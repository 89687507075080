import React from "react";

export default function PoundIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
    >
      <path
        d="M7.89986 11H0.939631V9.67756H7.89986V11ZM5.81179 7.01278H0.90483V5.69034H5.81179V7.01278ZM3.40057 3.83097L3.55966 8.12145C3.57955 8.54901 3.52486 8.93182 3.3956 9.26989C3.26634 9.60464 3.03267 9.87145 2.6946 10.0703L1.42188 9.67756C1.60085 9.66761 1.7384 9.57647 1.83452 9.40412C1.93063 9.23177 1.99527 9.02959 2.02841 8.79759C2.06155 8.56226 2.07481 8.35014 2.06818 8.16122L1.92401 3.83097C1.89749 3.13494 2.02675 2.55327 2.31179 2.08594C2.60014 1.61861 2.98295 1.26728 3.46023 1.03196C3.9375 0.796638 4.45123 0.678977 5.00142 0.678977C5.49195 0.678977 5.93277 0.76018 6.32386 0.922585C6.71828 1.08499 7.04972 1.30705 7.31818 1.58878C7.58996 1.86719 7.78385 2.18537 7.89986 2.54332C8.01586 2.90128 8.03906 3.27746 7.96946 3.67188L6.53267 3.44318C6.56581 3.11837 6.51444 2.84825 6.37855 2.63281C6.24266 2.41738 6.0554 2.25497 5.81676 2.1456C5.57813 2.03622 5.32457 1.98153 5.05611 1.98153C4.77438 1.98153 4.50592 2.04782 4.25071 2.1804C3.99882 2.31297 3.79332 2.51515 3.63423 2.78693C3.47846 3.05871 3.40057 3.40672 3.40057 3.83097Z"
        fill="#8A96A6"
      />
    </svg>
  );
}
