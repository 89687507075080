import axios from "axios";
import { BASE_URL } from "./apiConstant";
import { getToken } from "./helpers";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken(); // Replace 'token' with your localStorage key
    if (token) {
      config.headers.Authorization = `Token 32a44b90b72ff77005f2367de74c80ee8d8b8209`;
      // config.headers.Authorization = `Token ${token}`;
    } else {
      window.location = "/login"; 
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
