import React from "react";
import Slider from "react-slick";
import ActiveAccountDetails from "./ActiveAccountDetails";
import { styled } from "@mui/system";

export const Wrapper = styled("div")`
  .slick-slide {
    padding: 0 20px 0 0;
  }
  .slick-list {
    padding: 0 !important;
  }
`;

export default function ActiveAccounts({accountData}) {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    infinite: accountData?.length >= 5,
    centerMode: false,
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        {
          accountData?.map((account, index) => {
            return <ActiveAccountDetails account={account} key={index}/>
          })
        }
      </Slider>
    </Wrapper>
  );
}
