import React from "react";

function PopoverHelpIcon({tabval}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.15184 5.24984C6.32242 4.90555 6.58585 4.61581 6.9124 4.41334C7.23896 4.21086 7.61561 4.10372 7.99984 4.104C8.4091 4.10398 8.80912 4.22572 9.14899 4.45373C9.48885 4.68174 9.7532 5.0057 9.9084 5.3844C10.0636 5.76309 10.1026 6.1794 10.0205 6.58034C9.93838 6.98128 9.73883 7.34873 9.44726 7.63592C8.98892 8.08784 8.43159 8.5755 8.16209 9.14842M7.99984 11.8957V11.9048M7.99984 14.6457C7.12709 14.6457 6.2629 14.4738 5.45659 14.1398C4.65028 13.8058 3.91765 13.3163 3.30052 12.6992C2.6834 12.082 2.19387 11.3494 1.85989 10.5431C1.5259 9.73678 1.354 8.87258 1.354 7.99984C1.354 7.12709 1.5259 6.2629 1.85989 5.45659C2.19387 4.65028 2.6834 3.91765 3.30052 3.30052C3.91765 2.6834 4.65028 2.19387 5.45659 1.85989C6.2629 1.5259 7.12709 1.354 7.99984 1.354C9.76242 1.354 11.4528 2.05419 12.6992 3.30052C13.9455 4.54686 14.6457 6.23725 14.6457 7.99984C14.6457 9.76242 13.9455 11.4528 12.6992 12.6992C11.4528 13.9455 9.76242 14.6457 7.99984 14.6457Z"
        stroke={tabval === "tabHelp" ? "white" : "#8A96A6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PopoverHelpIcon;
