import {
  Box,
  Stack,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  FormHelperText,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import AuthLayout from "../components/Login/AuthLayout";
import EmailIcon from "../components/Icons/EmailIcon";
import PassHide from "../components/ui/PassHide";
import PassShow from "../components/ui/PassShow";
import GoogleIcon from "../components/Icons/GoogleIcon";
import AppleIcon from "../components/Icons/AppleIcon";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../slices/auth/authSlice";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

export default function Login() {
  const [showPassword, setShowPassword] = useState("password");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.status);
  const themeMode = useSelector((state) => state.theme.mode);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handlePassVisiblety = () => {
    setShowPassword((prev) => (prev === "password" ? "text" : "password"));
  };

  const onSubmit = async (event) => {
    const formData = {
      email: event.email,
      password: event.password,
    };
    dispatch(loginUser(formData));
  };

  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: (theme) => theme.palette.color.bg,
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Sign-in to your account
          </Typography>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ paddingTop: "24px", justifyContent: "center" }}
          >
            <Button
              className="loginButton"
              sx={{
                backgroundColor: (theme) => theme.palette.color.chatActive,
                color: (theme) => theme.palette.color.darkText,
                px: "35px",
                py: "14px",
                textTransform: "none",
                borderRadius: "8px",
              }}
            >
              <GoogleIcon /> Sign up with Google
            </Button>
            <Button
              className="loginButton"
              sx={{
                backgroundColor: (theme) => theme.palette.color.chatActive,
                color: (theme) => theme.palette.color.darkText,
                px: "35px",
                py: "14px",
                textTransform: "none",
                borderRadius: "8px",
              }}
            >
              <AppleIcon themeColor={themeMode} /> Sign up with apple
            </Button>
          </Stack>
          <div className="no-span section-divider">OR continue with</div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1, maxWidth: "590px", width: "100%" }}
            >
              <Box paddingBottom={"16px"} sx={{ position: "relative" }}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Email
                </Typography>
                <Box position={"relative"}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        startAdornment={
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        }
                        className="custom_input"
                        placeholder="Email"
                        autoComplete="off"
                        sx={{
                          marginTop: "5px",
                          width: "100%",
                          background: (theme) =>
                            theme.palette.color.lightShade + "!important",
                        }}
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText>{errors.email.message}</FormHelperText>
                  )}
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Password
                </Typography>
                <Box>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required" }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type={showPassword}
                        autoComplete="off"
                        sx={{
                          marginTop: "5px",
                          width: "100%",
                          background: (theme) =>
                            theme.palette.color.lightShade + "!important",
                        }}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton onClick={handlePassVisiblety}>
                              {showPassword === "password" ? (
                                <PassHide />
                              ) : (
                                <PassShow />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        className="custom_input"
                        placeholder="Enter your password"
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText>{errors.password.message}</FormHelperText>
                  )}
                </Box>
              </Box>
              <Grid
                container
                justifyContent="flex-end"
                sx={{ pb: "24px", pt: "4px" }}
              >
                <Grid item>
                  <Typography
                    variant="subtitle2_500"
                    sx={{
                      a: {
                        color: (theme) => theme.palette.color.darkText,
                        textDecoration: "none",
                      },
                    }}
                  >
                    <Link to="/forgot-password" variant="body2">
                      Forgot Password ?
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Button
                className="custom_color_btn customButton"
                fullWidth
                sx={{
                  padding: "14px !important",
                  fontSize: "16px !important",
                }}
                type="submit"
                disabled={loading === "loading"}
              >
                {loading === "loading" ? (
                  <CircularProgress color="inherit" size={21} />
                ) : (
                  "Sign In"
                )}
              </Button>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  paddingTop: "48px",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="subtitle2_500"
                  sx={{
                    a: {
                      color: (theme) => theme.palette.color.skyColor,
                      textDecoration: "none",
                    },
                  }}
                >
                  Don't have an account yet?{" "}
                  <Link to="/register" variant="body2">
                    Sign Up
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
