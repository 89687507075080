export const selectedData = [
    {
      "ID": 17285,
      "event": "Revolution Day",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-20 00:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17284,
      "event": "Consumer Confidence (Nov)",
      "country": "IE",
      "currency": "EUR",
      "date": "2023-11-20 00:01:00",
      "impact": "Low",
      "previous": "60.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17282,
      "event": "Loan Prime Rate 5Y (Nov)",
      "country": "CN",
      "currency": "CNY",
      "date": "2023-11-20 01:15:00",
      "impact": "Low",
      "previous": "4.20000000",
      "estimate": "4.20000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17283,
      "event": "Loan Prime Rate 1Y",
      "country": "CN",
      "currency": "CNY",
      "date": "2023-11-20 01:15:00",
      "impact": "Low",
      "previous": "3.45000000",
      "estimate": "3.45000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17280,
      "event": "GDP Growth Rate QoQ (Q3)",
      "country": "TH",
      "currency": "THB",
      "date": "2023-11-20 02:30:00",
      "impact": "Medium",
      "previous": "0.20000000",
      "estimate": "1.20000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17281,
      "event": "GDP Growth Rate YoY (Q3)",
      "country": "TH",
      "currency": "THB",
      "date": "2023-11-20 02:30:00",
      "impact": "Medium",
      "previous": "1.80000000",
      "estimate": "2.40000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17279,
      "event": "ECB Lane Speech",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-20 05:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17276,
      "event": "Industrial Inventories QoQ (Q3)",
      "country": "SE",
      "currency": "SEK",
      "date": "2023-11-20 07:00:00",
      "impact": "Low",
      "previous": "5.90000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17277,
      "event": "PPI YoY (Oct)",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-20 07:00:00",
      "impact": "Low",
      "previous": "-14.70000000",
      "estimate": "-11.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17278,
      "event": "PPI MoM (Oct)",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-20 07:00:00",
      "impact": "Low",
      "previous": "-0.20000000",
      "estimate": "-0.10000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17275,
      "event": "Export Orders YoY (Oct)",
      "country": "TW",
      "currency": "TWD",
      "date": "2023-11-20 08:00:00",
      "impact": "Low",
      "previous": "-15.60000000",
      "estimate": "-4.93000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17274,
      "event": "Current Account (Q3)",
      "country": "TW",
      "currency": "TWD",
      "date": "2023-11-20 08:20:00",
      "impact": "Low",
      "previous": "22.24000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17273,
      "event": "Construction Output YoY (Sep)",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-20 10:00:00",
      "impact": "Low",
      "previous": "-0.10000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17272,
      "event": "12-Month Bubill Auction",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-20 10:30:00",
      "impact": "Low",
      "previous": "3.72900000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17266,
      "event": "M1 Money Supply YoY (Oct)",
      "country": "IL",
      "currency": "ILS",
      "date": "2023-11-20 11:00:00",
      "impact": "Low",
      "previous": "-26.30000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17267,
      "event": "Manufacturing PMI (Oct)",
      "country": "IL",
      "currency": "ILS",
      "date": "2023-11-20 11:00:00",
      "impact": "Low",
      "previous": "49.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17268,
      "event": "Consumer Confidence (Oct)",
      "country": "ES",
      "currency": "EUR",
      "date": "2023-11-20 11:00:00",
      "impact": "Medium",
      "previous": "77.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17269,
      "event": "PPI YoY (Oct)",
      "country": "PT",
      "currency": "EUR",
      "date": "2023-11-20 11:00:00",
      "impact": "Low",
      "previous": "-5.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17270,
      "event": "PPI MoM (Oct)",
      "country": "PT",
      "currency": "EUR",
      "date": "2023-11-20 11:00:00",
      "impact": "Low",
      "previous": "0.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17271,
      "event": "Inflation Expectations (Nov)",
      "country": "IL",
      "currency": "ILS",
      "date": "2023-11-20 11:00:00",
      "impact": "Low",
      "previous": "2.70000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17265,
      "event": "BCB Focus Market Readout",
      "country": "BR",
      "currency": "BRL",
      "date": "2023-11-20 11:30:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17264,
      "event": "Unemployment Rate (Oct)",
      "country": "IL",
      "currency": "ILS",
      "date": "2023-11-20 12:30:00",
      "impact": "Low",
      "previous": "3.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17261,
      "event": "3-Month BTF Auction",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-20 14:00:00",
      "impact": "Low",
      "previous": "3.79600000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17262,
      "event": "12-Month BTF Auction",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-20 14:00:00",
      "impact": "Low",
      "previous": "3.67400000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17263,
      "event": "6-Month BTF Auction",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-20 14:00:00",
      "impact": "Low",
      "previous": "3.79600000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17260,
      "event": "CB Leading Index MoM (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-20 15:00:00",
      "impact": "Medium",
      "previous": "-0.70000000",
      "estimate": "-0.70000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17258,
      "event": "3-Month Bill Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-20 16:30:00",
      "impact": "Low",
      "previous": "5.28500000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17259,
      "event": "6-Month Bill Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-20 16:30:00",
      "impact": "Low",
      "previous": "5.27000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17257,
      "event": "20-Year Bond Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-20 18:00:00",
      "impact": "Low",
      "previous": "5.24500000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17256,
      "event": "BoE Gov Bailey Speech",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-20 18:45:00",
      "impact": "Medium",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17254,
      "event": "PPI YoY (Oct)",
      "country": "KR",
      "currency": "KRW",
      "date": "2023-11-20 21:00:00",
      "impact": "Low",
      "previous": "1.30000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17255,
      "event": "PPI MoM (Oct)",
      "country": "KR",
      "currency": "KRW",
      "date": "2023-11-20 21:00:00",
      "impact": "Low",
      "previous": "0.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17251,
      "event": "Exports (Oct)",
      "country": "NZ",
      "currency": "NZD",
      "date": "2023-11-20 21:45:00",
      "impact": "Low",
      "previous": "4.87000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17252,
      "event": "Imports (Oct)",
      "country": "NZ",
      "currency": "NZD",
      "date": "2023-11-20 21:45:00",
      "impact": "Low",
      "previous": "7.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17253,
      "event": "Balance of Trade (Oct)",
      "country": "NZ",
      "currency": "NZD",
      "date": "2023-11-20 21:45:00",
      "impact": "Medium",
      "previous": "-2.32900000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17250,
      "event": "RBA Bullock Speech",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-20 23:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17249,
      "event": "RBA Schwartz Speech",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-20 23:45:00",
      "impact": "Medium",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17248,
      "event": "RBA Meeting Minutes",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-21 00:30:00",
      "impact": "Medium",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17247,
      "event": "Credit Card Spending YoY (Oct)",
      "country": "NZ",
      "currency": "NZD",
      "date": "2023-11-21 02:00:00",
      "impact": "Low",
      "previous": "3.30000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17246,
      "event": "Current Account (Q3)",
      "country": "ID",
      "currency": "IDR",
      "date": "2023-11-21 03:00:00",
      "impact": "Low",
      "previous": "-1.90000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17245,
      "event": "20-Year JGB Auction",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-21 03:35:00",
      "impact": "Low",
      "previous": "1.55300000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17244,
      "event": "New Car Sales YoY (Oct)",
      "country": "TH",
      "currency": "THB",
      "date": "2023-11-21 04:00:00",
      "impact": "Low",
      "previous": "-16.27000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17242,
      "event": "MAS 4-Week Bill Auction",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-21 05:00:00",
      "impact": "Low",
      "previous": "3.98000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17243,
      "event": "MAS 12-Week Bill Auction",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-21 05:00:00",
      "impact": "Low",
      "previous": "3.98000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17241,
      "event": "Unemployment Rate (Oct)",
      "country": "FI",
      "currency": "EUR",
      "date": "2023-11-21 06:00:00",
      "impact": "Low",
      "previous": "7.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17234,
      "event": "Public Sector Net Borrowing (Oct)",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-21 07:00:00",
      "impact": "Medium",
      "previous": "-13.52600000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17235,
      "event": "Capacity Utilization QoQ (Q3)",
      "country": "SE",
      "currency": "SEK",
      "date": "2023-11-21 07:00:00",
      "impact": "Low",
      "previous": "0.60000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17236,
      "event": "New Car Registrations YoY (Oct)",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-21 07:00:00",
      "impact": "Medium",
      "previous": "9.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17237,
      "event": "Balance of Trade (Oct)",
      "country": "CH",
      "currency": "CHF",
      "date": "2023-11-21 07:00:00",
      "impact": "Medium",
      "previous": "5.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17238,
      "event": "Leading Business Cycle Indicator MoM (Sep)",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-21 07:00:00",
      "impact": "Low",
      "previous": "0.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17239,
      "event": "Labour Productivity QoQ (Q3)",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-21 07:00:00",
      "impact": "Low",
      "previous": "0.70000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17240,
      "event": "Public Sector Net Borrowing Ex Banks (Oct)",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-21 07:00:00",
      "impact": "Low",
      "previous": "-14.34700000",
      "estimate": "-13.70000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17233,
      "event": "Current Account (Q3)",
      "country": "TW",
      "currency": "TWD",
      "date": "2023-11-21 08:20:00",
      "impact": "Low",
      "previous": "22.24000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17231,
      "event": "Inflation Rate MoM (Oct)",
      "country": "HK",
      "currency": "HKD",
      "date": "2023-11-21 08:30:00",
      "impact": "Low",
      "previous": "0.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17232,
      "event": "Inflation Rate YoY (Oct)",
      "country": "HK",
      "currency": "HKD",
      "date": "2023-11-21 08:30:00",
      "impact": "Low",
      "previous": "2.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17226,
      "event": "Corporate Sector Wages YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-21 09:00:00",
      "impact": "Low",
      "previous": "10.30000000",
      "estimate": "11.80000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17227,
      "event": "Employment Growth YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-21 09:00:00",
      "impact": "Low",
      "previous": "0.00000000",
      "estimate": "0.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17228,
      "event": "Current Account (Sep)",
      "country": "GR",
      "currency": "EUR",
      "date": "2023-11-21 09:00:00",
      "impact": "Low",
      "previous": "0.49800000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17229,
      "event": "Industrial Production YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-21 09:00:00",
      "impact": "Low",
      "previous": "-3.10000000",
      "estimate": "1.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17230,
      "event": "PPI YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-21 09:00:00",
      "impact": "Low",
      "previous": "-2.80000000",
      "estimate": "-3.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17225,
      "event": "5-Year Bobl Auction",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-21 10:30:00",
      "impact": "Low",
      "previous": "2.71000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17224,
      "event": "Manufacturing Production MoM (Sep)",
      "country": "IL",
      "currency": "ILS",
      "date": "2023-11-21 11:00:00",
      "impact": "Low",
      "previous": "5.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17222,
      "event": "Deposit Interest Rate (Nov)",
      "country": "HU",
      "currency": "HUF",
      "date": "2023-11-21 13:00:00",
      "impact": "Low",
      "previous": "11.25000000",
      "estimate": "10.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17223,
      "event": "Base Rate",
      "country": "HU",
      "currency": "HUF",
      "date": "2023-11-21 13:00:00",
      "impact": "Low",
      "previous": "12.25000000",
      "estimate": "11.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17213,
      "event": "CPI Median YoY (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "Low",
      "previous": "3.80000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17214,
      "event": "Chicago Fed National Activity Index (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 13:30:00",
      "impact": "Medium",
      "previous": "0.02000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17215,
      "event": "New Housing Price Index MoM (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "Low",
      "previous": "-0.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17216,
      "event": "Core Inflation Rate YoY (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "High",
      "previous": "2.80000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17217,
      "event": "CPI Trimmed-Mean YoY (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "Low",
      "previous": "3.70000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17218,
      "event": "Inflation Rate MoM (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "Medium",
      "previous": "-0.10000000",
      "estimate": "0.10000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17219,
      "event": "Core Inflation Rate MoM (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "High",
      "previous": "-0.10000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17220,
      "event": "New Housing Price Index YoY (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "Medium",
      "previous": "-1.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17221,
      "event": "Inflation Rate YoY (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-21 13:30:00",
      "impact": "High",
      "previous": "3.80000000",
      "estimate": "3.20000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17212,
      "event": "Redbook YoY (Nov/18)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 13:55:00",
      "impact": "Low",
      "previous": "3.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17209,
      "event": "Existing Home Sales (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 15:00:00",
      "impact": "Low",
      "previous": "3.96000000",
      "estimate": "3.90000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17210,
      "event": "Global Dairy Trade Price Index",
      "country": "NZ",
      "currency": "NZD",
      "date": "2023-11-21 15:00:00",
      "impact": "Low",
      "previous": "-0.70000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17211,
      "event": "Existing Home Sales MoM (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 15:00:00",
      "impact": "Medium",
      "previous": "-2.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17207,
      "event": "41-Day Bill Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 16:30:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17208,
      "event": "2-Year FRN Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 16:30:00",
      "impact": "Low",
      "previous": "0.17000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17206,
      "event": "ECB Schnabel Speech",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-21 17:15:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17205,
      "event": "10-Year TIPS Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 18:00:00",
      "impact": "Low",
      "previous": "2.09400000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17204,
      "event": "FOMC Minutes",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 19:00:00",
      "impact": "High",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17203,
      "event": "API Crude Oil Stock Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-21 21:30:00",
      "impact": "Low",
      "previous": "1.33500000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17199,
      "event": "Westpac Leading Index MoM (Oct)",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-22 00:00:00",
      "impact": "Medium",
      "previous": "0.07000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17200,
      "event": "GDP Growth Rate YoY (Q3)",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-22 00:00:00",
      "impact": "Low",
      "previous": "0.50000000",
      "estimate": "0.70000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17201,
      "event": "General Elections",
      "country": "NL",
      "currency": "EUR",
      "date": "2023-11-22 00:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17202,
      "event": "GDP Growth Rate QoQ (Q3)",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-22 00:00:00",
      "impact": "Low",
      "previous": "0.10000000",
      "estimate": "1.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17198,
      "event": "Consumer Confidence (Nov)",
      "country": "NL",
      "currency": "EUR",
      "date": "2023-11-22 05:30:00",
      "impact": "Medium",
      "previous": "-38.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17197,
      "event": "Consumer Confidence (Nov)",
      "country": "DK",
      "currency": "DKK",
      "date": "2023-11-22 07:00:00",
      "impact": "Low",
      "previous": "-11.80000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17192,
      "event": "Core Inflation Rate YoY (Oct)",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-22 08:00:00",
      "impact": "Low",
      "previous": "4.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17193,
      "event": "Unemployment Rate (Oct)",
      "country": "TW",
      "currency": "TWD",
      "date": "2023-11-22 08:00:00",
      "impact": "Low",
      "previous": "3.44000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17194,
      "event": "Core Inflation Rate MoM (Oct)",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-22 08:00:00",
      "impact": "Low",
      "previous": "0.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17195,
      "event": "Inflation Rate YoY (Oct)",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-22 08:00:00",
      "impact": "Medium",
      "previous": "5.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17196,
      "event": "Inflation Rate MoM (Oct)",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-22 08:00:00",
      "impact": "Medium",
      "previous": "0.60000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17191,
      "event": "RBA Bullock Speech",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-22 08:35:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17186,
      "event": "Employment Growth YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-22 09:00:00",
      "impact": "Low",
      "previous": "0.00000000",
      "estimate": "0.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17187,
      "event": "Industrial Production YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-22 09:00:00",
      "impact": "Low",
      "previous": "-3.10000000",
      "estimate": "1.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17188,
      "event": "Corporate Sector Wages YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-22 09:00:00",
      "impact": "Low",
      "previous": "10.30000000",
      "estimate": "11.80000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17189,
      "event": "PPI YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-22 09:00:00",
      "impact": "Low",
      "previous": "-2.80000000",
      "estimate": "-3.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17190,
      "event": "Retail Sales YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-22 09:00:00",
      "impact": "Low",
      "previous": "-0.30000000",
      "estimate": "1.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17183,
      "event": "Consumer Confidence (Nov)",
      "country": "BE",
      "currency": "EUR",
      "date": "2023-11-22 10:00:00",
      "impact": "Low",
      "previous": "-5.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17184,
      "event": "OECD Economic Outlook",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-22 10:00:00",
      "impact": "Medium",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17185,
      "event": "Construction Output YoY (Sep)",
      "country": "IT",
      "currency": "EUR",
      "date": "2023-11-22 10:00:00",
      "impact": "Low",
      "previous": "-0.30000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17182,
      "event": "15-Year Bund Auction",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-22 10:30:00",
      "impact": "Low",
      "previous": "3.05000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17179,
      "event": "Wholesale Prices MoM (Oct)",
      "country": "IE",
      "currency": "EUR",
      "date": "2023-11-22 11:00:00",
      "impact": "Low",
      "previous": "-0.90000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17180,
      "event": "Wholesale Prices YoY (Oct)",
      "country": "IE",
      "currency": "EUR",
      "date": "2023-11-22 11:00:00",
      "impact": "Low",
      "previous": "-2.60000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17181,
      "event": "CBI Industrial Trends Orders (Nov)",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-22 11:00:00",
      "impact": "Low",
      "previous": "-26.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17177,
      "event": "3-Month Bill Auction",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-22 11:10:00",
      "impact": "Low",
      "previous": "3.73900000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17178,
      "event": "6-Month Bill Auction",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-22 11:10:00",
      "impact": "Low",
      "previous": "3.75300000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17169,
      "event": "MBA Mortgage Market Index (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 12:00:00",
      "impact": "Low",
      "previous": "170.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17170,
      "event": "MBA Purchase Index (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 12:00:00",
      "impact": "Low",
      "previous": "133.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17171,
      "event": "MBA 30-Year Mortgage Rate (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 12:00:00",
      "impact": "Medium",
      "previous": "7.61000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17172,
      "event": "UK Autumn Statement",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-22 12:00:00",
      "impact": "High",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17173,
      "event": "Retail Sales MoM (Sep)",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-22 12:00:00",
      "impact": "Low",
      "previous": "-0.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17174,
      "event": "Retail Sales YoY (Sep)",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-22 12:00:00",
      "impact": "Low",
      "previous": "3.20000000",
      "estimate": "3.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17175,
      "event": "MBA Mortgage Refinance Index (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 12:00:00",
      "impact": "Low",
      "previous": "354.30000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17176,
      "event": "MBA Mortgage Applications (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 12:00:00",
      "impact": "Medium",
      "previous": "2.80000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17158,
      "event": "Durable Goods Orders MoM (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 13:30:00",
      "impact": "High",
      "previous": "4.70000000",
      "estimate": "-3.20000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17159,
      "event": "Continuing Jobless Claims (Nov/11)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 13:30:00",
      "impact": "Medium",
      "previous": "1865.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17160,
      "event": "Wholesale Sales MoM (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-22 13:30:00",
      "impact": "Low",
      "previous": "0.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17161,
      "event": "Non Defense Goods Orders Ex Air (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 13:30:00",
      "impact": "Low",
      "previous": "0.60000000",
      "estimate": "0.10000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17162,
      "event": "New Housing Price Index YoY (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-22 13:30:00",
      "impact": "Medium",
      "previous": "-1.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17163,
      "event": "Durable Goods Orders ex Defense MoM (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 13:30:00",
      "impact": "Low",
      "previous": "5.80000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17164,
      "event": "New Housing Price Index MoM (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-22 13:30:00",
      "impact": "Low",
      "previous": "-0.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17165,
      "event": "Jobless Claims 4-week Average (Nov/18)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 13:30:00",
      "impact": "Low",
      "previous": "220.25000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17166,
      "event": "Initial Jobless Claims (Nov/18)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 13:30:00",
      "impact": "Medium",
      "previous": "231.00000000",
      "estimate": "225.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17167,
      "event": "Durable Goods Orders Ex Transp MoM (Oct)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 13:30:00",
      "impact": "Medium",
      "previous": "0.50000000",
      "estimate": "0.10000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17168,
      "event": "Manufacturing Sales MoM (Oct)",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-22 13:30:00",
      "impact": "Low",
      "previous": "0.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17157,
      "event": "ECB Elderson Speech",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-22 14:10:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17151,
      "event": "Michigan Consumer Expectations (Nov)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:00:00",
      "impact": "Low",
      "previous": "59.30000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17152,
      "event": "Michigan Inflation Expectations (Nov)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:00:00",
      "impact": "Low",
      "previous": "4.20000000",
      "estimate": "4.40000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17153,
      "event": "Michigan Current Conditions (Nov)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:00:00",
      "impact": "Low",
      "previous": "70.60000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17154,
      "event": "Michigan Consumer Sentiment (Nov)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:00:00",
      "impact": "High",
      "previous": "63.80000000",
      "estimate": "60.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17155,
      "event": "Michigan 5 Year Inflation Expectations (Nov)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:00:00",
      "impact": "Low",
      "previous": "3.00000000",
      "estimate": "3.20000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17156,
      "event": "Consumer Confidence (Nov)",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-22 15:00:00",
      "impact": "Medium",
      "previous": "-17.90000000",
      "estimate": "-17.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17142,
      "event": "EIA Cushing Crude Oil Stocks Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "1.92500000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17143,
      "event": "EIA Gasoline Stocks Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "-1.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17144,
      "event": "EIA Distillate Fuel Production Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "0.05300000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17145,
      "event": "EIA Heating Oil Stocks Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "-0.10000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17146,
      "event": "EIA Crude Oil Imports Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "-0.38500000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17147,
      "event": "EIA Refinery Crude Runs Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "0.16400000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17148,
      "event": "EIA Gasoline Production Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "-0.81300000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17149,
      "event": "EIA Crude Oil Stocks Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "3.59200000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17150,
      "event": "EIA Distillate Stocks Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 15:30:00",
      "impact": "Low",
      "previous": "-1.42200000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17140,
      "event": "PPI MoM (Oct)",
      "country": "RU",
      "currency": "RUB",
      "date": "2023-11-22 16:00:00",
      "impact": "Low",
      "previous": "4.70000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17141,
      "event": "PPI YoY (Oct)",
      "country": "RU",
      "currency": "RUB",
      "date": "2023-11-22 16:00:00",
      "impact": "Low",
      "previous": "16.70000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17137,
      "event": "17-Week Bill Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 16:30:00",
      "impact": "Low",
      "previous": "5.25500000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17138,
      "event": "8-Week Bill Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 16:30:00",
      "impact": "Low",
      "previous": "5.28000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17139,
      "event": "4-Week Bill Auction",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 16:30:00",
      "impact": "Low",
      "previous": "5.29000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17136,
      "event": "BoC Gov Macklem Speech",
      "country": "CA",
      "currency": "CAD",
      "date": "2023-11-22 16:45:00",
      "impact": "Medium",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17133,
      "event": "EIA Natural Gas Stocks Change (Nov/17)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 17:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17134,
      "event": "30-Year Mortgage Rate (Nov/21)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 17:00:00",
      "impact": "Low",
      "previous": "7.44000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17135,
      "event": "15-Year Mortgage Rate (Nov/21)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 17:00:00",
      "impact": "Low",
      "previous": "6.76000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17131,
      "event": "Baker Hughes Total Rig Count (Nov/24)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 18:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17132,
      "event": "Baker Hughes Oil Rig Count (Nov/24)",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 18:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17130,
      "event": "FOMC Minutes",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-22 19:00:00",
      "impact": "High",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17127,
      "event": "Judo Bank Composite PMI (Nov)",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-22 22:00:00",
      "impact": "Low",
      "previous": "47.60000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17128,
      "event": "Judo Bank Services PMI (Nov)",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-22 22:00:00",
      "impact": "Medium",
      "previous": "47.90000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17129,
      "event": "Judo Bank Manufacturing PMI (Nov)",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-22 22:00:00",
      "impact": "Medium",
      "previous": "48.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17124,
      "event": "Labor Thanksgiving Day",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-23 00:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17125,
      "event": "Thanksgiving Day",
      "country": "US",
      "currency": "USD",
      "date": "2023-11-23 00:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17126,
      "event": "RBA Payments System Board Meeting",
      "country": "AU",
      "currency": "AUD",
      "date": "2023-11-23 00:00:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17123,
      "event": "Current Account (Q3)",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-23 01:00:00",
      "impact": "Low",
      "previous": "31.02000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17122,
      "event": "M2 Money Supply YoY (Oct)",
      "country": "ID",
      "currency": "IDR",
      "date": "2023-11-23 03:00:00",
      "impact": "Low",
      "previous": "6.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17117,
      "event": "Inflation Rate YoY (Oct)",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-23 05:00:00",
      "impact": "Low",
      "previous": "4.10000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17118,
      "event": "6-Month T-Bill Auction",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-23 05:00:00",
      "impact": "Low",
      "previous": "3.75000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17119,
      "event": "Inflation Rate MoM (Oct)",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-23 05:00:00",
      "impact": "Low",
      "previous": "0.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17120,
      "event": "Core Inflation Rate YoY (Oct)",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-23 05:00:00",
      "impact": "Low",
      "previous": "3.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17121,
      "event": "CPI (Oct)",
      "country": "SG",
      "currency": "SGD",
      "date": "2023-11-23 05:00:00",
      "impact": "Low",
      "previous": "114.90000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17108,
      "event": "GDP Growth Rate YoY (Q3)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "0.70000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17109,
      "event": "Business Confidence (Nov)",
      "country": "DK",
      "currency": "DKK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "-6.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17110,
      "event": "GDP Growth Rate QoQ (Q3)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "0.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17111,
      "event": "Household Consumption MoM (Sep)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "-0.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17112,
      "event": "GDP Growth Mainland QoQ (Q3)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "0.00000000",
      "estimate": "0.20000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17113,
      "event": "Unemployment Rate (Oct)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "3.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17114,
      "event": "Loan Growth YoY (Oct)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "4.10000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17115,
      "event": "GDP MoM (Sep)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "-0.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17116,
      "event": "GDP Mainland MoM (Sep)",
      "country": "NO",
      "currency": "NOK",
      "date": "2023-11-23 07:00:00",
      "impact": "Low",
      "previous": "-0.20000000",
      "estimate": "0.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17107,
      "event": "Loan Growth YoY (Sep)",
      "country": "ID",
      "currency": "IDR",
      "date": "2023-11-23 07:20:00",
      "impact": "Low",
      "previous": "8.96000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17103,
      "event": "Gross Wage YoY (Sep)",
      "country": "HU",
      "currency": "HUF",
      "date": "2023-11-23 07:30:00",
      "impact": "Low",
      "previous": "15.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17104,
      "event": "Interest Rate Decision",
      "country": "ID",
      "currency": "IDR",
      "date": "2023-11-23 07:30:00",
      "impact": "Medium",
      "previous": "6.00000000",
      "estimate": "6.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17105,
      "event": "Deposit Facility Rate (Nov)",
      "country": "ID",
      "currency": "IDR",
      "date": "2023-11-23 07:30:00",
      "impact": "Low",
      "previous": "5.25000000",
      "estimate": "5.25000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17106,
      "event": "Lending Facility Rate (Nov)",
      "country": "ID",
      "currency": "IDR",
      "date": "2023-11-23 07:30:00",
      "impact": "Low",
      "previous": "6.75000000",
      "estimate": "6.75000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17101,
      "event": "Business Climate Indicator (Nov)",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-23 07:45:00",
      "impact": "Low",
      "previous": "98.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17102,
      "event": "Business Confidence (Nov)",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-23 07:45:00",
      "impact": "Medium",
      "previous": "98.00000000",
      "estimate": "98.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17099,
      "event": "Industrial Production YoY (Oct)",
      "country": "TW",
      "currency": "TWD",
      "date": "2023-11-23 08:00:00",
      "impact": "Low",
      "previous": "-6.72000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17100,
      "event": "Retail Sales YoY (Oct)",
      "country": "TW",
      "currency": "TWD",
      "date": "2023-11-23 08:00:00",
      "impact": "Low",
      "previous": "6.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17096,
      "event": "HCOB Manufacturing PMI (Nov)",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-23 08:15:00",
      "impact": "Medium",
      "previous": "42.80000000",
      "estimate": "43.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17097,
      "event": "HCOB Composite PMI (Nov)",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-23 08:15:00",
      "impact": "Low",
      "previous": "44.60000000",
      "estimate": "44.90000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17098,
      "event": "HCOB Services PMI (Nov)",
      "country": "FR",
      "currency": "EUR",
      "date": "2023-11-23 08:15:00",
      "impact": "Medium",
      "previous": "45.20000000",
      "estimate": "45.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17091,
      "event": "Riksbank Rate Decision",
      "country": "SE",
      "currency": "SEK",
      "date": "2023-11-23 08:30:00",
      "impact": "Medium",
      "previous": "4.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17092,
      "event": "HCOB Composite PMI (Nov)",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-23 08:30:00",
      "impact": "Low",
      "previous": "45.90000000",
      "estimate": "46.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17093,
      "event": "HCOB Manufacturing PMI (Nov)",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-23 08:30:00",
      "impact": "High",
      "previous": "40.80000000",
      "estimate": "41.20000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17094,
      "event": "HCOB Services PMI (Nov)",
      "country": "DE",
      "currency": "EUR",
      "date": "2023-11-23 08:30:00",
      "impact": "Medium",
      "previous": "48.20000000",
      "estimate": "48.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17095,
      "event": "Monetary Policy Report",
      "country": "SE",
      "currency": "SEK",
      "date": "2023-11-23 08:30:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17087,
      "event": "Retail Sales YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-23 09:00:00",
      "impact": "Low",
      "previous": "-0.30000000",
      "estimate": "1.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17088,
      "event": "HCOB Manufacturing PMI (Nov)",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-23 09:00:00",
      "impact": "Medium",
      "previous": "43.10000000",
      "estimate": "43.40000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17089,
      "event": "HCOB Services PMI (Nov)",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-23 09:00:00",
      "impact": "Medium",
      "previous": "47.80000000",
      "estimate": "48.10000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17090,
      "event": "HCOB Composite PMI (Nov)",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-23 09:00:00",
      "impact": "Low",
      "previous": "46.50000000",
      "estimate": "46.90000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17084,
      "event": "S&P Global/CIPS Manufacturing PMI (Nov)",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-23 09:30:00",
      "impact": "Medium",
      "previous": "44.80000000",
      "estimate": "45.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17085,
      "event": "S&P Global/CIPS Services PMI (Nov)",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-23 09:30:00",
      "impact": "Medium",
      "previous": "49.50000000",
      "estimate": "49.50000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17086,
      "event": "S&P Global/CIPS Composite PMI (Nov)",
      "country": "UK",
      "currency": "GBP",
      "date": "2023-11-23 09:30:00",
      "impact": "Low",
      "previous": "48.70000000",
      "estimate": "48.70000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17083,
      "event": "Business Confidence (Q4)",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-23 10:00:00",
      "impact": "Medium",
      "previous": "33.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17082,
      "event": "Manufacturing Production MoM (Sep)",
      "country": "IL",
      "currency": "ILS",
      "date": "2023-11-23 11:00:00",
      "impact": "Low",
      "previous": "5.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17078,
      "event": "Mid-month Core Inflation Rate YoY (Nov)",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-23 12:00:00",
      "impact": "Low",
      "previous": "5.54000000",
      "estimate": "5.34000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17079,
      "event": "Mid-month Inflation Rate YoY (Nov)",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-23 12:00:00",
      "impact": "Medium",
      "previous": "4.27000000",
      "estimate": "4.35000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17080,
      "event": "Mid-month Inflation Rate MoM (Nov)",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-23 12:00:00",
      "impact": "Medium",
      "previous": "0.24000000",
      "estimate": "0.65000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17081,
      "event": "Mid-month Core Inflation Rate MoM (Nov)",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-23 12:00:00",
      "impact": "Low",
      "previous": "0.24000000",
      "estimate": "0.22000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17075,
      "event": "Interest Rate Decision",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-23 13:00:00",
      "impact": "Medium",
      "previous": "8.25000000",
      "estimate": "8.25000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17076,
      "event": "M3 Money Supply YoY (Oct)",
      "country": "PL",
      "currency": "PLN",
      "date": "2023-11-23 13:00:00",
      "impact": "Low",
      "previous": "8.30000000",
      "estimate": "8.60000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17077,
      "event": "Prime Overdraft Rate",
      "country": "ZA",
      "currency": "ZAR",
      "date": "2023-11-23 13:00:00",
      "impact": "Low",
      "previous": "11.75000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17073,
      "event": "Monetary Policy Meeting Minutes",
      "country": "MX",
      "currency": "MXN",
      "date": "2023-11-23 15:00:00",
      "impact": "Medium",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17074,
      "event": "Business Confidence (Oct)",
      "country": "CO",
      "currency": "COP",
      "date": "2023-11-23 15:00:00",
      "impact": "Low",
      "previous": "0.80000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17072,
      "event": "ECB Schnabel Speech",
      "country": "EU",
      "currency": "EUR",
      "date": "2023-11-23 19:30:00",
      "impact": "Low",
      "previous": null,
      "estimate": null,
      "actual": null,
      "change": "0.00000000",
      "percentage": "0.00000000"
    },
    {
      "ID": 17070,
      "event": "Retail Sales QoQ (Q3)",
      "country": "NZ",
      "currency": "NZD",
      "date": "2023-11-23 21:45:00",
      "impact": "Medium",
      "previous": "-1.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17071,
      "event": "Retail Sales YoY (Q3)",
      "country": "NZ",
      "currency": "NZD",
      "date": "2023-11-23 21:45:00",
      "impact": "Low",
      "previous": "-3.50000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17066,
      "event": "Inflation Rate Ex-Food and Energy YoY (Oct)",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-23 23:30:00",
      "impact": "Medium",
      "previous": "4.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17067,
      "event": "Inflation Rate MoM (Oct)",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-23 23:30:00",
      "impact": "Low",
      "previous": "0.30000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17068,
      "event": "Inflation Rate YoY (Oct)",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-23 23:30:00",
      "impact": "High",
      "previous": "3.00000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17069,
      "event": "Core Inflation Rate YoY (Oct)",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-23 23:30:00",
      "impact": "Medium",
      "previous": "2.80000000",
      "estimate": "3.00000000",
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17064,
      "event": "Foreign Bond Investment (Nov/18)",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-23 23:50:00",
      "impact": "Medium",
      "previous": "-68.20000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    },
    {
      "ID": 17065,
      "event": "Stock Investment by Foreigners (Nov/18)",
      "country": "JP",
      "currency": "JPY",
      "date": "2023-11-23 23:50:00",
      "impact": "Low",
      "previous": "388.40000000",
      "estimate": null,
      "actual": null,
      "change": null,
      "percentage": "0.00000000"
    }
  ]