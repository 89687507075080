import { configureStore } from "@reduxjs/toolkit";
import {
  accountSlice,
  openModalSliceReducer,
  passwordRecoveryReducer,
  passwordSlice,
  profileSlice,
  snackbarSlice,
  themeSliceReducer,
  toggleSidebarReducer,
  tradeSlice,
} from "./slices";
import authSlice from "./slices/auth/authSlice";

export const store = configureStore({
  reducer: {
    theme: themeSliceReducer,
    openModal: openModalSliceReducer,
    sidebar: toggleSidebarReducer,
    auth: authSlice,
    passwordRecovery: passwordRecoveryReducer,
    snackbar: snackbarSlice,
    profile: profileSlice,
    passwordUpdate: passwordSlice,
    accounts: accountSlice,
    trades: tradeSlice
  },
});
