import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../utils/apiConstant';
import { showSnackbar } from '../snackbarSlice';

export const sendPasswordRecoveryEmail = createAsyncThunk(
  'auth/sendPasswordRecoveryEmail',
  async (email, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/auth/password/reset/`, { email });    
      dispatch(showSnackbar({ message: 'Password recovery email sent!', severity: 'success' }));
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message.email[0], severity: 'error' }));
      return rejectWithValue(err.response.data);
    }
  }
);

const passwordRecoverySlice = createSlice({
  name: 'passwordRecovery',
  initialState: {
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendPasswordRecoveryEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendPasswordRecoveryEmail.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendPasswordRecoveryEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectPasswordRecoveryLoading = (state) => state.passwordRecovery.loading;

export default passwordRecoverySlice.reducer;
