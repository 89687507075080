import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import SendIcon from "../../assets/sendbtn.png";
import popSelectedArr from "../../assets/popSelectedArr.svg";
import RightIcon from "../Icons/rightIcon";

function PopoverHome() {
  return (
    <Box sx={{ transition: "all 500ms ease-in-out" }}>
      <Box
        padding={"14px"}
        paddingTop={"120px"}
        sx={{
          background: "linear-gradient(135deg, #55AFFF 0%, #6A52FF 100%)",
        }}
      >
        <Stack direction={"column"} gap={0.5}>
          <Typography variant="h3_heading" fontWeight={"400"} color={(theme) => theme.palette.color.bgGrey}>
            Hello there.
          </Typography>
          <Typography variant="h2" color={"#ffffff"}>Ask our AI anything.</Typography>
        </Stack>
        <Stack
          direction={"row"}
          marginTop={"24px"}
          marginBottom={"14px"}
          padding={"16px"}
          sx={{
            background: (theme) => theme.palette.color.bgGrey,
            borderRadius: "12px",
          }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography sx={{ fontSize: "14px" }} variant="h6">
              Chat with our AI assistant
            </Typography>
            <Typography
              sx={{ marginTop: "8px", fontSize: "12px" }}
              variant="h6"
            >
              He typically replies in seconds, 24/7
            </Typography>
          </Box>
          <Box>
            <img src={SendIcon} alt="" />
          </Box>
        </Stack>
      </Box>
      <Box
        p={"14px"}
        sx={{ background: (theme) => theme.palette.color.bgGrey }}
      >
        <Box
          sx={{
            border: "1px solid",
            borderRadius: "12px",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack p={"4px"} direction={"column"} gap={"8px"}>
            <Stack
              sx={{ cursor: "pointer" }}
              p={"12px 16px"}
              direction={"row"}
              justifyContent={"space-between"}
              backgroundColor={(theme) => theme.palette.color.chatActive}
              borderRadius={1.5}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: 500 }}
              >
                Prompts
              </Typography>
              <Box>
                <img src={popSelectedArr} alt="" />
              </Box>
            </Stack>
            <Stack
              sx={{ cursor: "pointer" }}
              p={"12px 16px"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: 500 }}
              >
                Account metrimetric
              </Typography>
              <Box sx={{
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText
                }
              }}>
                <RightIcon />
              </Box>
            </Stack>
            <Stack
              sx={{ cursor: "pointer" }}
              p={"12px 16px"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: 500 }}
              >
                Rules and objectives
              </Typography>
              <Box sx={{
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText
                }
              }}>
                <RightIcon />
              </Box>
            </Stack>
            <Stack
              sx={{ cursor: "pointer" }}
              p={"12px 16px"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: 500 }}
              >
                Account metrics
              </Typography>
              <Box sx={{
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText
                }
              }}>
                <RightIcon />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default PopoverHome;
