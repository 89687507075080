import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";

export default function BillingDetails() {
  return (
    <Box>
      <Box
        sx={{
          background: (theme) => theme.palette.color.bgGrey,
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "21px 24px",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
            background: (theme) => theme.palette.color.lightShade,
          }}
        >
          <Typography variant="subHeaddingMedium">Billing Details</Typography>
        </Box>
        <Box sx={{
          padding: "24px"
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Company name
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Email
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Billed To
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Billed To
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                COC number (Chamber of Commerce)
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Address
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Country
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                State
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                City
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Zipcode
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
