import { alpha } from "@mui/material";

export default function componentStyleOverrides(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.customButton": {
            backgroundColor: theme.colors.lightgray,
            borderRadius: "8px",
            padding: "8px 15px",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            color: theme.colors.darkText,
            "svg path": {
              stroke: theme.colors.darkText,
            },
            textTransform: "capitalize",
          },
          "&.custom_color_btn.customButton": {
            color: "#ffffff",
            background: `linear-gradient(to right, ${theme.colors.skyColor} 0%, ${theme.colors.skyLightColor} 100%)`,
            "svg path": {
              stroke: "#ffffff",
            },
          },
          "&.custom_color_btn.customButton.outline_btn": {
            background: "transparent",
            border: "1px solid",
            borderColor: theme.colors.skyColor,
            color: theme.colors.skyColor,
            "svg path": {
              stroke: theme.colors.skyColor,
            },
          },
          "&.customButton.danger_varaint": {
            background: "transparent",
            border: "1px solid",
            borderColor: theme.colors.red,
            color: theme.colors.red,
          },
          "&.customButton.success_varaint": {
            background: "transparent",
            border: "1px solid",
            borderColor: theme.colors.green,
            color: theme.colors.green,
          },
          "&.discount_apply_btn": {
            color: theme.colors.secondary,
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: theme.colors.secondary,
            fontSize: "16px",
            fontWeight: "500",
            padding: "13px 16px",
          },
          "&.see_all_btn": {
            color: theme.colors.secondary,
            background: "transparent",
            border: "1px solid",
            borderColor: theme.colors.strockborder,
            "svg path": {
              stroke: theme.colors.secondary,
            },
          },
          "&.see_all_btn.metrix_btn": {
            background: theme.colors.lightShade,
          },
          "&.metrix_btn svg path": {
            stroke: theme.colors.skyColor,
          },
          "&.view_all": {
            color: theme.colors.purple,
            background: theme.colors.lightShade,
            border: "1px solid",
            lineHeight: "1.2",
            borderColor: (theme) => theme.colors.strockborder,
          },
          "&.purple_btn.custom_color_btn": {
            background: theme.colors.purple,
            color: "#ffffff",
          },
          "&.ai-btn.customButton": {
            color: theme.colors.purple,
            background: "transparent",
            justifyContent: "flex-start",
            padding: "0 16px",
            "svg path": {
              stroke: theme.colors.purple,
            },
          },
          "&.customButton.outline-btn": {
            background: "transparent",
            color: theme.colors.secondary,
            border: `1px solid ${alpha(theme.colors.secondary, 0.15)}`,
            ":hover": {
              bgcolor: theme.colors.bg5,
              border: `1px solid ${alpha(theme.colors.secondary, 0.15)}`,
            },
            width: "100%",
            "svg path": {
              stroke: theme.colors.secondary,
            },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.slick-slide": {
            marginLeft: "20px",
          },
          "& .stdropdown-container": {
            marginTop: "8px",
            border: "1px solid",
            borderColor: theme.colors.strockborder,
            borderRadius: "16px",
            padding: "12px 14px",
            background: theme.colors.blackShade,
          },
          "& .stdropdown-container .stsearch-box input": {
            backgroundColor: "transparent",
            border: 0,
            padding: 0,
            color: theme.colors.secondary,
            fontSize: "14px",
          },
          "& .stdropdown-container .stsearch-box input::placeholder": {
            color: theme.colors.secondary,
          },
          "& .stdropdown-container .stsearch-box svg": {
            fill: theme.colors.secondary,
          },
          "& .stdropdown-container .stsearch-box svg path": {
            stroke: theme.colors.secondary,
          },
          "& .stdropdown-container .stdropdown-menu": {
            background: theme.colors.bgGrey,
            borderColor: theme.colors.strockborder,
            top: "100%",
            left: 0,
          },
          "& .stdropdown-container .stdropdown-menu .stdropdown-item": {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Inter",
          },
          "& .stdropdown-container .stdropdown-menu .stdropdown-item span": {
            marginRight: "8px",
            fontFamily: "initial",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.custom_btn_icon": {
            padding: "12px",
            background: theme.colors.darkgray,
            borderRadius: "8px",
            border: "1px solid",
            borderColor: theme.colors.lightgray,
            ".MuiBadge-badge": {
              minWidth: "10px",
              height: "10px",
              top: "3px",
              right: "6px",
              borderRadius: "50%",
              // backgroundColor: theme.colors.purple,
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          "&.custom-profile-popover .MuiPopover-paper": {
            background: theme.colors.bgGrey,
            borderRadius: "12px",
            padding: "4px",
            border: "1px solid",
            borderColor: theme.colors.strockborder,
            marginLeft: "8px",
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          "&.custom_tab_buttons": {
            borderRadius: "40px",
            overflow: "hidden",
            width: "fit-content",
            background: theme.colors.chatActive,
            border: "1px solid",
            borderColor: theme.colors.lightShade,
            boxShadow: "none",
          },
          "&.custom_tab_buttons .MuiButtonGroup-firstButton": {
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
          },
          "&.custom_tab_buttons button": {
            background: "transparent",
            border: 0,
            color: theme.colors.secondary,
            fontSize: "14px",
            margin: "4px",
            "svg path": {
              fill: theme.colors.secondary,
            },
          },
          "&.custom_tab_buttons button.selected": {
            background: theme.colors.blackShade,
            border: 0,
            color: theme.colors.skyColor,
            borderRadius: "32px",
            padding: "10px 15px",
            "svg path": {
              fill: theme.colors.skyColor,
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.custom_input fieldset": {
            border: 0,
          },
          "&.custom_input input": {
            background: theme.colors.lightShade,
            color: theme.colors.secondary,
            border: "1px solid",
            borderColor: theme.colors.strockborder,
            borderRadius: "16px",
            fontSize: "14px",
            padding: "14px",
          },
          "&.custom_input.otp_fields": {
            maxWidth: "48px",
            "input": {
              textAlign: "center"
            }
          },
          "&.custom_input.otp_fields input:focus": {
            borderColor: theme.colors.purple,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.custom_input": {
            background: theme.colors.blackShade,
            color: theme.colors.secondary,
            border: "1px solid",
            borderColor: theme.colors.strockborder,
            borderRadius: "16px",
            fontSize: "14px",
            padding: "14px",
            "::after, ::before": {
              display: "none",
            },
            input: {
              padding: 0,
            },
          },
          "&.without_border-input": {
            width: "110px",
            "::after, ::before": {
              display: "none",
            },
            input: {
              padding: 0,
              textAlign: "right",
            },
          },
          "&.search-input": {
            borderRadius: "32px",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "&.activePayment": {
            borderColor: theme.colors.skyColor,
            background: `linear-gradient(to right, ${theme.colors.skyColor} 0%, ${theme.colors.skyLightColor} 100%)`,
          },
          "&.payment_method-cards": {
            width: "100%",
          },
          "&.partner_cards": {
            height: "100%",
          },
          "&.leaderboard_country_list": {
            borderRadius: "32px",
            background: theme.colors.lightShade,
          },
          "&.transparent_bg": {
            background: "transparent",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.custom_tabs_container": {
            background: theme.colors.bgGrey,
            borderRadius: "40px",
            width: "fit-content",
            padding: "4px",
            minHeight: "auto",
            ".Mui-selected": {
              color: theme.colors.darkText,
              background: theme.colors.lightgray,
              borderRadius: "40px",
            },
            ".MuiTab-root": {
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "500",
              textTransform: "capitalize",
              minHeight: "32px",
              padding: "6px 16px",
            },
            ".MuiTabs-indicator": {
              display: "none",
            },
          },
        },
      },
    },
  };
}
