import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { getToken } from "../../utils/helpers";

// Define the initial state
const initialState = {
  closedPositions: null,
  token: getToken() || null,
  loading: false,
  error: null,
};

export const fetchClosedPositions = createAsyncThunk(
  "accounts/fetchClosedPositions",
  async (idx) => {
    const response = await axiosInstance.get(`/trade/closedpositions/my-closedpositions/${idx}/`);
    return response.data;
  }
);

const tradeSlice = createSlice({
  name: "trade",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClosedPositions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClosedPositions.fulfilled, (state, action) => {
        state.loading = false;
        state.closedPositions = action.payload;
      })
      .addCase(fetchClosedPositions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const tradeLoading = (state) => state.auth.loading;

export default tradeSlice.reducer;
