import React from "react";

export default function DanderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.01158 17.0307H15.9974C17.3157 17.0307 18.1432 15.6057 17.4882 14.4607L11.4999 3.98983C10.8407 2.83733 9.17908 2.8365 8.51908 3.989L2.52075 14.4598C1.86575 15.6048 2.69241 17.0307 4.01158 17.0307Z"
        stroke="#FF4F6F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0021 11.179V8.59566"
        stroke="#FF4F6F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99551 13.75H10.0038"
        stroke="#FF4F6F"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
