import React from "react";

export default function ToolsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.9 6.2C12.9 6.5866 12.5865 6.9 12.2 6.9C11.8135 6.9 11.5 6.5866 11.5 6.2C11.5 5.8134 11.8135 5.5 12.2 5.5C12.5865 5.5 12.9 5.8134 12.9 6.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9 12.2C12.9 12.5865 12.5865 12.9 12.2 12.9C11.8135 12.9 11.5 12.5865 11.5 12.2C11.5 11.8135 11.8135 11.5 12.2 11.5C12.5865 11.5 12.9 11.8135 12.9 12.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9 6.2C18.9 6.5866 18.5865 6.9 18.2 6.9C17.8135 6.9 17.5 6.5866 17.5 6.2C17.5 5.8134 17.8135 5.5 18.2 5.5C18.5865 5.5 18.9 5.8134 18.9 6.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9 12.2C18.9 12.5865 18.5865 12.9 18.2 12.9C17.8135 12.9 17.5 12.5865 17.5 12.2C17.5 11.8135 17.8135 11.5 18.2 11.5C18.5865 11.5 18.9 11.8135 18.9 12.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9 6.2C6.9 6.5866 6.5866 6.9 6.2 6.9C5.8134 6.9 5.5 6.5866 5.5 6.2C5.5 5.8134 5.8134 5.5 6.2 5.5C6.5866 5.5 6.9 5.8134 6.9 6.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9 12.2C6.9 12.5865 6.5866 12.9 6.2 12.9C5.8134 12.9 5.5 12.5865 5.5 12.2C5.5 11.8135 5.8134 11.5 6.2 11.5C6.5866 11.5 6.9 11.8135 6.9 12.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9 18.2C12.9 18.5865 12.5865 18.9 12.2 18.9C11.8135 18.9 11.5 18.5865 11.5 18.2C11.5 17.8135 11.8135 17.5 12.2 17.5C12.5865 17.5 12.9 17.8135 12.9 18.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9 18.2C18.9 18.5865 18.5865 18.9 18.2 18.9C17.8135 18.9 17.5 18.5865 17.5 18.2C17.5 17.8135 17.8135 17.5 18.2 17.5C18.5865 17.5 18.9 17.8135 18.9 18.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9 18.2C6.9 18.5865 6.5866 18.9 6.2 18.9C5.8134 18.9 5.5 18.5865 5.5 18.2C5.5 17.8135 5.8134 17.5 6.2 17.5C6.5866 17.5 6.9 17.8135 6.9 18.2Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
