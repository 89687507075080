import { createSlice } from "@reduxjs/toolkit";

const view = localStorage.getItem("sideView") || "expanded";

const themeSlice = createSlice({
  name: "sidebar",
  initialState: {
    mode: view,
  },
  reducers: {
    togglesidebar: (state) => {
      state.mode = state.mode === "expanded" ? "collapsed" : "expanded";
      localStorage.setItem("sideView", state.mode);
    },
  },
});

export const { togglesidebar } = themeSlice.actions;

export default themeSlice.reducer;
