import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as FilterIcon } from '../../../../Icons/economic_calendar/filter.svg';

const styles = {
  viewButton: {
    height: 'fit-content',
    borderRadius: 5,
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'divider',
    color: 'text.primary',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: '0.8',
    },
    gap: '0.3rem',
    padding: '0.2rem 0.8rem',
  },
};

export default function FilterButton({ onClick }) {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex' }}>
      <Button size='small' sx={styles.viewButton} onClick={onClick}>
        <Typography variant='caption' fontWeight={500} color={'text.secondary'}>
          {'filter'}
        </Typography>
        <FilterIcon
          height={24}
          width={24}
          color={theme.palette.text.secondary}
        />
      </Button>
    </Box>
  );
}
