import { Box, Typography, Stack, Input, InputAdornment } from "@mui/material";
import React from "react";
import SearchIcon from "../Icons/SearchIcon";
import RightIcon from "../Icons/rightIcon";

function PopoverHelp() {
  return (
    <Box sx={{ transition: "all 500ms ease-in-out" }}>
      <Box
        padding={"14px"}
        sx={{
          background: "linear-gradient(135deg, #55AFFF 0%, #6A52FF 100%)",
        }}
      >
        <Typography variant="h2" color={"#ffffff"}>Help center</Typography>
        <Input
          sx={{ height: "36px", marginTop: "14px" }}
          endAdornment={<InputAdornment position="end"></InputAdornment>}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          className="custom_input search-input"
          placeholder="Search for article"
        />
      </Box>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
    </Box>
  );
}

export default PopoverHelp;
