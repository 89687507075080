import React from "react";

function SymbolIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H12C15.0376 0.5 17.5 2.96243 17.5 6V12C17.5 15.0376 15.0376 17.5 12 17.5H6C2.96243 17.5 0.5 15.0376 0.5 12V6Z"
        fill="#26282D"
      />
      <path
        d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H12C15.0376 0.5 17.5 2.96243 17.5 6V12C17.5 15.0376 15.0376 17.5 12 17.5H6C2.96243 17.5 0.5 15.0376 0.5 12V6Z"
        stroke="#212529"
      />
      <path
        d="M9.41619 5.72727H12.8182V6.50852H9.41619V5.72727ZM4.40909 6.50852V5.72727H7.20739L10.4886 12.2188H12.8182V13H10.0199L6.73864 6.50852H4.40909Z"
        fill="white"
      />
    </svg>
  );
}

export default SymbolIcon;
