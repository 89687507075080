export const notifications = [
  {
    time: "Today",
    notifications: [
      {
        user: "{{ user }}",
        status: "pending",
        type: "payout",
        amount: "{{ amount }}",
      },

      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        payoutId: "{{ payoutId }}",
        status: "rejected",
        type: "payout",
        amount: "{{ amount }}",
      },
      {
        user: "{{ user }}",
        status: "pending",
        type: "copyTrader",
        amount: "Copy Trader",
      },
    ],
  },
  {
    time: "Yesterday",
    notifications: [
      {
        ticket: "{{ ticket number }}",
        status: "pending",
        type: "ticket",
      },
      {
        user: "{{ Customer }}",
        customer: "{{ Customer }}",
        status: "pending",
        type: "waiting",
      },

      {
        user: "{{ user }}",
        status: "pending",
        type: "payout",
        amount: "{{ amount }}",
      },
      {
        user: "{{ user }}",
        status: "pending",
        type: "copyTrader",
        amount: "Copy Trader",
      },
      {
        ticket: "{{ ticket number }}",
        status: "pending",
        type: "ticket",
      },
    ],
  },
  {
    time: "July 22, 2024",
    notifications: [
      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        payoutId: "{{ payoutId }}",
        status: "rejected",
        type: "payout",
        amount: "{{ amount }}",
      },
      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        id: "{{ ticketId }}",
        status: "closed",
        type: "ticket",
      },
      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        payoutId: "{{ payoutId }}",
        status: "approved",
        type: "payout",
      },
      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        payoutId: "{{ payoutId }}",
        status: "assigned",
        type: "ticket",
      },
      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        payoutId: "{{ payoutId }}",
        status: "rejected",
        type: "payout",
        amount: "{{ amount }}",
      },
      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        payoutId: "{{ IP Adress }}",
        status: "banned",
        type: "ipAddress",
      },
      {
        userId: "{{ userId }}",
        user: "{{ user }}",
        payoutId: "{{ payoutId }}",
        status: "rejected",
        type: "payout",
        amount: "{{ amount }}",
      },
    ],
  },
];
