import React from "react";

export default function DarkLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116"
      height="25"
      viewBox="0 0 116 25"
      fill="none"
    >
      <path
        d="M24.5353 20.6723L23.148 15.7746H14.8977C14.5387 15.7746 14.2332 15.5454 14.134 15.2069L12.599 9.7771H3.38132C2.80856 9.7771 2.37835 10.3397 2.53109 10.8793L3.91844 15.7771L5.45344 21.2094C5.54763 21.548 5.8531 21.7771 6.21457 21.7771H23.6851C24.2579 21.7771 24.6881 21.2145 24.5353 20.6749V20.6723Z"
        fill="url(#paint0_linear_5772_1093)"
      />
      <path
        d="M10.8696 15.7745H23.1445L21.6095 10.3422C21.5153 10.0036 21.2099 9.77454 20.8484 9.77454H8.56836L10.1034 15.2069C10.2001 15.5454 10.5081 15.7745 10.8671 15.7745H10.8696Z"
        fill="#0594CC"
      />
      <path
        d="M23.1479 15.7746H26.7092C27.2819 15.7746 27.7147 15.212 27.5594 14.6723L24.637 4.34477C24.5428 4.00621 24.2374 3.7771 23.8759 3.7771H8.03966C7.4669 3.7771 7.03415 4.33968 7.18434 4.87935L8.5717 9.7771H20.8517C21.2106 9.7771 21.5161 10.0062 21.6129 10.3448L23.1479 15.7771V15.7746Z"
        fill="url(#paint1_linear_5772_1093)"
      />
      <path
        d="M11.7661 7.59807H10.0478C9.90019 7.59807 9.77036 7.49878 9.72963 7.35623L9.37834 6.11397C9.31724 5.90268 9.47762 5.69141 9.69654 5.69141H11.4148C11.5625 5.69141 11.6923 5.79068 11.733 5.93323L12.0843 7.17549C12.1454 7.38678 11.985 7.59807 11.7661 7.59807Z"
        fill="url(#paint2_linear_5772_1093)"
      />
      <path
        d="M21.8213 20.0155H7.53026C7.38261 20.0155 7.25279 19.9163 7.21206 19.7737L6.86076 18.5315C6.79967 18.3202 6.96004 18.1089 7.17896 18.1089H21.47C21.6177 18.1089 21.7475 18.2082 21.7882 18.3507L22.1395 19.593C22.2006 19.8043 22.0402 20.0155 21.8213 20.0155Z"
        fill="url(#paint3_linear_5772_1093)"
      />
      <path
        d="M50.9817 4.13989L46.7076 19.082L42.2133 4.13989H40.1888L35.692 19.082L31.4204 4.13989H29.5918L34.6051 21.4295H36.681L41.1998 6.41156L45.7211 21.4295H47.7945L52.8103 4.13989H50.9817Z"
        fill="black"
      />
      <path
        d="M58.9986 8.78357C57.0966 8.78357 55.5666 9.40045 54.3549 10.6366C53.1456 11.8704 52.5508 13.4028 52.5508 15.2559C52.5508 17.1089 53.1676 18.6878 54.3794 19.8996C55.5886 21.1088 57.17 21.7282 59.121 21.7282C61.5175 21.7282 63.4685 20.6413 64.5554 18.8886L63.1233 18.0489C62.4306 19.3096 61.023 20.1493 59.1479 20.1493C56.5042 20.1493 54.5532 18.5679 54.2325 16.0735H65.0768C65.1013 15.7528 65.1257 15.4811 65.1257 15.3073C65.1257 13.5546 64.5578 12.0222 63.422 10.737C62.3106 9.42737 60.8296 8.78602 59.001 8.78602L58.9986 8.78357ZM54.2325 14.4897C54.5532 12.0442 56.4307 10.3649 58.9986 10.3649C60.1099 10.3649 61.0989 10.7346 61.9385 11.4518C62.7782 12.169 63.2727 13.18 63.444 14.4897H54.23H54.2325Z"
        fill="black"
      />
      <path
        d="M82.1487 15.7478C82.1487 17.7968 81.0863 18.8102 79.4315 18.8102C77.9505 18.8102 76.986 17.9216 76.986 16.2668V9.07971H73.7988V16.6634C73.7988 18.2692 74.2443 19.503 75.1085 20.4185C75.9971 21.3315 77.1084 21.7771 78.467 21.7771C80.1463 21.7771 81.3825 21.2091 82.1462 20.0488V21.4319H85.3334V9.08216H82.1462V15.7503L82.1487 15.7478Z"
        fill="black"
      />
      <path
        d="M94.2863 8.73462C92.6071 8.73462 91.3709 9.30254 90.6071 10.4629V9.07978H87.4199V21.4295H90.6071V14.7614C90.6071 12.7125 91.6695 11.6991 93.3243 11.6991C94.8053 11.6991 95.7698 12.5876 95.7698 14.2424V21.4295H98.957V13.8459C98.957 12.24 98.5114 11.0063 97.6228 10.0908C96.7587 9.1777 95.6474 8.73218 94.2888 8.73218L94.2863 8.73462Z"
        fill="black"
      />
      <path
        d="M110.236 4.1399V10.5363C109.299 9.32704 107.964 8.7322 106.234 8.7322C104.503 8.7322 103.147 9.37355 101.96 10.6342C100.799 11.8949 100.207 13.4249 100.207 15.2534C100.207 17.082 100.799 18.6365 101.96 19.8972C103.145 21.1578 104.579 21.7747 106.234 21.7747C107.889 21.7747 109.296 21.1823 110.236 19.9706V21.4271H113.423V4.13745H110.236V4.1399ZM109.274 17.7748C108.633 18.4162 107.818 18.7393 106.829 18.7393C105.84 18.7393 105.025 18.4186 104.359 17.7748C103.717 17.109 103.397 16.2693 103.397 15.2559C103.397 14.2425 103.717 13.4028 104.359 12.7615C105.025 12.0956 105.84 11.7725 106.829 11.7725C107.818 11.7725 108.633 12.0932 109.274 12.7615C109.915 13.4028 110.239 14.2425 110.239 15.2559C110.239 16.2693 109.918 17.109 109.274 17.7748Z"
        fill="black"
      />
      <path
        d="M67.3906 14.2816V21.4295H70.5778V12.1421L68.2278 13.0576C67.7235 13.2534 67.3906 13.7406 67.3906 14.2816Z"
        fill="black"
      />
      <path
        d="M67.3905 8.95735V9.07975H65.6133V12.1421H73.1969V9.07975H70.5777V8.95735C70.5777 7.42496 71.4418 6.73465 73.1969 6.85704V3.79469C69.3439 3.57193 67.3929 5.47397 67.3929 8.95735H67.3905Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5772_1093"
          x1="25.6045"
          y1="21.8993"
          x2="6.70079"
          y2="14.7843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8EDFF" />
          <stop offset="1" stopColor="#09B8F1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5772_1093"
          x1="32.0651"
          y1="12.4831"
          x2="17.6417"
          y2="7.88317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8EDFF" />
          <stop offset="1" stopColor="#09B8F1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5772_1093"
          x1="12.5273"
          y1="6.64601"
          x2="8.56374"
          y2="6.64601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#50D5FE" />
          <stop offset="1" stopColor="#E0F4FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5772_1093"
          x1="20.8005"
          y1="19.0635"
          x2="5.65923"
          y2="19.0635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#50D5FE" />
          <stop offset="1" stopColor="#E0F4FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
