import React from "react";

export default function AbookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.7449 4.75H8.74487C8.21444 4.75 7.70573 4.96071 7.33066 5.33579C6.95559 5.71086 6.74487 6.21957 6.74487 6.75V10.25M13.7449 4.75V8.25C13.7449 8.78043 13.9556 9.28914 14.3307 9.66421C14.7057 10.0393 15.2144 10.25 15.7449 10.25H19.2449M13.7449 4.75L19.2449 10.25M19.2449 10.25V17.25C19.2449 17.7804 19.0342 18.2891 18.6591 18.6642C18.284 19.0393 17.7753 19.25 17.2449 19.25H13.7449M10.2449 12.75H9.74487C8.94922 12.75 8.18616 13.0661 7.62355 13.6287C7.06094 14.1913 6.74487 14.9544 6.74487 15.75V16.25C6.74487 17.0456 7.06094 17.8087 7.62355 18.3713C8.18616 18.9339 8.94922 19.25 9.74487 19.25H10.2449M5.74487 16H9.24487"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
