import React, { useState } from "react";
import { Box, Menu, MenuItem, Divider, Typography } from "@mui/material";
import InfoIcon from "../../Icons/InfoIcon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const style = {
  transition: "all 500ms ease-in-out",
  padding: "12px",
  borderRadius: "18px",
  ":hover": {
    background: (theme) => theme.palette.color.gray,
  },
};

const HelpCenter = ({ name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const isExpanded = useSelector((state) => state.sidebar.mode === "expanded");


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "fit-content", position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: "12px",
          pb: "0px",
        }}
        onClick={handleClick}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <InfoIcon />
          {isExpanded && (<Typography variant="subHeading">Help center</Typography>)}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          ".MuiPaper-root": {
            background: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.color.bg3
                : theme.palette.color.bg2,
            zIndex: 9999,
            boxShadow: "none",
            border: (theme) => `1px solid ${theme.palette.color.border}`,
            borderRadius: "12px",
            width: "180px",
            transition: "all 500ms ease-in-out",
            padding: "6px 3px",
          },
          marginLeft: { xs: "0px", lg: "70px" },
        }}
      >
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Twitter
          </Typography>
        </MenuItem>
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Youtube
          </Typography>
        </MenuItem>
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Tiktok
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={style} onClick={() => navigate("/help-center")}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Help Center
          </Typography>
        </MenuItem>
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Tutorials
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Send feedback
          </Typography>
        </MenuItem>
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Report a bug
          </Typography>
        </MenuItem>
        <MenuItem sx={style}>
          <Typography variant="caption" sx={{ fontWeight: "400" }}>
            Features request
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default HelpCenter;
