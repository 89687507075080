import React from "react";

function PopoverHomeIcon({ tabval = "tabHome" }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18734 17.6457H15.8123C16.2986 17.6457 16.7649 17.4525 17.1087 17.1087C17.4525 16.7649 17.6457 16.2986 17.6457 15.8123V8.93734L10.9998 4.354L4.354 8.93734V15.8123C4.354 16.2986 4.54716 16.7649 4.89097 17.1087C5.23479 17.4525 5.70111 17.6457 6.18734 17.6457Z"
        stroke={tabval === "tabHome" ? "white" : "#8A96A6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9375 14.4373C8.9375 13.9511 9.13065 13.4848 9.47447 13.141C9.81829 12.7972 10.2846 12.604 10.7708 12.604H11.2292C11.7154 12.604 12.1817 12.7972 12.5255 13.141C12.8693 13.4848 13.0625 13.9511 13.0625 14.4373V17.6457H8.9375V14.4373Z"
        stroke={tabval === "tabHome" ? "white" : "#8A96A6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PopoverHomeIcon;
