import React from "react";

export default function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.1439 8.74997L14.4189 6.20697C14.1189 5.15897 12.9729 4.53697 11.8569 4.81697L6.30091 6.21697C5.18491 6.49697 4.52291 7.57497 4.82091 8.62297L6.58091 14.793C6.88091 15.841 8.02691 16.463 9.14291 16.183L9.74991 16.03M15.1439 8.74997H11.7499C11.2195 8.74997 10.7108 8.96068 10.3357 9.33575C9.96062 9.71083 9.74991 10.2195 9.74991 10.75V16.03M15.1439 8.74997H17.2499C17.7803 8.74997 18.289 8.96068 18.6641 9.33575C19.0392 9.71083 19.2499 10.2195 19.2499 10.75V17.25C19.2499 17.7804 19.0392 18.2891 18.6641 18.6642C18.289 19.0393 17.7803 19.25 17.2499 19.25H11.7499C11.2195 19.25 10.7108 19.0393 10.3357 18.6642C9.96062 18.2891 9.74991 17.7804 9.74991 17.25V16.03"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
