import React from "react";

function AiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4 4H4.01M4 12H4.01M12 4H12.01M12 12H12.01M9.25 8C9.25 8.69 8.69 9.25 8 9.25C7.31 9.25 6.75 8.69 6.75 8C6.75 7.31 7.31 6.75 8 6.75C8.69 6.75 9.25 7.31 9.25 8ZM9.25 2C9.25 2.69 8.69 3.25 8 3.25C7.31 3.25 6.75 2.69 6.75 2C6.75 1.31 7.31 0.75 8 0.75C8.69 0.75 9.25 1.31 9.25 2ZM15.25 8C15.25 8.69 14.69 9.25 14 9.25C13.31 9.25 12.75 8.69 12.75 8C12.75 7.31 13.31 6.75 14 6.75C14.69 6.75 15.25 7.31 15.25 8ZM3.25 8C3.25 8.69 2.69 9.25 2 9.25C1.31 9.25 0.75 8.69 0.75 8C0.75 7.31 1.31 6.75 2 6.75C2.69 6.75 3.25 7.31 3.25 8ZM9.25 14C9.25 14.69 8.69 15.25 8 15.25C7.31 15.25 6.75 14.69 6.75 14C6.75 13.31 7.31 12.75 8 12.75C8.69 12.75 9.25 13.31 9.25 14Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AiIcon;
