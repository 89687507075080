import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../ui/CustomCard";
import Dark120Image from "../../assets/dark120.png";
import Light120Image from "../../assets/light120.png";
import Dark240Image from "../../assets/dark240.png";
import Light240Image from "../../assets/light240.png";
import Dark350Image from "../../assets/dark300250.png";
import Light350Image from "../../assets/light300250.png";
import dark360Image from "../../assets/dark300600.png";
import Light360Image from "../../assets/light300600.png";
import dark480Image from "../../assets/dark480.png";
import Light480Image from "../../assets/light480.png";
import Light600Image from "../../assets/light600.png";
import dark600Image from "../../assets/dark600.png";
import dark780Image from "../../assets/dark780.png";
import light780Image from "../../assets/light780.png";
import UseSelect from "../ui/BaseSelect";

export default function PormotionalPage() {
  return (
    <Box mx={2} mt={5} mb={3} minHeight={"calc(100vh - 200px)"}>
      <Stack direction={"column"} gap={1.5}>
        <Typography variant="caption">
          Feel free to use any of these banners to promote We-Fund. To use these
          images on your website follow the list below:
        </Typography>
        <Typography variant="caption">
          1. Right click on any image and select save as, or click the button
          below to download a zipped version of all images.
        </Typography>
        <Stack direction={"column"} gap={0.8}>
          <Typography variant="caption">
            2. Upload the image to your website and note url of your website. If
            you are using a WordPress website it could be something like
          </Typography>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            className="custom_input"
            fullWidth
            sx={{
              width: "450px",
              marginLeft: "15px",
              input: {
                background: (theme) =>
                  theme.palette.color.blackShade + "! important",
                borderRadius: "32px !important",
              },
            }}
            value={"https://firm.vuetra.com/join/partnerprogram"}
          />
        </Stack>
        <Stack direction={"column"} gap={0.8}>
          <Typography variant="caption">
            3. Create an image link using some HTML code.
          </Typography>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            className="custom_input"
            fullWidth
            sx={{
              width: "650px",
              marginLeft: "15px",
              input: {
                background: (theme) =>
                  theme.palette.color.blackShade + "! important",
                borderRadius: "32px !important",
              },
            }}
            value={
              "<a href='https:/we-fund.com?kaid=AFFILIATEID'><img src='mysite.com/image.jpg' /></a>"
            }
          />
        </Stack>
        <Typography variant="caption">
          4. Modify the URL to the image in the code above to make sure it
          points to the image you uploaded to your website
        </Typography>
      </Stack>
      <Box mt={2}>
        <Button className="customButton custom_color_btn purple_btn">
          Download all images
        </Button>
      </Box>
      <Box mt={4}>
        <Stack direction={"column"} gap={4}>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              sx={{
                button: {
                  minWidth: "100%",
                  borderRadius: "16px",
                  padding: "14px",
                  marginTop: 1
                },
              }}
            >
              <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>Language</Typography>
              <UseSelect
                options={[
                  { value: "english", label: "English" },
                  { value: "german", label: "German" },
                  { value: "french", label: "French" },
                ]}
                defaultValue="english"
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                button: {
                  minWidth: "100%",
                  borderRadius: "16px",
                  padding: "14px",
                  marginTop: 1

                },
              }}
            >
              <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>Category</Typography>
              <UseSelect
                options={[
                  { value: "wordpress", label: "Wordpress" },
                  { value: "yesterday", label: "Yesterday" },
                  { value: "thisweek", label: "This Week" },
                ]}
                defaultValue="wordpress "
              />
            </Grid>
          </Grid>
          <Typography
            variant="h5"
            color={(theme) => theme.palette.color.darkText}
          >
            If you don't see any image below please switch off your ad blocker.
          </Typography>
          <CustomCard customPadding="0">
            <Stack direction={"column"} gap={1.5}>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  background: (theme) => theme.palette.color.lightShade,
                }}
              >
                <Typography
                  variant="subHeaddingMedium"
                  color={(theme) => theme.palette.color.darkText}
                >
                  120x120 Banners
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={1}>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={1}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Dark120Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={1}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Light120Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Stack>
          </CustomCard>
          <CustomCard customPadding="0">
            <Stack direction={"column"} gap={1.5}>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  background: (theme) => theme.palette.color.lightShade,
                }}
              >
                <Typography
                  variant="subHeaddingMedium"
                  color={(theme) => theme.palette.color.darkText}
                >
                  240x240 Banners
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={2}>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Dark240Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Light240Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Stack>
          </CustomCard>
          <CustomCard customPadding="0">
            <Stack direction={"column"} gap={1.5}>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  background: (theme) => theme.palette.color.lightShade,
                }}
              >
                <Typography
                  variant="subHeaddingMedium"
                  color={(theme) => theme.palette.color.darkText}
                >
                  300x250 Banners
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={2}>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={3}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Dark350Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={3}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Light350Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Stack>
          </CustomCard>
          <CustomCard customPadding="0">
            <Stack direction={"column"} gap={1.5}>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  background: (theme) => theme.palette.color.lightShade,
                }}
              >
                <Typography
                  variant="subHeaddingMedium"
                  color={(theme) => theme.palette.color.darkText}
                >
                  300x250 Banners
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={2}>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={3}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={dark360Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={3}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Light360Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Stack>
          </CustomCard>
          <CustomCard customPadding="0">
            <Stack direction={"column"} gap={1.5}>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  background: (theme) => theme.palette.color.lightShade,
                }}
              >
                <Typography
                  variant="subHeaddingMedium"
                  color={(theme) => theme.palette.color.darkText}
                >
                  480x60 Banners
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={2}>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={5}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={dark480Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={5}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Light480Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Stack>
          </CustomCard>
          <CustomCard customPadding="0">
            <Stack direction={"column"} gap={1.5}>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  background: (theme) => theme.palette.color.lightShade,
                }}
              >
                <Typography
                  variant="subHeaddingMedium"
                  color={(theme) => theme.palette.color.darkText}
                >
                  600x300 Banners
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={2}>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={5}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={dark600Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={5}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={Light600Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Stack>
          </CustomCard>
          <CustomCard customPadding="0">
            <Stack direction={"column"} gap={1.5}>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  background: (theme) => theme.palette.color.lightShade,
                }}
              >
                <Typography
                  variant="subHeaddingMedium"
                  color={(theme) => theme.palette.color.darkText}
                >
                  728x90 Banners
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={2}>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={7}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={dark780Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <Grid item md={7}>
                          <Box
                            sx={{
                              img: {
                                width: "100%",
                              },
                            }}
                            key={index}
                          >
                            <img src={light780Image} alt="" />
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Stack>
          </CustomCard>
        </Stack>
      </Box>
    </Box>
  );
}
