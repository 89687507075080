import React from "react";

export default function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M15.255 4.58334H17.5031L12.5662 10.0293L18.3343 17.4167H13.808L10.2641 12.9278L6.20797 17.4167H3.95903L9.18914 11.5922L3.66577 4.58334H8.30478L11.5059 8.68451L15.255 4.58334ZM14.4678 16.1379H15.7142L7.64953 5.81443H6.31061L14.4678 16.1379Z"
        fill="white"
      />
    </svg>
  );
}
