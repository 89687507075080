import React from "react";
import CustomCard from "../ui/CustomCard";
import { Box, Stack, Typography } from "@mui/material";
import ChartIcon from "../Icons/ChartIcon";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export default function AccountCard({ account }) {
  return (
    <CustomCard customPadding={"16px"}>
      <Box
        flexDirection={"column"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        sx={{
          ".image_div": {
            position: "absolute",
            right: "0",
            bottom: "0",
          },
        }}
      >
        <Box
          width={"100%"}
          sx={{
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
            pb: 2,
            mb: 3,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              flexDirection={"row"}
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    account.status === "ACTIVE"
                      ? theme.palette.color.skyColor
                      : account.status === "InActive"
                      ? theme.palette.color.greenShade
                      : theme.palette.color.red,
                  borderRadius: "50%",
                  height: "6px",
                  width: "6px",
                }}
              />
              <Typography
                variant="h5"
                color={(theme) => theme.palette.color.darkText}
                sx={{
                  a: {
                    textDecoration: "none",
                    color: (theme) => theme.palette.color.darkText 
                  }
                }}
              >
                <Link to={`/account/${account.id}`}>{account.id}</Link>
              </Typography>
            </Stack>
            <Link to={`/account/${account.id}`}><ChartIcon /></Link>
          </Stack>
          <Typography variant="subtitle2">Challenge name</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stack
              flexDirection={"row"}
              sx={{
                justifyContent: "space-between",
                paddingBlock: "8px",
              }}
            >
              <Typography variant="subHeading">Start Date</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                {dayjs(account?.startDate).format("MMMM D, YYYY")}
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{
                justifyContent: "space-between",
                paddingBlock: "8px",
              }}
            >
              <Typography variant="subHeading">Phase</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                {account?.phase.title || "Phase"}
              </Typography>
            </Stack>
            {account.status === "ACTIVE" && (
              <Stack
                flexDirection={"row"}
                sx={{
                  justifyContent: "space-between",
                  paddingBlock: "8px",
                }}
              >
                <Typography variant="subHeading">Balance</Typography>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  ${account?.initial_balance || 0}
                </Typography>
              </Stack>
            )}
            {account.status !== "ACTIVE" && (
              <Stack
                flexDirection={"row"}
                sx={{
                  justifyContent: "space-between",
                  paddingBlock: "8px",
                }}
              >
                <Typography variant="subHeading">Status</Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Box
                    sx={{
                      width: 6,
                      height: 6,
                      borderRadius: "50%",
                      background: (theme) =>
                        account.status === "InActive"
                          ? theme.palette.color.greenShade
                          : theme.palette.color.red,
                    }}
                  />
                  <Typography
                    variant="heading_500"
                    color={(theme) =>
                      account.status === "InActive"
                        ? theme.palette.color.greenShade
                        : theme.palette.color.red
                    }
                  >
                    {account.status === "InActive" ? "Passed" : "Failed"}
                  </Typography>
                </Stack>
              </Stack>
            )}
            {account.status === "InActive" && (
              <Stack
                flexDirection={"row"}
                sx={{
                  justifyContent: "space-between",
                  paddingBlock: "8px",
                }}
              >
                <Typography variant="subHeading">New Account</Typography>
                <Typography
                  variant="heading_500"
                  sx={{
                    a: {
                      color: (theme) => theme.palette.color.greenShade,
                    },
                  }}
                >
                  <Link>#1050501</Link>
                </Typography>
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
}
