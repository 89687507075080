import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import AuthLayout from "../components/Login/AuthLayout";
import LeftArrow from "../components/Icons/LeftArrow";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendPasswordRecoveryEmail } from "../slices/auth/forgotPasswordSlice";

export default function CheckEmail() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const dispatch = useDispatch();

  const handleEmailResend = () => {
    dispatch(sendPasswordRecoveryEmail(email));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
  };
  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: (theme) => theme.palette.color.bg,
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Check your email
          </Typography>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
              paddingTop: "24px",
              paddingBottom: "48px",
              fontSize: "16px",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.color.secondary,
              }}
            >
              We sent a password reset link to{" "}
              <Typography
                variant="paragraph"
                sx={{ color: (theme) => theme.palette.color.primary }}
              >
                {email}
              </Typography>
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ maxWidth: "590px", width: "100%" }}
            >
              <Button
                className="custom_color_btn"
                fullWidth
                sx={{
                  padding: "14px 0 !important",
                  backgroundColor: `${(theme) => theme.palette.color.lightSky} !important`,
                  bgcolor: (theme) => theme.palette.color.lightSky,
                  borderRadius: "32px",
                  color: (theme) => theme.palette.color.blackWhiteText,
                  fontSize: "14px",
                  textTransform: "Capitalize",
                  lineHeight: "22px",
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.color.lightSky,
                  },
                }}
                type="submit"
              >
                Open Email
              </Button>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  paddingTop: "48px",
                  justifyContent: "center",
                  fontSize: "14px",
                }}
              >
                <Typography
                  variant="subtitle2_500"
                  sx={{
                    a: {
                      color: (theme) => theme.palette.color.skyColor,
                      textDecoration: "none",
                    },
                  }}
                >
                  Didn't receive the email?{" "}
                  <Button onClick={handleEmailResend} sx={{
                    padding: 0,
                    textTransform: "capitalize",
                    lineHeight: 1,
                    color: (theme) => theme.palette.color.skyColor
                  }}>
                    Resend
                  </Button>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  paddingTop: "48px",
                  justifyContent: "center",
                  "a": {
                    textDecoration: "none"
                  }
                }}
              >
                <Link to="/login" variant="body2">
                  <Typography
                    variant="heading_500"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <LeftArrow /> Back to login
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
