import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ to }) => {
  const {auth} = useSelector((state) => state);
  return auth.token ? <Outlet /> : <Navigate to={to} />;
};

export default PrivateRoute;
