import {
  Badge,
  Box,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { notifications } from "../../../static/header";
import PendingNotification from "./Notifications/PendingNotification";
import ActivityNotification from "./Notifications/ActivityNotification";
import NotificationIcon from "../../Icons/NotificationIcon";

const iconStyle = {
  color: (theme) => theme.palette.color.secondary,
  fontSize: "22px",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Notifications() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredNotifications = (notification) => {
    if (value === 0) return !notification.complete; // "All"
    if (value === 1)
      return notification.status !== "pending" && !notification.complete; // "Account"
    if (value === 2) return !notification.complete; // "News"
    if (value === 3) return !notification.complete; // "Affiliate"
    if (value === 4) return !notification.complete; // "Leaderboard"
  };

  return (
    <Box>
      <IconButton onClick={handleOpen} className="custom_btn_icon" sx={{
        ".MuiBadge-badge": {
          backgroundColor: (theme) => theme.palette.color.purple,
        }
      }}>
        <Badge color="secondary" variant="dot">
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            height: "fit-content",
            padding: "24px",
            background: "transparent",
            mt: 6,
            boxShadow: "none",
          },
        }}
        open={open}
        onClose={handleClose}
        anchor="right"
      >
        <Paper
          sx={{
            width: "460px",
            height: "100%",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg5,
            border: (theme) => `1px solid ${theme.palette.color.strockborder}`,
            borderRadius: "24px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "18px 24px",
            }}
          >
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Typography variant="h6">Notifications </Typography>
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  background: (theme) => theme.palette.color.lightgray,
                  borderRadius: "4px",
                  border: `1px solid #15191F`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="heading">11</Typography>
              </Box>
            </Box>

            <IconButton onClick={handleClose}>
              <CloseIcon sx={iconStyle} />
            </IconButton>
          </Stack>

          <Box
            sx={{
              width: "100%",
              borderBottom: (theme) =>
                `1px solid ${theme.palette.color.border}`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pl: "16px",
              pr: "18px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& button.Mui-selected": {
                    color: (theme) => theme.palette.color.darkText,
                  },
                  "+ .MuiTabs-indicator": {
                    background: "transparent",
                  },
                }}
              >
                <Tab
                  label="All"
                  {...a11yProps(0)}
                  sx={{
                    backgroundColor: (theme) =>
                      value === 0
                        ? `${theme.palette.color.lightgray}`
                        : "transparent",
                    color: (theme) =>
                      value === 0
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,
                    borderRadius: "32px",
                    border: "none",
                    px: "12px",
                    py: "6px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Account"
                  {...a11yProps(1)}
                  sx={{
                    backgroundColor: (theme) =>
                      value === 1
                        ? `${theme.palette.color.lightgray}`
                        : "transparent",
                    color: (theme) =>
                      value === 1
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,

                    borderRadius: "32px",
                    border: "none",
                    px: "12px",
                    py: "6px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="News"
                  {...a11yProps(2)}
                  sx={{
                    backgroundColor: (theme) =>
                      value === 2
                        ? `${theme.palette.color.lightgray}`
                        : "transparent",
                    color: (theme) =>
                      value === 2
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,

                    borderRadius: "32px",
                    border: "none",
                    px: "12px",
                    py: "6px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Affiliate"
                  {...a11yProps(3)}
                  sx={{
                    backgroundColor: (theme) =>
                      value === 3
                        ? `${theme.palette.color.lightgray}`
                        : "transparent",
                    color: (theme) =>
                      value === 3
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,

                    borderRadius: "32px",
                    border: "none",
                    px: "12px",
                    py: "6px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Leaderboard"
                  {...a11yProps(4)}
                  sx={{
                    backgroundColor: (theme) =>
                      value === 4
                        ? `${theme.palette.color.lightgray}`
                        : "transparent",
                    color: (theme) =>
                      value === 4
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,

                    borderRadius: "32px",
                    border: "none",
                    px: "12px",
                    py: "6px",
                    textTransform: "none",
                  }}
                />
              </Tabs>
            </Box>
          </Box>

          <Box
            sx={{
              height: "500px",
              overflowY: "auto",
              px: "20px",
            }}
            spacing={1}
          >
            {notifications
              .filter((n) =>
                n.notifications.some((notification) =>
                  filteredNotifications(notification)
                )
              )
              .map((n, index) => (
                <Stack key={index} spacing={0}>
                  <Typography variant="caption_500" sx={{ pt: "20px" }}>
                    {n.time}
                  </Typography>
                  {n.notifications
                    .filter(filteredNotifications)
                    .map((notification) =>
                      notification.status === "pending" ? (
                        <PendingNotification
                          key={notification.id}
                          notification={notification}
                        />
                      ) : (
                        !notification.complete && (
                          <ActivityNotification
                            key={notification.id}
                            notification={notification}
                          />
                        )
                      )
                    )}
                </Stack>
              ))}
          </Box>
        </Paper>
      </Drawer>
    </Box>
  );
}
