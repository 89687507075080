import React from "react";

function FileIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H24C27.3137 0 30 2.68629 30 6V24C30 27.3137 27.3137 30 24 30H6C2.68629 30 0 27.3137 0 24V6Z"
        fill="#26282D"
      />
      <path
        d="M20.4375 18.9375V13.3125C20.4375 12.9147 20.2795 12.5331 19.9982 12.2518C19.7169 11.9705 19.3353 11.8125 18.9375 11.8125H9.5625V18.9375C9.5625 19.3353 9.72054 19.7169 10.0018 19.9982C10.2831 20.2795 10.6647 20.4375 11.0625 20.4375H18.9375C19.3353 20.4375 19.7169 20.2795 19.9982 19.9982C20.2795 19.7169 20.4375 19.3353 20.4375 18.9375Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.125 11.625L15.4268 10.344C15.2977 10.1074 15.1072 9.91004 14.8755 9.77252C14.6437 9.635 14.3792 9.56245 14.1097 9.5625H11.0625C10.6647 9.5625 10.2831 9.72054 10.0018 10.0018C9.72054 10.2831 9.5625 10.6647 9.5625 11.0625V14.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileIcon;
