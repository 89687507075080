import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../utils/apiConstant';
import { showSnackbar } from '../snackbarSlice';
import { getToken, removeToken, setToken } from '../../utils/helpers';

// Define the initial state
const initialState = {
  user: null,
  token: getToken() || null,
  status: 'idle',
  error: null,
  api_id: null,
  snackbar: {
    open: false,
    message: '',
    severity: 'success',
  },
};

// Define the async thunk for login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/login/`, credentials);      
      setToken(response.data.key); 
      return response.data;
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message, severity: 'error' }));
      return rejectWithValue(err.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/auth/registration/`, credentials);
      dispatch(showSnackbar({ message: 'Registration successful!', severity: 'success' }));
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000)
    } catch (err) {
      Object.keys(err.response.data).forEach((key) => {
        const errMsg = err.response.data[key][0]; // Assuming the first error message for each field
        dispatch(showSnackbar({ message: `${errMsg}`, severity: 'error' }));
      });
      return rejectWithValue(err.response.data);
    }
  }
);

// Define the async thunk for logout
export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      await axios.post(`${BASE_URL}/auth/logout/`, {});
      removeToken();
      localStorage.removeItem('mode');
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Create the slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.token = action.payload.key;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = 'succeeded';
        state.user = null;
        state.token = null;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const loginLoading = (state) => state.auth.status;

export default authSlice.reducer;
