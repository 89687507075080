import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import AuthLayout from "../components/Login/AuthLayout";
import { useSelector } from "react-redux";

export default function ReserSuccess() {
  const loading = useSelector((state) => state.auth.status);
  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
  };
  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: (theme) => theme.palette.color.bg,
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Password reset
          </Typography>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
              paddingTop: "24px",
              paddingBottom: "48px",
              fontSize: "16px",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.color.secondary,
                lineHeight: "22px",
              }}
            >
              Your password has been successfully reset. Click below to log in
              magically.
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, maxWidth: "590px", width: "100%" }}
            >
              <Button   className="custom_color_btn "
                fullWidth
                sx={{
                  padding: "14px 0 !important",
                  backgroundColor: `${(theme) => theme.palette.color.lightSky} !important`,
                  bgcolor: (theme) => theme.palette.color.lightSky,
                  borderRadius: "32px",
                  color: (theme) => theme.palette.color.blackWhiteText,
                  fontSize: "14px",
                  textTransform: "Capitalize",
                  lineHeight: "22px",
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.color.lightSky,
                  },
                }}
                type="submit"
                disabled={loading === "loading"}>
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
