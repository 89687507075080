import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { getToken } from "../../utils/helpers";

// Define the initial state
const initialState = {
  accounts: null,
  token: getToken() || null,
  loading: false,
  error: null,
  accountDetails: null,
  singleAccountDetails: null,
  accountStatistics: null,
  leaderBoardData: null,
  positionData: null,
  certificateData: null,
  balanceHistoryData: null
};

export const fetchAccounts = createAsyncThunk(
  "accounts/fetchAccounts",
  async () => {
    const response = await axiosInstance.get("/accounts/accounts/my-accounts/");
    
    return response.data;
  }
);

export const fetchAccountAccess = createAsyncThunk(
  "accounts/fetchAccountAccess",
  async (idx) => {
    const response = await axiosInstance.get(`/accounts/accounts/${idx}/access-details/`);
    return response.data;
  }
);

export const fetchAccountDetails = createAsyncThunk(
  "accounts/fetchAccountDetails",
  async (idx) => {
    const response = await axiosInstance.get(`/accounts/accounts/${idx}/`);
    return response.data;
  }
);

export const fetchAccountStatistics = createAsyncThunk(
  "accounts/fetchAccountStatistics",
  async (idx) => {
    const response = await axiosInstance.get(`/accounts/accounts/${idx}/statistics/`);
    return response.data;
  }
);

export const fetchAccountLeaderBoard = createAsyncThunk(
  "accounts/fetchAccountLeaderBoard",
  async (page) => {
    const response = await axiosInstance.get(`/accounts/leaderboard?page_size=10&page=${page}`);
    return response.data;
  }
);

export const fetchCertificates = createAsyncThunk(
  "accounts/fetchCertificates",
  async (accId) => {
    const response = await axiosInstance.get(`/payment/certificates/my/${accId}/`);
    return response.data;
  }
);

export const fetchBalanceHistory = createAsyncThunk(
  "accounts/fetchBalanceHistory",
  async (accId) => {
    const response = await axiosInstance.get(`/trade/balancehistory/my-balancehistory/${accId}/`);
    return response.data;
  }
);

const accountSlice = createSlice({
  name: "accounts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.accounts = action.payload;
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAccountAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountAccess.fulfilled, (state, action) => {     
        state.loading = false;
        state.accountDetails = action.payload;
      })
      .addCase(fetchAccountAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAccountDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountDetails.fulfilled, (state, action) => {     
        state.loading = false;
        state.singleAccountDetails = action.payload;
      })
      .addCase(fetchAccountDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAccountStatistics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountStatistics.fulfilled, (state, action) => {     
        state.loading = false;
        state.accountStatistics = action.payload;
      })
      .addCase(fetchAccountStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAccountLeaderBoard.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountLeaderBoard.fulfilled, (state, action) => {     
        state.loading = false;
        state.leaderBoardData = action.payload;
      })
      .addCase(fetchAccountLeaderBoard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCertificates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCertificates.fulfilled, (state, action) => {     
        state.loading = false;
        state.certificateData = action.payload;
      })
      .addCase(fetchCertificates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBalanceHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBalanceHistory.fulfilled, (state, action) => {     
        state.loading = false;
        state.balanceHistoryData = action.payload;
      })
      .addCase(fetchBalanceHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const loginLoading = (state) => state.auth.loading;

export default accountSlice.reducer;
