import { Box, Button, IconButton, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import CrossIcon from "../Icons/CrossIcon";
import LinkIcon from "../Icons/LinkIcon";
import WeFundLogo from "../../assets/logo.png";
import DiscordIcon from "../Icons/DiscordIcon";

export default function IntegrationModal({
  isOpen = false,
  onCloseModal = () => {},
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    width: "100%",
    bgcolor: "background.paper",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.strockborder,
    boxShadow: 24,
    borderRadius: "16px",
  };
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "16px",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={{
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText,
                },
              }}
            >
              <LinkIcon />
              <Typography
                variant="h5"
                color={(theme) => theme.palette.color.darkText}
              >
                Connect Integration
              </Typography>
            </Stack>
            <IconButton
              sx={{
                background: (theme) => theme.palette.color.lightgray,
                borderRadius: "32px",
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText,
                },
              }}
              onClick={onCloseModal}
            >
              <CrossIcon />
            </IconButton>
          </Stack>
        </Box>
        <Box
          sx={{
            padding: "24px 16px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Box sx={{
                width: 150,
                height: 150,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid",
                borderRadius: "16px",
                borderColor: (theme) => theme.palette.color.strockborder,
                padding: "16px"
            }}>
              <img src={WeFundLogo} alt="" style={{ width: "100%" }}/>
            </Box>
            <Box sx={{
                width: 165,
                textAlign: "center",
                borderBottom: "1px dashed",
                position: "relative",
                borderColor: (theme) => theme.palette.color.strockborder
            }}>
              <Box sx={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%)",
                top: "-15px",
                background: (theme) => theme.palette.color.lightgray,
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "svg path": {
                    stroke: (theme) => theme.palette.color.darkText,
                }
              }}>
                <LinkIcon />
              </Box>
            </Box>
            <Box
             sx={{
                width: 150,
                height: 150,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid",
                borderRadius: "16px",
                borderColor: (theme) => theme.palette.color.strockborder,
                padding: "16px"
            }}>
                <DiscordIcon />
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            p: "16px",
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={3.5}
          >
            <Button
              className="custom_color_btn customButton"
              onClose={onCloseModal}
            >
              Connect
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
