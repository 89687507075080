// src/app/Router.js

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import { navItems } from "../app/navitems";
import PrivateRoute from "./PrivateRouter";
import PublicRoute from "./PublicRoute";
import CustomSnackbar from "../components/ui/CustomSnackbar";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<PrivateRoute to="/login" />}
        >
          <Route path="/" element={<Layout />}>
            {navItems
              .filter((item) => item.isPrivate)
              .map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  element={item.element}
                />
              ))}
          </Route>
        </Route>
        <Route element={<PublicRoute to="/" />}>
          {navItems
            .filter((item) => !item.isPrivate)
            .map((item) => (
              <Route key={item.path} path={item.path} element={item.element} />
            ))}
        </Route>

        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <CustomSnackbar />
    </BrowserRouter>
  );
}
