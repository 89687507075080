import { Box, Pagination, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    headcells,
    fontSize,
    borderTopValue = false,
  } = props;

  return (
    <TableHead>
      <TableRow
        sx={{ borderTop: borderTopValue ? "1px solid #15191FA6" : "none" }}
      >
        {headcells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              paddingTop: "22px !important",
              paddingBottom: "22px !important",
              ...(headCell.padding && {
                padding: headCell.padding + " !important",
              }),
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                color: (theme) => theme.palette.color.secondary + " !important",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: "500",
                  ...(fontSize && { fontSize: fontSize + " !important" }),
                  color: (theme) =>
                    theme.palette.color.secondary + " !important",
                }}
              >
                {headCell.label2 || headCell.label}
              </Typography>
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const LeaderboardTable = ({
  headcells,
  rows,
  onRequestSort,
  order,
  orderBy,
  onRowClick,
  fontSize,
  checkbox,
  selected = [],
  setSelected,
  actionComponent,
  borderTopValue = false,
  page,
  onPageChange,
  count
}) => {
  // const handleRequestSort = (property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   if (onRequestSort) onRequestSort(isAsc ? "desc" : "asc", property);
  // };

  // const handleSelectAllClick = () => {
  //   if (selected.length !== rows.length) {
  //     const newSelected = rows.map((n, index) => index);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <>
      <TableContainer
        sx={{
          mb: 3,
        }}
      >
        <Table
          sx={{
            minWidth: 750,
            maxWidth: "100%",
            overflowX: "auto",
            color: (theme) => theme.palette.color.secondary,
          }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            // onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            headcells={headcells}
            fontSize={fontSize}
            checkbox={checkbox}
            // allSelected={selected.length === rows.length}
            borderTopValue={borderTopValue}
          />
          <TableBody>
            {rows?.map((row, index) => {
              const isItemSelected = isSelected(index);
              return (
                <TableRow
                  hover
                  onClick={() => onRowClick && onRowClick(row)}
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    cursor: "pointer",
                    "&:hover .action": { opacity: 1 },
                    color: (theme) =>
                      theme.palette.color.secondary + " !important",
                  }}
                >
                  {headcells.map((headcell) => (
                    <TableCell
                      key={headcell.id}
                      align={headcell.align || "left"}
                      sx={{
                        ...(fontSize && {
                          fontSize: fontSize + " !important",

                          color: (theme) =>
                            theme.palette.color.secondary + " !important",
                        }),
                        color: (theme) => theme.palette.color.secondary,
                        py: "22px !important",
                      }}
                    >
                      <Stack
                        sx={{
                          color: (theme) =>
                            theme.palette.color.secondary + " !important",
                        }}
                      >
                        {" "}
                        {headcell.getCell(row, index)}
                      </Stack>
                    </TableCell>
                  ))}
                  {actionComponent && (
                    <TableCell align="right">
                      <Box
                        className="action"
                        sx={{
                          opacity: 0,
                        }}
                      >
                        {actionComponent}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {rows?.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={headcells.length}
                  align="left"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: (theme) =>
                      theme.palette.color.secondary + " !important",
                  }}
                >
                  <Typography variant="caption_500">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Stack alignItems={'flex-end'} sx={{ mt: 3 }}>
        <Pagination
          count={Math.ceil(count / 10)}
          page={page}
          onChange={onPageChange}
          siblingCount={0}
          showFirstButton
          showLastButton
        />
        </Stack>
      </TableContainer>
    </>
  );
};

export default LeaderboardTable;
