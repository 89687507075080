import { createSlice } from "@reduxjs/toolkit";

const defaultUserTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
const mode = localStorage.getItem("mode") || defaultUserTheme;

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    mode: mode,
  },
  reducers: {
    toggleTheme: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
      localStorage.setItem("mode", state.mode);
    },
  },
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
