import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../ui/CustomCard";
import UpAngleArrowIcon from "../Icons/UpAngleArrowIcon";
import ReferralTable from "./ReferralTable";
import UseSelect from "../ui/BaseSelect";
import { useNavigate } from "react-router-dom";

export default function PaymentHistory() {
  const navigate = useNavigate();

  return (
    <Box mx={2} mt={5} mb={3} minHeight={"calc(100vh - 200px)"}>
      <Box
        sx={{
          mb: 4,
        }}
      >
        <Box mb={2}>
          <Typography
            variant="heading"
            color={(theme) => theme.palette.color.darkText}
          >
            Last 30 days
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <CustomCard customPadding="16px">
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={0.5}
                sx={{
                  "svg path": {
                    stroke: (theme) => theme.palette.color.green,
                  },
                }}
              >
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.green}
                >
                  95.6%
                </Typography>
                <UpAngleArrowIcon />
              </Stack>
              <Box>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Referrals</Typography>
                  <Typography variant="heading2">41</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/referral")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={4}>
            <CustomCard customPadding="16px">
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={0.5}
                sx={{
                  "svg path": {
                    stroke: (theme) => theme.palette.color.red,
                  },
                }}
              >
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.red}
                >
                  95.6%
                </Typography>
                <UpAngleArrowIcon />
              </Stack>
              <Box>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Visits</Typography>
                  <Typography variant="heading2">156</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={4}>
            <CustomCard customPadding="16px">
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={0.5}
                sx={{
                  "svg path": {
                    stroke: (theme) => theme.palette.color.green,
                  },
                }}
              >
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.green}
                >
                  95.6%
                </Typography>
                <UpAngleArrowIcon />
              </Stack>
              <Box>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Conversion Rate</Typography>
                  <Typography variant="heading2">5.77%</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/conversion")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          mb: 4,
        }}
      >
        <Box mb={2}>
          <Typography
            variant="heading"
            color={(theme) => theme.palette.color.darkText}
            sx={{
              marginBottom: "16px",
            }}
          >
            All time
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Box mt={4}>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Referrals</Typography>
                  <Typography variant="heading2">181</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Box mt={4}>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Visits</Typography>
                  <Typography variant="heading2">4100</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Box mt={4}>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Conversion Rate</Typography>
                  <Typography variant="heading2">5.77%</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Box mt={4}>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">
                    Unpaid Referrals
                  </Typography>
                  <Typography variant="heading2">11</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Box mt={4}>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Paid Referrals</Typography>
                  <Typography variant="heading2">170</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Box mt={4}>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Unpaid Earnings</Typography>
                  <Typography variant="heading2">$497.25</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Box mt={4}>
                <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                  <Typography variant="heading_500">Total Earnings</Typography>
                  <Typography variant="heading2">$7,876.17</Typography>
                  <Button
                    className="customButton outline_btn view_all"
                    onClick={() => navigate("/partner/visits")}
                  >
                    View all
                  </Button>
                </Stack>
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          mb: 4,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant="heading"
            color={(theme) => theme.palette.color.darkText}
          >
            Recent referral activity
          </Typography>
          <UseSelect
            options={[
              { value: "today", label: "Today" },
              { value: "yesterday", label: "Yesterday" },
              { value: "thisweek", label: "This Week" },
              { value: "lastweek", label: "Last Week" },
              { value: "thismonth", label: "This Month" },
              { value: "lastmonth", label: "Last Month" },
            ]}
            defaultValue="thisweek"
          />
        </Stack>
        <Box mt={2}>
          <ReferralTable />
        </Box>
      </Box>
    </Box>
  );
}
