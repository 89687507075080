import React from "react";

export default function CardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <g clipPath="url(#clip0_4322_35786)">
        <rect
          x="4.41667"
          y="6.33331"
          width="24.6667"
          height="19.3333"
          rx="4.33333"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M24.1878 14.9191C24.3009 15.0322 24.3964 15.156 24.4742 15.2903C24.5838 15.4636 24.6651 15.658 24.7111 15.8525C24.8772 16.5065 24.704 17.2313 24.1878 17.7475C23.8236 18.1117 23.3569 18.3026 22.8832 18.3238C22.6852 18.338 22.4837 18.3203 22.2927 18.2708C21.9498 18.1895 21.6245 18.0127 21.3594 17.7475C20.9775 17.3657 20.7795 16.8636 20.7725 16.3616C20.7654 15.8383 20.9634 15.3151 21.3594 14.9191C21.7766 14.5019 22.3352 14.3039 22.8797 14.3393C23.3569 14.364 23.8272 14.5585 24.1878 14.9191Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4322_35786">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
