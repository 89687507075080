import React from "react";

export default function EuroIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
    >
      <path
        d="M7.38281 4.38778L6.91051 5.41193H0.372869L0.745739 4.38778H7.38281ZM6.4929 6.40625L6.01065 7.44034H0.372869L0.745739 6.40625H6.4929ZM8.59588 1.68821L8.00923 2.94602C7.82363 2.78693 7.62145 2.64276 7.4027 2.51349C7.18395 2.38423 6.94863 2.28149 6.69673 2.20526C6.44484 2.12571 6.17472 2.08594 5.88636 2.08594C5.35606 2.08594 4.88376 2.22846 4.46946 2.51349C4.05516 2.79853 3.72869 3.22609 3.49006 3.79616C3.25474 4.36293 3.13707 5.06889 3.13707 5.91406C3.13707 6.75923 3.25474 7.4652 3.49006 8.03196C3.72869 8.59872 4.05516 9.02462 4.46946 9.30966C4.88376 9.59138 5.35606 9.73224 5.88636 9.73224C6.17803 9.73224 6.44981 9.69413 6.7017 9.6179C6.95691 9.54167 7.18892 9.44223 7.39773 9.3196C7.60985 9.19366 7.80043 9.06108 7.96946 8.92188L8.57102 10.1747C8.2197 10.4929 7.81203 10.7332 7.34801 10.8956C6.884 11.058 6.39678 11.1392 5.88636 11.1392C5.05445 11.1392 4.317 10.9287 3.67401 10.5078C3.03433 10.0869 2.5322 9.48698 2.16761 8.7081C1.80303 7.9259 1.62074 6.99455 1.62074 5.91406C1.62074 4.83026 1.80303 3.89725 2.16761 3.11506C2.5322 2.33286 3.03433 1.7313 3.67401 1.31037C4.317 0.889441 5.05445 0.678977 5.88636 0.678977C6.41667 0.678977 6.91051 0.766808 7.3679 0.942471C7.82528 1.11482 8.23461 1.3634 8.59588 1.68821Z"
        fill="#8A96A6"
      />
    </svg>
  );
}
