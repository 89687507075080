import React from "react";

function PopoverChatIcon({tabval}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M3.78502 13.9444L5.0268 12.9688L5.03549 12.9623C5.29406 12.758 5.42552 12.6559 5.5711 12.5831C5.70147 12.5179 5.84053 12.469 5.98502 12.4408C6.1469 12.4082 6.31638 12.4082 6.65426 12.4082H13.7279C14.6394 12.4082 15.0957 12.4082 15.4434 12.2333C15.7498 12.0801 15.9996 11.8346 16.1561 11.5336C16.3332 11.1914 16.3332 10.7438 16.3332 9.84967V3.76029C16.3332 2.86508 16.3332 2.41639 16.1561 2.07417C15.9986 1.77323 15.7498 1.52878 15.4423 1.37451C15.0947 1.20068 14.6384 1.20068 13.7258 1.20068H4.27391C3.36132 1.20068 2.90502 1.20068 2.55628 1.37451C2.24991 1.52878 2.00003 1.77323 1.84359 2.07417C1.6665 2.41747 1.6665 2.86508 1.6665 3.76246V12.9449C1.6665 13.7988 1.6665 14.2247 1.84359 14.4431C1.99895 14.6343 2.23362 14.7451 2.48241 14.7451C2.76705 14.7451 3.10601 14.4778 3.78502 13.9444Z"
        stroke={tabval === "tabChat" ? "white" : "#8A96A6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PopoverChatIcon;
