import React from "react";

export default function DiscordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
    >
      <path
        d="M38.8937 10.66C36.2337 9.41997 33.3538 8.51997 30.3538 7.99997C30.3274 7.99913 30.3013 8.00406 30.2771 8.01443C30.2529 8.0248 30.2313 8.04034 30.2138 8.05997C29.8538 8.71997 29.4338 9.57997 29.1537 10.24C25.9718 9.75997 22.7358 9.75997 19.5538 10.24C19.2738 9.55997 18.8538 8.71997 18.4738 8.05997C18.4538 8.01997 18.3938 7.99997 18.3337 7.99997C15.3337 8.51997 12.4738 9.41997 9.79375 10.66C9.77375 10.66 9.75375 10.68 9.73375 10.7C4.29375 18.84 2.79375 26.76 3.53375 34.6C3.53375 34.64 3.55375 34.68 3.59375 34.7C7.19375 37.34 10.6538 38.94 14.0738 40C14.1338 40.02 14.1937 40 14.2137 39.96C15.0138 38.86 15.7338 37.7 16.3538 36.48C16.3938 36.4 16.3538 36.32 16.2738 36.3C15.1337 35.86 14.0538 35.34 12.9938 34.74C12.9138 34.7 12.9137 34.58 12.9738 34.52C13.1938 34.36 13.4137 34.18 13.6337 34.02C13.6737 33.98 13.7338 33.98 13.7738 34C20.6538 37.14 28.0737 37.14 34.8738 34C34.9138 33.98 34.9737 33.98 35.0138 34.02C35.2337 34.2 35.4537 34.36 35.6737 34.54C35.7537 34.6 35.7537 34.72 35.6537 34.76C34.6137 35.38 33.5138 35.88 32.3738 36.32C32.2938 36.34 32.2738 36.44 32.2938 36.5C32.9338 37.72 33.6538 38.88 34.4338 39.98C34.4938 40 34.5537 40.02 34.6137 40C38.0537 38.94 41.5137 37.34 45.1137 34.7C45.1537 34.68 45.1737 34.64 45.1737 34.6C46.0537 25.54 43.7137 17.68 38.9737 10.7C38.9537 10.68 38.9338 10.66 38.8937 10.66ZM17.3938 29.82C15.3338 29.82 13.6138 27.92 13.6138 25.58C13.6138 23.24 15.2938 21.34 17.3938 21.34C19.5138 21.34 21.1938 23.26 21.1737 25.58C21.1737 27.92 19.4938 29.82 17.3938 29.82ZM31.3337 29.82C29.2738 29.82 27.5538 27.92 27.5538 25.58C27.5538 23.24 29.2337 21.34 31.3337 21.34C33.4538 21.34 35.1337 23.26 35.1137 25.58C35.1137 27.92 33.4538 29.82 31.3337 29.82Z"
        fill="white"
      />
    </svg>
  );
}
