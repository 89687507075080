import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomTabs from "../components/ui/CustomTabs";
import AccountCard from "../components/Accounts/AccountCard";
import AddEvaluationCard from "../components/Accounts/AddEvaluationCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccounts } from "../slices/accounts/myaccounts";

const tabsList = [
  {
    label: "All",
  },
  {
    label: "Active",
  },
  {
    label: "Passed",
  },
  {
    label: "Breached",
  },
];

export default function AllAccounts() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const dispatch = useDispatch();
  const { accounts: accountData } = useSelector((state) => state.accounts);

  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);  

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const activeAccounts = accountData?.filter(
    (account) => account.status === "ACTIVE"
  );
  const inactiveAccounts = accountData?.filter(
    (account) => account.status === "InActive"
  );
  const breachedAccounts = accountData?.filter(
    (account) => account.status === "Failed"
  );  

  return (
    <Box mx={2} mt={4} mb={3} minHeight={"calc(100vh - 200px)"}>
      <Box>
        <CustomTabs
          onChange={handleTabChange}
          value={selectedTab}
          tabs={tabsList}
        />
        <Box my={4}>
          {selectedTab === 0 && (
            <Box>
              <Box mb={4}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={0.5}
                  sx={{
                    mb: 2,
                  }}
                >
                  <Typography variant="heading">Active</Typography>
                  <Typography
                    variant="heading"
                    color={(theme) => theme.palette.color.secondary}
                  >{`(${activeAccounts?.length})`}</Typography>
                </Stack>
                <Grid container spacing={2}>
                  {activeAccounts?.map((account, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <AccountCard key={index} account={account} />{" "}
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box mb={4}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={0.5}
                  sx={{
                    mb: 2,
                  }}
                >
                  <Typography variant="heading">Inactive account</Typography>
                  <Typography
                    variant="heading"
                    color={(theme) => theme.palette.color.secondary}
                  >{`(${inactiveAccounts.length})`}</Typography>
                </Stack>
                <Grid container spacing={2}>
                  {inactiveAccounts.map((account, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <AccountCard key={index} account={account} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={0.5}
                  sx={{
                    mb: 2,
                  }}
                >
                  <Typography variant="heading">Breached</Typography>
                  <Typography
                    variant="heading"
                    color={(theme) => theme.palette.color.secondary}
                  >{`(${breachedAccounts.length})`}</Typography>
                </Stack>
                <Grid container spacing={2}>
                  {breachedAccounts.map((account, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <AccountCard key={index} account={account} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
          {selectedTab === 1 && (
            <Box>
              <Grid container spacing={2}>
                {activeAccounts.map((account, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <AccountCard key={index} account={account} />
                  </Grid>
                ))}
                <AddEvaluationCard />
              </Grid>
            </Box>
          )}
          {selectedTab === 2 && (
            <Box>
              <Grid container spacing={2}>
                {inactiveAccounts.map((account, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <AccountCard key={index} account={account} />
                  </Grid>
                ))}
                <AddEvaluationCard />
              </Grid>
            </Box>
          )}
          {selectedTab === 3 && (
            <Box>
              <Grid container spacing={2}>
                {breachedAccounts.map((account, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <AccountCard key={index} account={account} />
                  </Grid>
                ))}
                <AddEvaluationCard />
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
