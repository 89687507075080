import React from "react";

export default function UpDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.5625 11.0625V14.4375H6.9375M14.4375 6.9375V3.5625H11.0625M3.75 14.25L7.6875 10.3125M14.25 3.75L10.3125 7.6875"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
