import React from "react";

export default function LightModeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 5.59982V4M12 18.4002V20M7.5314 7.5314L6.39974 6.39974M16.5821 16.5821L17.7138 17.7138M5.59982 12H4M18.4002 12H20M16.583 7.5314L17.7147 6.39974M7.53228 16.5821L6.39974 17.7138M12 15.9996C9.79122 15.9996 8.00044 14.2088 8.00044 12C8.00044 9.79122 9.79122 8.00044 12 8.00044C14.2088 8.00044 15.9996 9.79122 15.9996 12C15.9996 14.2088 14.2088 15.9996 12 15.9996Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
