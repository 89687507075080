import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../components/ui/CustomCard";
import ContainerBg from "../assets/Containor.png";
import AnswerHelped from "../components/HelpCenter/AnswerHelped";

function HelpArticle() {
  return (
    <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
      <div style={{ width: "300px" }}>SideBar section</div>
      <Box
        sx={{
          paddingTop: "45px",
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Box
            paddingBottom={"16px"}
            sx={{
              width: "100%",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  fontSize: "16px",
                }}
              >
                Lorem ipsum dolor sit amet
              </Typography>
              <Typography
                variant="paragraph"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "14px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </Typography>
            </Box>
            <Box
              paddingTop={"16px"}
              paddingBottom={"16px"}
              sx={{
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
              }}
            >
              <CustomCard customPadding="0">
                <Box padding={"16px"}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: (theme) => theme.palette.color.primary,
                      fontSize: "16px",
                    }}
                  >
                    Lorem ipsum dolor sit amet
                  </Typography>
                  <Typography
                    variant="paragraph"
                    sx={{
                      color: (theme) => theme.palette.color.secondary,
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                    }}
                  >
                    <ul style={{ paddingLeft: "23px", paddingTop: "16px" }}>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </CustomCard>
            </Box>
            <Box paddingTop={"16px"}>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  fontSize: "16px",
                }}
              >
                Lorem ipsum dolor sit amet
              </Typography>
              <Typography
                variant="paragraph"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "14px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </Typography>
            </Box>
            <Grid container paddingTop={"16px"}>
              <Grid item xs={12} md={12}>
                <img
                  style={{ width: "100%", borderRadius: "16px" }}
                  src={ContainerBg}
                  alt=""
                />
              </Grid>
            </Grid>
            <Box paddingTop={"16px"}>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  fontSize: "16px",
                }}
              >
                Lorem ipsum dolor sit amet
              </Typography>
              <Typography
                variant="paragraph"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "14px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </Typography>
            </Box>
              <AnswerHelped />
          </Box>
        </Container>
      </Box>
    </Stack>
  );
}

export default HelpArticle;
