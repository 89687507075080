import React, { useState } from 'react';

import { Box, Typography, Button } from '@mui/material';

import CalendarItem from './CalendarItem';
import { selectedData } from './dummyData';


const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

const date = new Date();
const today = date.getDay();
const currentHour = date.getHours();
const monday = new Date(
  date.setDate(date.getDate() - (today === 0 ? 6 : today - 1))
);

const week = Array.from({ length: 5 }, (_, i) => {
  const day = new Date(monday);
  day.setDate(day.getDate() + i);
  return day;
});

const weekDays = week.map((day) => days[day.getDay() - 1]);

const styles = {
  formBox: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    pb: 1,
  },
  gridBox: (uniqueHours, direction) => ({
    display: 'grid',
    gridTemplateColumns:
      direction === 'vertical'
        ? 'auto repeat(5, 1fr)'
        : `auto repeat(${uniqueHours.length}, 1fr)`,
    gridTemplateRows:
      direction === 'vertical'
        ? `auto repeat(${uniqueHours.length}, auto)`
        : 'auto repeat(5, auto)',
    gridColumnGap: '0px',
    gridRowGap: '0px',
    width: '100%',
    height: '100%',
  }),
  dayBox: (index, direction) => ({
    gridArea:
      direction === 'vertical'
        ? `1 / ${index + 2} / 1 / ${index + 3}`
        : `${index + 2} / 1 / ${index + 3} / 2`,
    color: today === index + 1 && today < 6 ? 'text.primary' : 'text.secondary',
    display: direction === 'vertical' ? 'block' : 'flex',
    justifyContent: direction === 'vertical' ? 'center' : 'center',
    alignItems: direction === 'vertical' ? 'center' : 'center',
    padding: direction === 'vertical' ? '0px' : '10px',
  }),
  hoursBoxVertical: (hourIndex, dayIndex) => ({
    gridArea: `${hourIndex + 2} / ${dayIndex + 2} / ${hourIndex + 3} / ${
      dayIndex + 3
    }`,
    borderBottom: '0.5px solid',
    borderColor: 'divider',
    backgroundColor:
     'transparent',
  }),
  hoursBoxHorizontal: (hourIndex, dayIndex) => ({
    gridArea: `${dayIndex + 2} / ${hourIndex + 2} / ${dayIndex + 3} / ${
      hourIndex + 3
    }`,
    borderBottom: '0.5px solid',
    borderColor: 'divider',
    backgroundColor:
      today === dayIndex + 1 && today < 6 ? 'background.paper' : 'transparent',
  }),
  hoursBox: (index, direction, currentHour, hour) => ({
    gridArea:
      direction === 'vertical'
        ? `${index + 2} / 1 / ${index + 3} / 2`
        : `1 / ${index + 2} / 1 / ${index + 3}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: currentHour === hour ? 'background.paper' : 'transparent',
    borderBottom: direction === 'vertical' ? '0.5px solid' : 'none',
    borderColor: 'divider',
  }),
  hoursTypo: (direction, currentHour, hour) => ({
    padding: direction === 'vertical' ? '10px' : '0px',
    color: currentHour === hour ? 'text.primary' : 'text.secondary',
  }),
  calendarItem: (getGridFunc, groupedEventsDate) => ({
    gridArea: getGridFunc(groupedEventsDate),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0.5rem',
    gap: '1rem',
  }),
  expandButton: {
    '&:hover': {
      backgroundColor: 'background.oldPaper',
    },
    backgroundColor: 'background.oldPaper',
    borderRadius: 4,
    padding: '0.2rem 1rem',
  },
};

export default function WeeklyCalendar() {
  const [expandedRows, setExpandedRows] = useState({});

  const calendarData = selectedData;
  const direction = "vertical";


  // TODO: FIX WHEN THE DATA IS NORMAL
  const startDate = new Date('2023-11-01');
  const endDate = new Date('2023-11-24');

  const filteredData = calendarData.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  const uniqueHours = [
    ...new Set(
      filteredData.map((event) => {
        const date = new Date(event.date);
        return date.getHours();
      })
    ),
  ].sort((a, b) => a - b);

  function getGridAreaForEvent(date) {
    const eventDate = new Date(date);
    const day = eventDate.getDay();
    const hour = eventDate.getHours();
    const row = uniqueHours.indexOf(hour) + 2;
    const column = day + 1;
    return direction === 'vertical'
      ? `${row} / ${column} / ${row} / ${column}`
      : `${column} / ${row} / ${column} / ${row}`;
  }

  const groupedEvents = filteredData.reduce(
    (acc, event) => {
      const eventDate = new Date(event.date);
      const year = eventDate.getFullYear();
      const month = eventDate.getMonth();
      const day = eventDate.getDate();
      const hour = eventDate.getHours();
      const key = `${year}-${month}-${day}-${hour}`;

      if (!acc.groups[key]) {
        acc.groups[key] = [];
        acc.dates.push(event.date);
      }

      acc.groups[key].push(event);
      return acc;
    },
    { groups: {}, dates: [] }
  );

  const { groups, dates } = groupedEvents;

  const eventsWithGroupedEvents = Object.keys(groups).map((key, index) => {
    return {
      date: dates[index],
      events: groups[key],
    };
  });

  return (
    <>
      {groupedEvents.dates.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography>
            Couldn&apos;t find any Calendar data. Please try again later.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box>
            <Box sx={styles.formBox}>
              <Box sx={styles.gridBox(uniqueHours, direction)}>
                <Box sx={{ gridArea: '1 / 1 / 2 / 2' }}>
                  {/* Place that could be used for timezone feature */}
                </Box>
                {weekDays.map((day, index) => (
                  <React.Fragment key={index}>
                    <Box sx={styles.dayBox(index, direction)}>
                      <Typography textAlign={'center'} variant='body2'>
                        {day}
                      </Typography>
                    </Box>
                  </React.Fragment>
                ))}
                {direction === 'vertical'
                  ? uniqueHours.map((_hour, hourIndex) =>
                    week.map((_day, dayIndex) => (
                      <Box
                        key={`${hourIndex}-${dayIndex}`}
                        sx={styles.hoursBoxVertical(hourIndex, dayIndex)}
                      />
                    ))
                  )
                  : week.map((_hour, dayIndex) =>
                    uniqueHours.map((_day, hourIndex) => (
                      <Box
                        key={`${hourIndex}-${dayIndex}`}
                        sx={styles.hoursBoxHorizontal(hourIndex, dayIndex)}
                      />
                    ))
                  )}
                {uniqueHours.map((hour, index) => (
                  <Box
                    key={index}
                    sx={styles.hoursBox(index, direction, currentHour, hour)}
                  >
                    <Typography
                      variant='body2'
                      sx={styles.hoursTypo(direction, currentHour, hour)}
                    >
                      {hour.toString().padStart(2, '0').padEnd(5, ':00')}
                    </Typography>
                  </Box>
                ))}
                {eventsWithGroupedEvents.map((groupedEvents, index) => (
                  <Box
                    sx={styles.calendarItem(
                      getGridAreaForEvent,
                      groupedEvents.date
                    )}
                    key={index}
                  >
                    {(expandedRows[groupedEvents.date] ||
                    groupedEvents.events.length <= 2
                      ? groupedEvents.events
                      : groupedEvents.events.slice(0, 2)
                    ).map((event, index) => {
                      const eventDay = new Date(event.date).getDay();
                      const eventHour = new Date(event.date).getHours();

                      return (
                        <CalendarItem
                          key={index}
                          index={index}
                          event={event}
                          eventDay={eventDay}
                          eventHour={eventHour}
                        />
                      );
                    })}
                    {groupedEvents.events.length > 2 && (
                      <Button
                        disableRipple
                        sx={styles.expandButton}
                        onClick={() =>
                          setExpandedRows({
                            ...expandedRows,
                            [groupedEvents.date]:
                              !expandedRows[groupedEvents.date],
                          })
                        }
                      >
                        <Typography
                          variant='caption'
                          textTransform={'none'}
                          color={'text.primary'}
                        >
                          {expandedRows[groupedEvents.date]
                            ? 'collapse'
                            : 'expand'}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}