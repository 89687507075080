import React from "react";

export default function DashboardIcon({ stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.75 10.25V5.75C4.75 5.48478 4.85536 5.23043 5.04289 5.04289C5.23043 4.85536 5.48478 4.75 5.75 4.75H9.25C9.51522 4.75 9.76957 4.85536 9.95711 5.04289C10.1446 5.23043 10.25 5.48478 10.25 5.75V10.25C10.25 10.5152 10.1446 10.7696 9.95711 10.9571C9.76957 11.1446 9.51522 11.25 9.25 11.25H5.75C5.48478 11.25 5.23043 11.1446 5.04289 10.9571C4.85536 10.7696 4.75 10.5152 4.75 10.25ZM4.75 18.25V15.75C4.75 15.4848 4.85536 15.2304 5.04289 15.0429C5.23043 14.8554 5.48478 14.75 5.75 14.75H9.25C9.51522 14.75 9.76957 14.8554 9.95711 15.0429C10.1446 15.2304 10.25 15.4848 10.25 15.75V18.25C10.25 18.5152 10.1446 18.7696 9.95711 18.9571C9.76957 19.1446 9.51522 19.25 9.25 19.25H5.75C5.48478 19.25 5.23043 19.1446 5.04289 18.9571C4.85536 18.7696 4.75 18.5152 4.75 18.25ZM13.75 6.25V5.75C13.75 5.48478 13.8554 5.23043 14.0429 5.04289C14.2304 4.85536 14.4848 4.75 14.75 4.75H18.25C18.5152 4.75 18.7696 4.85536 18.9571 5.04289C19.1446 5.23043 19.25 5.48478 19.25 5.75V6.25C19.25 6.51522 19.1446 6.76957 18.9571 6.95711C18.7696 7.14464 18.5152 7.25 18.25 7.25H14.75C14.4848 7.25 14.2304 7.14464 14.0429 6.95711C13.8554 6.76957 13.75 6.51522 13.75 6.25ZM13.75 18.25V11.75C13.75 11.4848 13.8554 11.2304 14.0429 11.0429C14.2304 10.8554 14.4848 10.75 14.75 10.75H18.25C18.5152 10.75 18.7696 10.8554 18.9571 11.0429C19.1446 11.2304 19.25 11.4848 19.25 11.75V18.25C19.25 18.5152 19.1446 18.7696 18.9571 18.9571C18.7696 19.1446 18.5152 19.25 18.25 19.25H14.75C14.4848 19.25 14.2304 19.1446 14.0429 18.9571C13.8554 18.7696 13.75 18.5152 13.75 18.25Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
