import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Emoji1 from "../../assets/emoji1.png";
import Emoji2 from "../../assets/emoji2.png";
import Emoji3 from "../../assets/emoji3.png";

function AnswerHelped() {
  return (
    <Box paddingTop={"36px"}>
      <Typography
        variant="h6"
        sx={{
          color: (theme) => theme.palette.color.primary,
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        Did this answer your question?
      </Typography>
      <Stack
        direction={"row"}
        gap={"30px"}
        justifyContent={"center"}
        pt={"24px"}
      >
        <Box width={"18px"}>
          <img style={{ width: "100%" }} src={Emoji1} alt="" />
        </Box>
        <Box width={"18px"}>
          <img style={{ width: "100%" }} src={Emoji2} alt="" />
        </Box>
        <Box width={"18px"}>
          <img style={{ width: "100%" }} src={Emoji3} alt="" />
        </Box>
      </Stack>
    </Box>
  );
}

export default AnswerHelped;
