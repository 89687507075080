import React from "react";

export default function AndroidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_4500_1201)">
        <g clipPath="url(#clip1_4500_1201)">
          <path
            d="M4.575 3.5C4.157 3.5 3.757 3.666 3.461 3.961C3.166 4.257 3 4.657 3 5.075V19.925C3 20.343 3.166 20.743 3.461 21.039C3.757 21.334 4.157 21.5 4.575 21.5H19.425C19.843 21.5 20.243 21.334 20.539 21.039C20.834 20.743 21 20.343 21 19.925V5.075C21 4.657 20.834 4.257 20.539 3.961C20.243 3.666 19.843 3.5 19.425 3.5H4.575ZM14.672 14.123C14.747 14.198 14.843 14.249 14.946 14.269C15.05 14.29 15.158 14.279 15.255 14.239C15.353 14.199 15.436 14.13 15.495 14.042C15.554 13.954 15.586 13.851 15.586 13.745C15.586 13.639 15.555 13.536 15.496 13.448C15.437 13.359 15.354 13.291 15.256 13.25C15.158 13.21 15.05 13.199 14.946 13.22C14.842 13.241 14.747 13.293 14.672 13.368C14.623 13.418 14.583 13.477 14.556 13.542C14.529 13.606 14.516 13.676 14.516 13.746C14.516 13.816 14.529 13.886 14.556 13.951C14.583 14.016 14.623 14.074 14.672 14.123ZM8.955 14.24C9.02 14.267 9.09 14.281 9.16 14.281C9.265 14.281 9.369 14.249 9.457 14.19C9.545 14.131 9.613 14.048 9.653 13.95C9.694 13.853 9.704 13.746 9.684 13.642C9.664 13.538 9.613 13.443 9.539 13.368C9.437 13.278 9.304 13.229 9.167 13.233C9.031 13.237 8.901 13.293 8.804 13.39C8.708 13.486 8.652 13.616 8.648 13.753C8.644 13.889 8.692 14.022 8.783 14.124C8.832 14.174 8.89 14.213 8.955 14.24ZM16.31 9.234L15.24 11.077C16.16 11.575 16.941 12.296 17.51 13.174C18.08 14.051 18.42 15.058 18.501 16.101H5.7C5.783 15.057 6.125 14.05 6.695 13.172C7.265 12.293 8.046 11.571 8.965 11.07L7.902 9.229C7.887 9.204 7.877 9.176 7.873 9.147C7.868 9.117 7.87 9.088 7.878 9.059C7.885 9.031 7.898 9.004 7.916 8.981C7.934 8.957 7.957 8.938 7.982 8.923C8.008 8.909 8.036 8.899 8.065 8.896C8.095 8.893 8.124 8.895 8.153 8.903C8.181 8.912 8.207 8.925 8.23 8.944C8.253 8.963 8.272 8.986 8.286 9.012L9.367 10.876C10.229 10.488 11.163 10.289 12.108 10.291C13.052 10.288 13.986 10.489 14.845 10.881L15.926 9.017C15.955 8.966 16.003 8.929 16.059 8.913C16.115 8.897 16.176 8.905 16.227 8.933C16.278 8.962 16.315 9.01 16.331 9.066C16.346 9.123 16.339 9.183 16.31 9.234Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4500_1201">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3.5)"
          />
        </clipPath>
        <clipPath id="clip1_4500_1201">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 3.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
