import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { showSnackbar } from "../snackbarSlice";

// Define the initial state
const initialState = {
  loading: false,
  error: null,
};

// Async thunk for updating the password
export const verifyOtp = createAsyncThunk(
  "password/verifyOtp",
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/api/client/confirm-change-password", credentials);
      dispatch(showSnackbar({ message: "Password successfully updated", severity: 'success' }));
      return response.data.data;
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message, severity: 'error' }));
      return rejectWithValue(err.response.data);
    }
  }
);

// Password slice
const verifySlice = createSlice({
  name: "otp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || action.error.message;
      });
  },
});

// Selector to get loading state
export const selectOtpLoading = (state) => state.loading;

// Selector to get error
export const selectOtpError = (state) => state.error;

export default verifySlice.reducer;
