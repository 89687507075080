import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTabs from "../components/ui/CustomTabs";
import LeaderboardTable from "../components/Leaderboard/leaderboardTable";
// import UseSelect from "../components/ui/BaseSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccountLeaderBoard } from "../slices/accounts/myaccounts";

const tabsList = [
  {
    label: "All",
  },
  {
    label: "10K",
  },
  {
    label: "25K",
  },
  {
    label: "50K",
  },
  {
    label: "100K",
  },
  {
    label: "200K",
  },
];

const headcells = [
  {
    id: "rank",
    label: "#",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.rank}
      </Typography>
    ),
  },
  {
    id: "full_name",
    label: "Name",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.full_name}
      </Typography>
    ),
  },
  {
    id: "live_account_equity",
    label: "Equity",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.live_account_equity}
      </Typography>
    ),
  },
  {
    id: "growth_percent",
    label: "Gain %",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.growth_percent.toFixed(2)}%
      </Typography>
    ),
  },
  {
    id: "profit_loss",
    label: "Profit",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.profit_loss}
      </Typography>
    ),
  },
  {
    id: "win_trade_percentage",
    label: "Won Trade Percent",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.win_trade_percentage.toFixed(2)}%
      </Typography>
    ),
  },
];

export default function LeaderboardListing() {
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedVal, setSelectedVal] = useState(null);

  const { leaderBoardData } = useSelector((state) => state.accounts);
  
  const handleChange = (_event, value) => {
    setPage(value);
  };

  console.log('selectedVal', selectedVal);
  

  const filteredData = selectedVal === null 
    ? leaderBoardData?.results 
    : leaderBoardData?.results?.filter(item => {
        const equity = parseFloat(item.live_account_equity);
        return equity >= 0 && equity <= selectedVal;
    });


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccountLeaderBoard(page));
  }, [dispatch, page]);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
    if(newValue === 0) {
      setSelectedVal(null);
    } else if(newValue === 1) {
      setSelectedVal(10000);
    } else if(newValue === 2) {
      setSelectedVal(25000);
    } else if(newValue === 3) {
      setSelectedVal(50000);
    } else if(newValue === 4) {
      setSelectedVal(100000);
    } else {
      setSelectedVal(200000);
    }
  };
  
  return (
    <Box
      mx={2}
      mt={5}
      mb={3}
      minHeight={"calc(100vh - 200px)"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CustomTabs
            onChange={handleTabChange}
            value={selectedTab}
            tabs={tabsList}
          />
          {/* <UseSelect
            options={[
              { value: "today", label: "Today" },
              { value: "yesterday", label: "Yesterday" },
              { value: "thisweek", label: "This Week" },
              { value: "lastweek", label: "Last Week" },
              { value: "thismonth", label: "This Month" },
              { value: "lastmonth", label: "Last Month" },
            ]}
            defaultValue="thisweek"
          /> */}
        </Stack>
        <Box mt={4}>
          <LeaderboardTable
            headcells={headcells}
            rows={filteredData}
            page={page}
            onPageChange={handleChange}
            count={leaderBoardData?.count}
          />
        </Box>
      </Box>
    </Box>
  );
}
