import React from 'react'
import WeeklyCalendar from './economic_calendar/EconomicCalendar'
import { Box } from '@mui/material'
import FilterButton from './economic_calendar/FilterButton'

export default function EconomicCalendar() {
  return (
    <Box mx={2} mt={4} mb={3} minHeight={"calc(100vh - 200px)"}>
      <FilterButton />
      <WeeklyCalendar />
    </Box>
  )
}
