import React from "react";

export default function GermanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_4662_760)">
        <path
          d="M0.87085 18.8695C2.84895 24.2007 7.98055 28 14.0001 28C20.0196 28 25.1512 24.2007 27.1293 18.8695L14.0001 17.6522L0.87085 18.8695Z"
          fill="#FFDA44"
        />
        <path
          d="M14.0001 0C7.98055 0 2.84895 3.79925 0.87085 9.13046L14.0001 10.3478L27.1293 9.13041C25.1512 3.79925 20.0196 0 14.0001 0Z"
          fill="black"
        />
        <path
          d="M0.870789 9.13046C0.308055 10.6471 0 12.2875 0 14C0 15.7125 0.308055 17.3529 0.870789 18.8695H27.1293C27.692 17.3529 28 15.7125 28 14C28 12.2875 27.692 10.6471 27.1292 9.13046H0.870789Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_4662_760">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
