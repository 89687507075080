import React from "react";

export default function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.75 9.5V13.012C6.74981 13.8186 6.99347 14.6064 7.449 15.272L10 19M17.25 14.25V10.47C17.2499 9.83952 17.0511 9.22511 16.682 8.714L14 5M9.25 7C9.25 7.29547 9.1918 7.58806 9.07873 7.86104C8.96566 8.13402 8.79992 8.38206 8.59099 8.59099C8.38206 8.79992 8.13402 8.96566 7.86104 9.07873C7.58806 9.1918 7.29547 9.25 7 9.25C6.70453 9.25 6.41194 9.1918 6.13896 9.07873C5.86598 8.96566 5.61794 8.79992 5.40901 8.59099C5.20008 8.38206 5.03434 8.13402 4.92127 7.86104C4.8082 7.58806 4.75 7.29547 4.75 7C4.75 6.40326 4.98705 5.83097 5.40901 5.40901C5.83097 4.98705 6.40326 4.75 7 4.75C7.59674 4.75 8.16903 4.98705 8.59099 5.40901C9.01295 5.83097 9.25 6.40326 9.25 7ZM19.25 17C19.25 17.5967 19.0129 18.169 18.591 18.591C18.169 19.0129 17.5967 19.25 17 19.25C16.4033 19.25 15.831 19.0129 15.409 18.591C14.9871 18.169 14.75 17.5967 14.75 17C14.75 16.4033 14.9871 15.831 15.409 15.409C15.831 14.9871 16.4033 14.75 17 14.75C17.5967 14.75 18.169 14.9871 18.591 15.409C19.0129 15.831 19.25 16.4033 19.25 17Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 15.75V19.25H6.75M13.75 8.25V4.75H17.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
