import React from "react";

export default function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0003 9.37516C11.4961 9.37516 12.7087 8.1626 12.7087 6.66683C12.7087 5.17106 11.4961 3.9585 10.0003 3.9585C8.50455 3.9585 7.29199 5.17106 7.29199 6.66683C7.29199 8.1626 8.50455 9.37516 10.0003 9.37516Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70635 16.042H14.2938C15.2454 16.042 15.9784 15.2237 15.5341 14.3823C14.8803 13.1446 13.3901 11.667 10.0001 11.667C6.61011 11.667 5.11982 13.1446 4.46613 14.3823C4.02174 15.2237 4.75475 16.042 5.70635 16.042Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
