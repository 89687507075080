export default function themeTypography(theme) {
  return {
    fontFamily: "Inter, sans-serif",
    h1_bigger: {
      fontWeight: 600,
      color: theme.colors.primary,
      fontSize: "32px",
      lineHeight: "40px"
    },
    h1: {
      fontWeight: 600,
      color: theme.colors.primary,
      fontSize: "28px",
    },
    h2: {
      fontSize: "24px",
      color: theme.colors.primary,
      fontWeight: 500,
    },
    h2_600: {
      fontSize: "24px",
      color: theme.colors.primary,
      fontWeight: 600,
      lineHeight: "32px"
    },
    h3: {
      fontSize: "20px",
      color: theme.colors.primary,
      fontWeight: 700,
    },
    h3_semibold: {
      fontSize: "20px",
      color: theme.colors.primary,
      fontWeight: 600,
    },
    h4: {
      fontSize: "18px",
      color: theme.colors.primary,
      fontWeight: 600,
      lineHeight: "24px"
    },
    h5: {
      fontSize: "16px",
      color: theme.colors.secondary,
      fontWeight: 600,
      lineHeight: "22px"
    },
    h6: {
      fontSize: "15px",
      color: theme.colors.primary,
      fontWeight: 600,
    },
    h3_heading: {
      fontSize: "20px",
      color: theme.colors.primary,
      fontWeight: 500,
      lineHeight: "26px"
    },
    heading: {
      fontSize: "14px",
      color: theme.colors.primary,
      fontWeight: 600,
      lineHeight: "20px"
    },
    subHeading: {
      fontSize: "14px",
      color: theme.colors.secondary,
      fontWeight: 400,
      lineHeight: "20px"
    },
    heading_500: {
      fontSize: "14px",
      color: theme.colors.secondary,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "13px",
      fontWeight: 600,
      color: theme.colors.primary,
    },
    subtitle1_500: {
      fontSize: "13px",
      fontWeight: 500,
      color: theme.colors.secondary,
      lineHeight: "24px"
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 400,
      color: theme.colors.secondary,
    },
    subtitle2_500: {
      fontSize: "12px",
      fontWeight: 500,
      color: theme.colors.secondary,
      lineHeight: "18px"
    },
    caption: {
      fontSize: "13px",
      color: theme.colors.secondary,
      fontWeight: 500,
    },
    caption_500: {
      fontSize: "13px",
      color: theme.colors.secondary,
      fontWeight: 500,
    },
    caption2: {
      fontSize: "12px",
      color: theme.colors.primary,
      fontWeight: 400,
      lineHeight: "18px",
    },

    body1: {
      fontSize: "11.5px",
      fontWeight: 500,
      lineHeight: "1.334em",
      color: theme.colors.primary,
    },
    body2: {
      fontSize: "11px",
      fontWeight: 400,
      color: theme.colors.secondary,
    },
    body3: {
      fontSize: "11.5px",
      fontWeight: 500,
      color: theme.colors.secondary,
    },
    heading2: {
      fontSize: "24px",
      fontWeight: 600,
      color: theme.colors.darkText,
      lineHeight: "32px"
    },
    subHeaddingMedium: {
      fontSize: "16px",
      fontWeight: 500,
      color: theme.colors.darkText,
      lineHeight: "22px"
    },
    subHeaddingRegular: {
      fontSize: "16px",
      fontWeight: 400,
      color: theme.colors.darkText,
      lineHeight: "22px"
    },
  };
}
