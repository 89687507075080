import React from "react";

export default function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.77794 16.6668H4.81498C4.42206 16.6668 4.04524 16.5107 3.76741 16.2329C3.48958 15.9551 3.3335 15.5783 3.3335 15.1853V4.81498C3.3335 4.42206 3.48958 4.04524 3.76741 3.76741C4.04524 3.48958 4.42206 3.3335 4.81498 3.3335H7.77794"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9629 13.7038L16.6666 10.0001L12.9629 6.29639"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 10H7.77783"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
