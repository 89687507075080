import React from "react";

export default function LinkedInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M19.25 3.96367V18.0373C19.25 18.7064 18.7064 19.25 18.0373 19.25H3.96367C3.29358 19.25 2.75 18.7064 2.75 18.0373V3.96367C2.75 3.29358 3.29358 2.75 3.96367 2.75H18.0373C18.7064 2.75 19.25 3.29358 19.25 3.96367ZM7.60283 9.05942H5.17642V16.8236H7.60283V9.05942ZM7.821 6.38917C7.82375 6.01883 7.67892 5.66225 7.41858 5.39825C7.15825 5.13425 6.8035 4.98483 6.43317 4.98208H6.38917C5.61275 4.98208 4.98208 5.61275 4.98208 6.38917C4.98208 7.1665 5.61275 7.79625 6.38917 7.79625C7.161 7.8155 7.80267 7.205 7.821 6.43317V6.38917ZM16.8236 12.1064C16.8236 9.77167 15.3386 8.86417 13.8637 8.86417C12.8801 8.81558 11.9451 9.29225 11.4079 10.1163H11.3401V9.0585H9.05942V16.8227H11.4858V12.6931C11.4125 11.8131 12.0615 11.0385 12.9415 10.956H13.0332C13.805 10.956 14.3779 11.4409 14.3779 12.6647V16.8236H16.8043L16.8236 12.1064Z"
        fill="white"
      />
    </svg>
  );
}
