import React from "react";

export default function MessageIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.31111 18.4847L7.66578 17.4204L7.67526 17.4133C7.95733 17.1905 8.10074 17.0791 8.25956 16.9997C8.40178 16.9286 8.55348 16.8753 8.71111 16.8444C8.8877 16.8089 9.07259 16.8089 9.44119 16.8089H17.1579C18.1523 16.8089 18.6501 16.8089 19.0293 16.6181C19.3636 16.451 19.6361 16.1831 19.8068 15.8548C20 15.4815 20 14.9932 20 14.0178V7.37482C20 6.39822 20 5.90874 19.8068 5.53541C19.635 5.20711 19.3636 4.94045 19.0281 4.77215C18.6489 4.58252 18.1511 4.58252 17.1556 4.58252H6.84444C5.84889 4.58252 5.35111 4.58252 4.97067 4.77215C4.63644 4.94045 4.36385 5.20711 4.19319 5.53541C4 5.90993 4 6.39822 4 7.37719V17.3944C4 18.3259 4 18.7905 4.19319 19.0287C4.36267 19.2373 4.61867 19.3582 4.89007 19.3582C5.20059 19.3582 5.57037 19.0667 6.31111 18.4847Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
