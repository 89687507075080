import React from "react";

export default function ThumbIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M13.7769 9.39329V13.2614C13.7769 14.7923 12.5314 16.0379 11.0004 16.0379"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.38629 7.80188C8.7715 6.73056 9.7978 5.96238 11.0001 5.96238C11.6173 5.96238 12.1882 6.16484 12.6497 6.50679"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.22379 13.2613V10.7814"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0004 11.7998V10.2005"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0152 9.97878V8.76508C17.0152 5.44304 14.3222 2.74999 11.0002 2.74999C7.67815 2.74999 4.98514 5.44304 4.98514 8.76508V13.2349C4.98514 16.5569 7.67819 19.25 11.0002 19.25C14.0911 19.25 16.6375 16.9186 16.9768 13.9183"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
