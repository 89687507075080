import React from "react";

export default function PaypalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="56"
      viewBox="0 0 49 56"
      fill="none"
    >
      <path
        d="M43.513 14.0746C43.513 14.3741 43.513 14.6735 43.2135 14.973C40.8178 26.9517 33.0317 30.8448 22.8498 30.8448H17.7588C16.5609 30.8448 15.3631 31.7432 15.3631 32.941L12.6679 49.7112L12.0689 54.5027C12.0689 55.4011 12.6679 56 13.2668 56H22.5503C23.7482 56 24.6466 55.1016 24.6466 54.2032V53.6043L26.4434 42.8235V42.2245C26.7429 41.0267 27.6413 40.4277 28.5397 40.4277H30.037C39.021 40.4277 45.9088 36.8341 47.7056 26.3528C48.604 21.8607 48.0051 18.2671 45.9088 15.8714C45.3098 15.2725 44.4114 14.6735 43.513 14.0746Z"
        fill="#2199D6"
      />
      <path
        d="M41.1173 13.1767C40.8179 13.1767 40.5184 12.8772 39.9195 12.8772C39.62 12.8772 39.0211 12.5777 38.7216 12.5777C37.2243 12.2783 35.7269 12.2783 34.2296 12.2783H20.4541C20.1546 12.2783 19.8552 12.2783 19.5557 12.5777C18.9568 12.8772 18.3578 13.4761 18.3578 14.0751L15.3632 32.6421V33.241C15.6626 32.0431 16.561 31.1447 17.7589 31.1447H22.8498C33.0317 31.1447 40.8179 26.9522 43.2136 15.2729C43.2136 14.9735 43.2136 14.674 43.5131 14.3745C42.9142 14.0751 42.3152 13.7756 41.7163 13.4761C41.4168 13.1767 41.1173 13.1767 41.1173 13.1767Z"
        fill="#252C5E"
      />
      <path
        d="M18.3581 14.075C18.3581 13.476 18.957 12.8771 19.556 12.5776C19.8554 12.5776 20.1549 12.2782 20.4544 12.2782H34.2299C35.7272 12.2782 37.524 12.2782 38.7219 12.5776C39.0214 12.5776 39.6203 12.5776 39.9198 12.8771C40.2193 12.8771 40.5187 13.1766 41.1176 13.1766C41.4171 13.1766 41.4171 13.1766 41.7166 13.476C42.3155 13.7755 42.9145 14.075 43.5134 14.3744C44.1123 9.88243 43.5134 6.88775 41.1176 4.19254C38.4224 1.19786 33.631 0 27.6416 0H9.97303C8.77516 0 7.57728 0.898394 7.57728 2.09626L0.390076 48.2143C0.390076 49.1127 0.989011 50.0111 1.88741 50.0111H12.6682L15.3635 32.642L18.3581 14.075Z"
        fill="#2C3E8B"
      />
    </svg>
  );
}
