import { Box } from "@mui/material";
import Header from "./Header";
import SideBar from "./Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import ChatSupport from "./ChatSupport";
import HelpCenterSidebar from "../HelpCenter/HelpCenterSidebar";

const pathsToCheck = ["/payment-info", "/help-center"];

export default function Layout() {
  const { pathname } = useLocation();
  const doesNotStartWithAnyPath = !pathsToCheck.some(path => pathname.startsWith(path));
  const doesNotStartWithHelpCenter = pathname.startsWith("/help-center");

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "calc(100vh - 32px)",
        backgroundColor: (theme) => theme.palette.color.bg,
        padding: "12px"
      }}
    >
      <SideBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {doesNotStartWithAnyPath && <Header />}
        <Box display={doesNotStartWithHelpCenter ? "flex" : ""}>
          {doesNotStartWithHelpCenter && <HelpCenterSidebar />}
          <Outlet />
        </Box>
        <ChatSupport />
        <Footer />
      </Box>
    </Box>
  );
}
