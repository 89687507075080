import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomCard from "../ui/CustomCard";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PencilIcon from "../Icons/PencilIcon";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, updateProfile } from "../../slices/settings/profile";
import { getProfileFromName } from "../../utils/helpers";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function Profile() {
  const [imagePreview, setImagePreview] = useState("");
  const [file, setFile] = useState();
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    const {
      first_name,
      last_name,
      email,
      phone,
      country_id,
      city,
      street,
      zip_code,
      company_name,
      vat_number,
      state,
    } = data;

    const formdata = new FormData();
    formdata.append("first_name", first_name);
    formdata.append("last_name", last_name);
    formdata.append("email", email);
    formdata.append("phone", phone);
    formdata.append("country_id", parseFloat(country_id.id));
    formdata.append("city", city.id);
    formdata.append("state", state.id);
    formdata.append("vat_number", vat_number || "");
    formdata.append("company_name", company_name || "");
    formdata.append("zip_code", zip_code);
    formdata.append("address", street);
    formdata.append("avatar", file);
    dispatch(updateProfile(formdata));
  };

  const fieldValues = watch();
  useEffect(() => {
    const setFormData = async (data) => {
      const {
        first_name,
        last_name,
        email,
        phone,
        country_id,
        city,
        street,
        zip_code,
        company_name,
        vat_number,
        state,
        avatar,
      } = data.profile;

      const selectedCountry = await fetchCountries(country_id);
      const selectedState = await fetchState(country_id, state);
      const selectedCity = await fetchCity(country_id, state, city);

      setValue("first_name", first_name);
      setValue("last_name", last_name);
      setValue("email", email);
      setValue("phone", phone);
      setValue("country_id", selectedCountry);
      setValue("city", selectedCity);
      setValue("street", street);
      setValue("zip_code", zip_code);
      setValue("company_name", company_name || "");
      setValue("vat_number", vat_number || "");
      setValue("state", selectedState);
      setImagePreview(avatar);
    };

    const fetchCountries = async (selectedCountryId) => {
      const result = await GetCountries();
      const selectedCountry = result.find(
        (country) => country.id === selectedCountryId
      );
      return selectedCountry;
    };

    if (profileData.profile) {
      setFormData(profileData);
    }
  }, [setValue, profileData]);

  const fetchState = async (countryId, stateId) => {
    const result = await GetState(countryId);
    const selectedState = result.find(
      (state) => state.id === parseFloat(stateId)
    );
    return selectedState;
  };

  const fetchCity = async (countryId, stateId, cityId) => {
    const result = await GetCity(countryId, parseFloat(stateId));
    const selectedState = result.find((city) => city.id === parseFloat(cityId));
    return selectedState;
  };

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const validateImage = (event) => {
    const fileInput = event.target;
    const filePath = fileInput.value;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    setFile(fileInput.files[0]);
    if (!allowedExtensions.exec(filePath)) {
      alert(
        "Please upload a file having extensions .jpeg/.jpg/.png/.gif only."
      );
      fileInput.value = "";
      setImagePreview("");
      return false;
    } else {
      // Image preview
      if (fileInput.files && fileInput.files[0]) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(fileInput.files[0]);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <CustomCard>
        <Grid container columnSpacing={5} spacing={2}>
          <Grid item md={5}>
            <Typography
              variant="heading_500"
              color={(theme) => theme.palette.color.darkText}
            >
              First name
            </Typography>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: "First name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  variant="outlined"
                  className="custom_input"
                  fullWidth
                  error={!!errors.first_name}
                  helperText={
                    errors.first_name ? errors.first_name.message : ""
                  }
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={5}>
            <Typography
              variant="heading_500"
              color={(theme) => theme.palette.color.darkText}
            >
              Last name
            </Typography>
            <Controller
              name="last_name"
              control={control}
              defaultValue=""
              rules={{ required: "Last name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="custom_input"
                  fullWidth
                  error={!!errors.last_name}
                  helperText={errors.last_name ? errors.last_name.message : ""}
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={2}>
            <Box position={"relative"}>
              <Box
                borderRadius={"50%"}
                overflow={"hidden"}
                width={"96px"}
                height={"96px"}
                backgroundColor={"#fff"}
                sx={{
                  overflow: "hidden",
                }}
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div className="profile-image me-3">
                    <div className="initials">
                      {getProfileFromName(profileData.profile?.first_name)}
                    </div>
                  </div>
                )}
              </Box>
              <IconButton
                sx={{
                  borderRadius: "50%",
                  background: (theme) => theme.palette.color.lightgray,
                  position: "absolute",
                  bottom: 0,
                  ":hover": {
                    background: (theme) => theme.palette.color.lightgray,
                  },
                }}
                onClick={handleButtonClick}
              >
                <PencilIcon />
              </IconButton>
              <input
                type="file"
                id="imageUpload"
                name="imageUpload"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={validateImage}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomCard>
      <Box mt={3}>
        <CustomCard>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Email
              </Typography>
              <Controller
                name="email"
                control={control}
                rules={{ required: "Email is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    sx={{
                      mt: "8px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                "& .custom_phone_input.PhoneInput": {
                  marginTop: "8px",
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.color.strockborder,
                  borderRadius: "16px",
                  padding: "14px",
                  background: (theme) => theme.palette.color.blackShade,
                  width: "100%",
                },
                "& .custom_phone_input.PhoneInput input": {
                  border: 0,
                  background: "transparent",
                  fontSize: "14px",
                  color: (theme) => theme.palette.color.secondary,
                },
                "& .custom_phone_input.PhoneInput input:focus": {
                  outline: "none",
                },
              }}
            >
              <Box>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Phone
                </Typography>
              </Box>
              <Controller
                name="phone"
                control={control}
                rules={{ required: "Phone is required" }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    defaultCountry="US"
                    international
                    className="custom_phone_input"
                  />
                )}
              />
              {errors.phone && (
                <FormHelperText>{errors.phone.message}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Company name (optional)
              </Typography>
              <Controller
                name="company_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    sx={{
                      mt: "8px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                EU VAT Number (optional)
              </Typography>
              <Controller
                name="vat_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    sx={{
                      mt: "8px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Country
              </Typography>
              <Controller
                name="country_id"
                control={control}
                rules={{ required: "Country is required" }}
                render={({ field }) => (
                  <CountrySelect
                    {...field}
                    defaultValue={field.value || {}}
                    showFlag={false}
                    placeHolder="Select Country"
                  />
                )}
              />
              {errors.country_id && (
                <FormHelperText>{errors.country_id.message}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                State
              </Typography>
              <Controller
                name="state"
                control={control}
                rules={{ required: "State is required" }}
                render={({ field }) => (
                  <StateSelect
                    countryid={
                      fieldValues &&
                      fieldValues.country_id &&
                      fieldValues.country_id.id
                    }
                    {...field}
                    defaultValue={field.value || {}}
                    placeHolder="Select State"
                  />
                )}
              />
              {errors.state && (
                <FormHelperText>{errors.state.message}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                City
              </Typography>
              <Controller
                name="city"
                control={control}
                rules={{ required: "City is required" }}
                render={({ field }) => (
                  <CitySelect
                    countryid={
                      fieldValues &&
                      fieldValues.country_id &&
                      fieldValues.country_id.id
                    }
                    stateid={
                      fieldValues && fieldValues.state && fieldValues.state.id
                    }
                    {...field}
                    defaultValue={field.value || {}}
                    placeHolder="Select City"
                  />
                )}
              />
              {errors.city && (
                <FormHelperText>{errors.city.message}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Address
              </Typography>
              <Controller
                name="street"
                control={control}
                rules={{ required: "Address is required" }}
                render={({ field }) => (
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    {...field}
                    sx={{
                      mt: "8px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                  />
                )}
              />
              {errors.street && (
                <FormHelperText>{errors.street.message}</FormHelperText>
              )}
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Postal Code
              </Typography>
              <Controller
                name="zip_code"
                control={control}
                rules={{ required: "Postal Code is required" }}
                render={({ field }) => (
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    {...field}
                    sx={{
                      mt: "8px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                  />
                )}
              />
              {errors.zip_code && (
                <FormHelperText>{errors.zip_code.message}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </CustomCard>
      </Box>
      <Box mt={3} textAlign={"right"}>
        <Button className="custom_color_btn customButton" type="submit">
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}
