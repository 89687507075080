import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCard from "../ui/CustomCard";
import DanderIcon from "../Icons/DanderIcon";

export default function Verification() {
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifyStart, setIsVerifyStart] = useState(false);

  useEffect(() => {
    setIsVerified(false);
  }, [])

  return (
    <>
      {!isVerified && !isVerifyStart && (
        <Box maxWidth={"700px"} margin={"200px auto"}>
          <CustomCard>
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <DanderIcon />
              <Stack direction={"column"} gap={2}>
                <Typography variant="subHeaddingMedium">
                  Verify your Account
                </Typography>
                <Typography variant="subtitle2_500">
                  you need to verify your profile to Used WE-FUNDS
                </Typography>
              </Stack>
            </Stack>
            <Box textAlign={"right"} mt={4}>
              <Button
                className="customButton custom_color_btn purple_btn"
                onClick={() => setIsVerifyStart(true)}
              >
                Verify now
              </Button>
            </Box>
          </CustomCard>
        </Box>
      )}
      {isVerifyStart && (
        <Box maxWidth={"700px"} margin={"0 auto"}>
          <CustomCard customPadding="0">
            <Box
              py={2.3}
              px={2}
              sx={{
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
              }}
            >
              <Typography
                variant="h4"
                fontWeight={"500"}
                color={(theme) => theme.palette.color.darkText}
              >
                Let's Get you Verified
              </Typography>
            </Box>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              sx={{
                height: 400,
                py: 3,
                px: 2,
              }}
            >
              <Box>
                <Typography variant="subHeading">
                  Before you start, prepare your identity document and make sure
                  it is valid
                  <br />
                  we also require you to agree to the processing of your
                  personal data:
                </Typography>
                <Box mt={3} mb={2}>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    I'm a resident and/or citizen (for TAX purpose) of:
                  </Typography>
                </Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: (theme) => theme.palette.color.secondary,
                    },
                  }}
                >
                  <FormControlLabel
                    value="non"
                    control={<Radio />}
                    label="Non-US resident"
                  />
                  <FormControlLabel
                    value="us"
                    control={<Radio />}
                    label="US resident"
                  />
                </RadioGroup>
              </Box>
              <Box  mt={5}>
                <Typography variant="subHeading" sx={{
                  "& span": {
                    color: (theme) => theme.palette.color.purple
                  }
                }}>
                  By clicking the "Continue" button I agree that I have read the
                  <span>&nbsp;Privacy Notice</span> and give my consent to the processing of my
                  personal data, including biometrics, as described in this
                  <span>&nbsp;Privacy User Acknowledgement and Consent.</span>
                </Typography>
                <Box textAlign={"right"} mt={3}>
                  <Button
                    className="customButton custom_color_btn"
                    onClick={() => setIsVerifyStart(true)}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Stack>
          </CustomCard>
        </Box>
      )}
    </>
  );
}
