import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Input,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import AuthLayout from "../components/Login/AuthLayout";
import EmailIcon from "../components/Icons/EmailIcon";
import LeftArrow from "../components/Icons/LeftArrow";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPasswordRecoveryLoading,
  sendPasswordRecoveryEmail,
} from "../slices/auth/forgotPasswordSlice";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

export default function ForgotPass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectPasswordRecoveryLoading);

  const onSubmit = (event) => {
    dispatch(sendPasswordRecoveryEmail(event.email))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") { 
          navigate(`/check-email?email=${event.email}`);
        }
      })
      .catch((error) => {
        console.error('Dispatch error:', error);
      });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: (theme) => theme.palette.color.bg,
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Forgot Password
          </Typography>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
              paddingTop: "24px",
              paddingBottom: "48px",
              fontSize: "16px",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.color.secondary,
              }}
            >
              No worries, we'll send you reset instructions.
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1, maxWidth: "590px", width: "100%" }}
            >
              <Box paddingBottom={"24px"}>
              <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Email
                </Typography>
                <Box position={"relative"}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        startAdornment={
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        }
                        className="custom_input"
                        placeholder="Email"
                        autoComplete="off"
                        sx={{
                          marginTop: "5px",
                          width: "100%",
                          background: (theme) => theme.palette.color.lightShade + "!important"
                        }}
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText>{errors.email.message}</FormHelperText>
                  )}
                </Box>
              </Box>
              <Button
                className="custom_color_btn customButton"
                fullWidth
                sx={{
                  padding: "14px !important",
                  fontSize: "16px !important",
                }}
                type="submit"
                disabled={loading === "loading"}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={21} />
                ) : (
                  "Reset Password"
                )}
              </Button>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  paddingTop: "48px",
                  justifyContent: "center",
                  "a": {
                    textDecoration: "none"
                  }
                }}
              >
                <Link to="/login" variant="body2">
                  <Typography
                    variant="heading_500"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <LeftArrow /> Back to login
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
