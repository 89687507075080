import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "../Icons/AddIcon";

export default function AddEvaluationCard() {
  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{
        a: {
          textDecoration: "none",
        },
      }}
    >
      <Link to="/new-evaluation">
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"230px"}
          sx={{
            border: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
            borderRadius: "12px",
            "svg path": {
              stroke: (theme) => theme.palette.color.skyColor,
            },
          }}
        >
          <Stack direction={"column"} gap={2} alignItems={"center"}>
            <Typography
              variant="heading_500"
              color={(theme) => theme.palette.color.skyColor}
            >
              New Evaluation
            </Typography>
            <AddIcon />
          </Stack>
        </Box>
      </Link>
    </Grid>
  );
}
