import {
  // Avatar,
  // AvatarGroup,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCard from "../components/ui/CustomCard";
import BannerImage from "../assets/banner-card-image.png";
import CrossIcon from "../components/Icons/CrossIcon";
import AndroidIcon from "../components/Icons/AndroidIcon";
import IosIcon from "../components/Icons/IosIcon";
// import FundImage from "../assets/funded.png";
// import LeaderBoardImage from "../assets/leaderboard.png";
// import AvatarImg from "../assets/avatar.png";
// import Avatar1 from "../assets/avatar2.png";
// import Avatar2 from "../assets/avatar3.png";
import RightArrowIcon from "../components/Icons/RightArrowIcon";
// import ChartComponent from "../components/Home/ChartComponent";
import ActiveAccounts from "../components/Home/ActiveAccounts";
// import UseSelect from "../components/ui/BaseSelect";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccounts } from "../slices/accounts/myaccounts";
import headphone from "../assets/headphones.svg";
import earthimg from "../assets/earthimg.png";
import Graphblocks from "../assets/Graphblocks.png";

export default function Dashboard() {
  const [showBanner, setShowBanner] = useState(true);

  const dispatch = useDispatch();
  const { accounts: accountData } = useSelector((state) => state.accounts);

  const activeAccounts = accountData?.filter(
    (account) => account.status === "ACTIVE"
  );

  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        margin: "30px 16px",
      }}
      position={"relative"}
    >
      {showBanner && (
        <CustomCard customPadding={"16px 16px 0"}>
          <Box
            sx={{
              textAlign: "right",
            }}
            mb={5}
          >
            <IconButton onClick={() => setShowBanner(false)}>
              <CrossIcon />
            </IconButton>
          </Box>
          <Box>
            <img
              src={BannerImage}
              alt="banner-image"
              style={{ marginBottom: "-4px" }}
            />
            <Box
              position={"absolute"}
              sx={{
                top: "-35px",
                left: "140px",
                background: (theme) => theme.palette.color.blackShade,
                maxWidth: "480px",
                width: "100%",
                borderRadius: "16px 16px 16px 0",
                padding: "20px 24px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
              }}
            >
              <Box mb={1}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Wefund on <span style={{ color: "#4ED5FF" }}>mobile</span>
                </Typography>
              </Box>
              <Typography variant="subtitle1_500">
                From now you can manage your account - journal your trades and
                access the community using the mobile app.
              </Typography>
              <Box
                mt={2}
                sx={{
                  "& svg path": {
                    stroke: (theme) =>
                      theme.palette.mode === "dark" ? "transparent" : "#131517",
                  },
                }}
              >
                <AndroidIcon />
                <IosIcon />
              </Box>
            </Box>
          </Box>
        </CustomCard>
      )}
      <Box my={4}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} lg={5}>
            <CustomCard>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Overview
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <UseSelect
                    options={[
                      { value: "today", label: "Today" },
                      { value: "yesterday", label: "Yesterday" },
                      { value: "thisweek", label: "This Week" },
                      { value: "lastweek", label: "Last Week" },
                      { value: "thismonth", label: "This Month" },
                      { value: "lastmonth", label: "Last Month" },
                    ]}
                    defaultValue="thisweek"
                  />
                  <UseSelect
                    options={[
                      { value: "all", label: "All Account" },
                      { value: "active", label: "Active Account" },
                      { value: "passed", label: "Passed Account" },
                      { value: "failed", label: "Failed Account" },
                    ]}
                    defaultValue="all"
                  />
                </Stack>
              </Stack>
              <ChartComponent />
            </CustomCard>
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.primary}
                sx={{
                  span: {
                    color: (theme) => theme.palette.color.skyColor,
                  },
                }}
              >
                Active account <span>({activeAccounts?.length})</span>
              </Typography>
              <Stack>
                <Button
                  className="customButton see_all_btn"
                  endIcon={<RightArrowIcon />}
                  onClick={() => navigate("/accounts")}
                >
                  See all
                </Button>
              </Stack>
            </Stack>
            <ActiveAccounts accountData={activeAccounts} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              position: "relative",
            }}
          >
            <CustomCard>
              <Box
                minHeight={"215px"}
                flexDirection={"column"}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                sx={{
                  ".image_div": {
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                  },
                }}
              >
                {/* <Typography
                  variant="h5"
                  sx={{
                    span: {
                      color: (theme) => theme.palette.color.darkText,
                    },
                  }}
                >
                  Get funded up to <span>$400.000</span> and scale to{" "}
                  <span>$2,000.000</span>
                </Typography> */}
                <Button
                  className="custom_color_btn customButton"
                >
                  Customer support
                </Button>
                <img style={{ right: "15px", bottom: "15px" }} src={headphone} alt="" className="image_div" />
              </Box>
            </CustomCard>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              position: "relative",
            }}
          >
            <CustomCard>
              <Box
                minHeight={"215px"}
                flexDirection={"column"}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                sx={{
                  ".image_div": {
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                  },
                }}
              >
                {/* <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Climb the leaderboard ladder
                </Typography> */}
                <Button
                  className="customButton"
                >
                  Join our community
                </Button>
                <img src={earthimg} alt="" className="image_div" />
              </Box>
            </CustomCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomCard>
              <Box
                minHeight={"215px"}
                flexDirection={"column"}
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                sx={{
                  ".image_div": {
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                  },
                }}
              >
                {/* <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                 A-book scaling plan
                </Typography> */}
                {/* <Box width={"100%"}>
                  <AvatarGroup max={6}>
                    <Avatar alt="Remy Sharp" src={AvatarImg} />
                    <Avatar alt="Travis Howard" src={Avatar1} />
                    <Avatar alt="Cindy Baker" src={Avatar2} />
                    <Avatar alt="Agnes Walker" src={AvatarImg} />
                    <Avatar alt="Trevor Henderson" src={Avatar1} />
                  </AvatarGroup>
                </Box> */}
                <Button className="customButton">A-book scaling plan</Button>
                <div class="overlay"></div>
                <img src={Graphblocks} alt="" className="image_div" />
              </Box>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
