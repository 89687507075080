import React from "react";

function FolderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.25 13.25V5.75C15.25 5.21957 15.0393 4.71086 14.6642 4.33579C14.2891 3.96071 13.7804 3.75 13.25 3.75H0.75V13.25C0.75 13.7804 0.960714 14.2891 1.33579 14.6642C1.71086 15.0393 2.21957 15.25 2.75 15.25H13.25C13.7804 15.25 14.2891 15.0393 14.6642 14.6642C15.0393 14.2891 15.25 13.7804 15.25 13.25Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 3.5L8.569 1.792C8.39689 1.4766 8.14299 1.21338 7.83399 1.03002C7.525 0.846663 7.17231 0.749934 6.813 0.75H2.75C2.21957 0.75 1.71086 0.960714 1.33579 1.33579C0.960714 1.71086 0.75 2.21957 0.75 2.75V7"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FolderIcon;
