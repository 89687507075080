import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import LoginBanner from "../../assets/loginbg.png";
import Logo from "../../assets/logo.png";
import darkLogo from "../../assets/darkLogo.svg";
import ProfileImg from "../../assets/profileimg.png";
import { useSelector } from "react-redux";
function AuthLayout() {
  const themeMode = useSelector((state) => state.theme.mode);
  return (
    <Box className="login-img-section" style={{ minHeight: "100vh" }}>
      <Box
        className="login-logo"
        sx={{
          position: "absolute",
          width: "108px",
          top: "32px",
          left: "24px ",
          background: `linear-gradient(46.08deg, ${(theme) =>
            theme.palette.color.lightShade} 5.29%,${(theme) =>
            theme.palette.color.blackShade} 75%)`,
        }}
      >
        <img
          src={themeMode === "light" ? darkLogo : Logo}
          style={{ width: "100%" }}
          alt="Logo"
        />
      </Box>
      <Box className="login-text">
        <Box>
          <Typography
            variant="paragraph"
            className="founder-text"
            sx={{ color: (theme) => theme.palette.color.darkText }}
          >
            Wefund has been a game-changer for my daily trading routine and
            performance.
          </Typography>
        </Box>
        <Box sx={{ paddingTop: "24px" }}>
          <Typography
            variant="paragraph"
            className="founder-text"
            sx={{ color: (theme) => theme.palette.color.darkText }}
          >
            <span style={{ color: "#8A96A6" }}>Instant payouts</span> and the
            dashboard is incredibly user-friendly. The{" "}
            <span style={{ color: "#754FFF" }}>AI-powered</span> tools are
            insane and saved me countless hours of work.
          </Typography>
        </Box>
        <Stack
          direction="row"
          sx={{ paddingTop: "32px" }}
          className="founder-profile"
          spacing={2}
        >
          <Box style={{ width: "48px", height: "48px" }}>
            <img
              src={ProfileImg}
              style={{ width: "100%", borderRadius: "50%" }}
              alt="Founder"
            />
          </Box>
          <Stack direction={"column"} spacing={1}>
            <Typography
              variant="h3"
              style={{ color: (theme) => theme.palette.color.darkText, fontSize: "14px" }}
            >
              Sam van den Berg
            </Typography>
            <Typography
              variant="paragraph"
              style={{ color: "#8A96A6", fontSize: "12px" }}
            >
              Funded Trader
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <img className="login-img" src={LoginBanner} alt="Banner" />
    </Box>
  );
}

export default AuthLayout;
