import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AuthLayout from "../components/Login/AuthLayout";
import FormInput from "../components/ui/FormInput";
import PassHide from "../components/ui/PassHide";
import PassShow from "../components/ui/PassShow";
export default function SetNewPass() {
  const [showPassword, setShowPassword] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const handlePassVisiblety = () => {
    setShowPassword((prev) => (prev === "password" ? "text" : "password"));
  };
  const handleConfirmPassVisiblety = () => {
    setShowConfirmPassword((prev) =>
      prev === "password" ? "text" : "password"
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
  };
  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: (theme) => theme.palette.color.bg,
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Set new password
          </Typography>
          <Box
            sx={{
              pt: "24px",
              pb: "48px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.color.secondary,
              }}
            >
              Your new password must be different to previously used passwords.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, maxWidth: "590px", width: "100%" }}
            >
              <Box paddingBottom={"16px"} sx={{ position: "relative" }}>
                <FormInput
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword}
                  id={"password"}
                  autoComplete="new-password"
                  sx={{
                    background: (theme) => theme.palette.color.chatActive,
                    borderRadius: "16px",
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.strockborder,
                    fontSize: "14px",
                    color: "#FFFFFF",
                  }}
                />
                <Box
                  style={{
                    position: "absolute",
                    bottom: "26px",
                    right: "16px",
                    cursor: "pointer",
                  }}
                  onClick={handlePassVisiblety}
                >
                  {showPassword === "password" ? <PassHide /> : <PassShow />}
                </Box>
              </Box>
              <Box sx={{ position: "relative" }}>
                <FormInput
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword}
                  id="confirmPassword"
                  autoComplete="new-password"
                  sx={{
                    background: (theme) => theme.palette.color.chatActive,
                    borderRadius: "16px",
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.strockborder,
                    fontSize: "14px",
                    color: "#FFFFFF",
                  }}
                />
                <Box
                  style={{
                    position: "absolute",
                    bottom: "26px",
                    right: "16px",
                    cursor: "pointer",
                  }}
                  onClick={handleConfirmPassVisiblety}
                >
                  {showConfirmPassword === "password" ? (
                    <PassHide />
                  ) : (
                    <PassShow />
                  )}
                </Box>
              </Box>
              <Button
                type="submit"
                sx={{
                  padding: "14px 0 !important",
                  backgroundColor: `${(theme) => theme.palette.color.lightSky} !important`,
                  bgcolor: (theme) => theme.palette.color.lightSky,
                  borderRadius: "32px",
                  color: (theme) => theme.palette.color.blackWhiteText,
                  fontSize: "14px",
                  textTransform: "Capitalize",
                  lineHeight: "22px",
                  mt: "24px",
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.color.lightSky,
                  },
                }}
                fullWidth
                className="custom_color_btn "
              >
                Reset password
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
