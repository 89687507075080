import React from "react";

export default function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M15.3541 12.1458L16.5 11C18.0188 9.48116 18.0188 7.01876 16.5 5.49999C14.9811 3.98121 12.5188 3.98121 11 5.49999L9.85413 6.64582"
        stroke="#4ED5FF"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.64588 9.85416L5.50005 11C3.98127 12.5188 3.98127 14.9812 5.50005 16.5C7.01883 18.0188 9.48122 18.0188 11 16.5L12.1459 15.3542"
        stroke="#4ED5FF"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0625 8.9375L8.9375 13.0625"
        stroke="#4ED5FF"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
