import React from "react";

export default function ColorCopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.41671 12.7083C4.61129 12.7083 3.95837 12.0554 3.95837 11.25V5.62501C3.95837 4.70454 4.70457 3.95834 5.62504 3.95834H11.25C12.0555 3.95834 12.7084 4.61126 12.7084 5.41668"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 7.29166H8.95829C8.03782 7.29166 7.29163 8.03785 7.29163 8.95832V14.375C7.29163 15.2955 8.03782 16.0417 8.95829 16.0417H14.375C15.2954 16.0417 16.0416 15.2955 16.0416 14.375V8.95832C16.0416 8.03785 15.2954 7.29166 14.375 7.29166Z"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
