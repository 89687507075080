import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import CandleStickChart from "../components/HelpCenter/CandleStickChart";
import Avtar from "../assets/Avtar.png";
import AnswerHelped from "../components/HelpCenter/AnswerHelped";
export default function HelpCenterGraphPage() {
  return (
    <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
      <div style={{ width: "300px" }}>SideBar section</div>
      <Box sx={{ width: "100%", pt: "25px " }}>
        <Container maxWidth="md">
          <CandleStickChart />
          <Stack direction="row" alignItems={"center"} gap={"8px"}>
            <Box sx={{ width: "48px" }}>
              <img style={{ width: "100%" }} src={Avtar} alt="" />
            </Box>
            <Typography
              sx={{
                color: (theme) => theme.palette.color.secondary,
                fontSize: "12px",
              }}
              variant="paragraph"
            >
              Shared by Vuetra • 1w ago
            </Typography>
          </Stack>
          <Box paddingTop={"16px"}>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.color.primary,
                fontSize: "16px",
              }}
            >
              Lorem ipsum dolor sit amet
            </Typography>
            <Box sx={{ paddingTop: "8px" }}>
              <Typography
                variant="paragraph"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "14px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </Typography>
            </Box>
            <Button
              sx={{
                borderRadius: "24px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
                color: (theme) => theme.palette.color.secondary,
                textTransform: "capitalize",
                marginTop: "8px",
              }}
              variant="outlined"
            >
              learn more
            </Button>
            <AnswerHelped />
          </Box>
        </Container>
      </Box>
    </Stack>
  );
}
