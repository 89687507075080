import React from "react";

function SIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H12C15.0376 0.5 17.5 2.96243 17.5 6V12C17.5 15.0376 15.0376 17.5 12 17.5H6C2.96243 17.5 0.5 15.0376 0.5 12V6Z"
        fill="#26282D"
      />
      <path
        d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H12C15.0376 0.5 17.5 2.96243 17.5 6V12C17.5 15.0376 15.0376 17.5 12 17.5H6C2.96243 17.5 0.5 15.0376 0.5 12V6Z"
        stroke="#212529"
      />
      <path
        d="M10.358 7.54545C10.3153 7.18561 10.1425 6.90625 9.83949 6.70739C9.53646 6.50852 9.16477 6.40909 8.72443 6.40909C8.40246 6.40909 8.12074 6.46117 7.87926 6.56534C7.64015 6.66951 7.45313 6.81274 7.31818 6.99503C7.18561 7.17732 7.11932 7.38447 7.11932 7.61648C7.11932 7.81061 7.16548 7.97751 7.25781 8.11719C7.35251 8.2545 7.47325 8.36932 7.62003 8.46165C7.76681 8.55161 7.92069 8.62618 8.08168 8.68537C8.24266 8.74219 8.39062 8.78835 8.52557 8.82386L9.2642 9.02273C9.4536 9.07244 9.6643 9.1411 9.89631 9.22869C10.1307 9.31629 10.3544 9.43584 10.5675 9.58736C10.7829 9.73651 10.9605 9.92827 11.1001 10.1626C11.2398 10.397 11.3097 10.6847 11.3097 11.0256C11.3097 11.4186 11.2067 11.7737 11.0007 12.0909C10.7971 12.4081 10.4988 12.6603 10.1058 12.8473C9.7152 13.0343 9.24053 13.1278 8.68182 13.1278C8.16098 13.1278 7.70999 13.0438 7.32884 12.8757C6.95005 12.7076 6.65175 12.4732 6.43395 12.1726C6.21851 11.8719 6.09659 11.5227 6.06818 11.125H6.97727C7.00095 11.3996 7.09328 11.6269 7.25426 11.8068C7.41761 11.9844 7.62358 12.117 7.87216 12.2045C8.12311 12.2898 8.39299 12.3324 8.68182 12.3324C9.01799 12.3324 9.31984 12.2779 9.58736 12.169C9.85488 12.0578 10.0668 11.9039 10.223 11.7074C10.3793 11.5085 10.4574 11.2765 10.4574 11.0114C10.4574 10.7699 10.3899 10.5734 10.255 10.4219C10.12 10.2704 9.94247 10.1473 9.7223 10.0526C9.50213 9.95786 9.2642 9.875 9.00852 9.80398L8.11364 9.5483C7.54545 9.38494 7.09564 9.15175 6.7642 8.84872C6.43277 8.54569 6.26705 8.14915 6.26705 7.65909C6.26705 7.25189 6.37713 6.89678 6.5973 6.59375C6.81984 6.28835 7.11813 6.05161 7.49219 5.88352C7.86861 5.71307 8.28883 5.62784 8.75284 5.62784C9.22159 5.62784 9.63826 5.71188 10.0028 5.87997C10.3674 6.04569 10.6563 6.27296 10.8693 6.56179C11.0848 6.85062 11.1984 7.1785 11.2102 7.54545H10.358Z"
        fill="white"
      />
    </svg>
  );
}

export default SIcon;
