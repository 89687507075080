import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import ReferralTable from "./ReferralTable";
import UseSelect from "../ui/BaseSelect";

export default function PartnerDetails() {
  const { slug } = useParams();

  return (
    <Box mx={2} mt={5} mb={3} minHeight={"calc(100vh - 200px)"}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography
                variant="heading"
                color={(theme) => theme.palette.color.darkText}
                textTransform={"capitalize"}
            >
                {slug}
            </Typography>
            <UseSelect
                options={[
                { value: "today", label: "Today" },
                { value: "yesterday", label: "Yesterday" },
                { value: "thisweek", label: "This Week" },
                { value: "lastweek", label: "Last Week" },
                { value: "thismonth", label: "This Month" },
                { value: "lastmonth", label: "Last Month" },
                ]}
                defaultValue="thisweek"
            />
        </Stack>
      <ReferralTable />
    </Box>
  );
}
