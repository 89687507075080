import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import calenderIcon from "../../assets/calenderIcon.svg";
import RightIcon from "../Icons/rightIcon";

function PopoverChat() {
  return (
    <Box>
      <Box
        padding={"14px"}
        sx={{
          background: "linear-gradient(135deg, #55AFFF 0%, #6A52FF 100%)",
        }}
      >
        <Typography variant="h2" color={"#ffffff"}>Chats</Typography>
      </Box>
      <Stack
        direction={"row"}
        sx={{
          padding: "16px 18px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} gap={"16px"}>
          <Box
            backgroundColor={(theme) => theme.palette.color.lightShade}
            padding="16px"
            borderRadius="32px"
          >
            <img src={calenderIcon} alt="" />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title Chat
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the message 1d
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "16px 18px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} gap={"16px"}>
          <Box
            backgroundColor={(theme) => theme.palette.color.lightShade}
            padding="16px"
            borderRadius="32px"
          >
            <img src={calenderIcon} alt="" />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title Chat
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the message 1d
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "16px 18px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} gap={"16px"}>
          <Box
            backgroundColor={(theme) => theme.palette.color.lightShade}
            padding="16px"
            borderRadius="32px"
          >
            <img src={calenderIcon} alt="" />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title Chat
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the message 1d
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{ padding: "16px 18px", }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} gap={"16px"}>
          <Box
            backgroundColor={(theme) => theme.palette.color.lightShade}
            padding="16px"
            borderRadius="32px"
          >
            <img src={calenderIcon} alt="" />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title Chat
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the message 1d
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText,
            },
          }}
        >
          <RightIcon />
        </Box>
      </Stack>
    </Box>
  );
}

export default PopoverChat;
