import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import SearchIcon from "../../Icons/SearchIcon";
import RightArrowIcon from "../../Icons/RightArrowIcon";
import PartnerIcon from "../../Icons/PartnerIcon";
import DocIcon from "../../Icons/DocIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "623px",
  border: (theme) => `1px solid ${theme.palette.color.strockborder}`,
  background: (theme) => theme.palette.color.bgGrey,
  borderRadius: "16px",
  p: "16px",
  maxHeight: "386px",
  overflowY: "auto",
};

export default function SearchSection() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton onClick={handleOpen} className="custom_btn_icon">
        <SearchIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Paper sx={style}>
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                fontSize: "10px",
              }}
            >
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                autoComplete="off"
                placeholder="/Search and run a command"
                sx={{
                  mt: "8px",
                  input: {
                    background: (theme) =>
                      theme.palette.color.blackShade + "! important",
                  },
                }}
              />
            </Box>

            <Stack spacing={1.3} sx={{ my: 2 }}>
              <Typography variant="subtitle1_500">Documentation</Typography>
              <Button
                className="customButton"
                startIcon={<DocIcon />}
                sx={{
                  justifyContent: "flex-start !important",
                }}
              >
                Search the docs...
              </Button>
              <Button
                className="customButton ai-btn"
                startIcon={<PartnerIcon />}
              >
                Ask we-fund AI...
              </Button>
            </Stack>
            <Stack spacing={1} sx={{ my: 2 }}>
              <Typography variant="subtitle1_500">Quick start</Typography>
              <Box sx={{
                mx: '16px !important',
                my: 1
              }}>
              <Stack direction={"row"} gap={1} alignItems={"center"} sx={{
                mb: 1
              }}>
                <RightArrowIcon />
                <Typography
                  variant="heading_500"
                  sx={{
                    span: {
                      color: (theme) => theme.palette.color.darkText,
                    },
                  }}
                >
                  Start with <span>Action name</span>
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <RightArrowIcon />
                <Typography
                  variant="heading_500"
                  sx={{
                    span: {
                      color: (theme) => theme.palette.color.darkText,
                    },
                  }}
                >
                  Start with <span>Action name</span>
                </Typography>
              </Stack>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Modal>
    </div>
  );
}
