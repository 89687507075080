import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomCard from "../ui/CustomCard";
import VuetraIcon from "../Icons/VuetraIcon";
import CustomSwitch from "../ui/Switch";
import IntegrationModal from "./IntegrationModal";
import DiscordIcon from "../Icons/DiscordIcon";

export default function Integration() {
  const [isSelected, setIsSelected] = useState({
    vuetra: false,
    discord: false,
  });
  const [isModalOpen, setIsModalOpen]  = useState(false);

  const handleSwitchChange = (event, type) => {
    const { checked } = event.target;
    setIsSelected((prev) => {
      return {
        ...prev,
        [type]: checked,
      };
    });
    if(checked) {
      setIsModalOpen(true);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={5}>
          <CustomCard>
            <Box>
              <Stack
                direction={"column"}
                gap={0.5}
                alignItems={"flex-start"}
                sx={{
                  width: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      padding: "12px 16px",
                      borderRadius: "12px",
                      background: (theme) => theme.palette.color.lightShade,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.color.strockborder,
                      mb: 2.5,
                    }}
                  >
                    <VuetraIcon />
                  </Box>
                  <CustomSwitch
                    onChange={(e) => handleSwitchChange(e, "vuetra")}
                    value={isSelected.vuetra}
                  />
                </Stack>
                <Typography
                  variant="h4"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Vuetra
                </Typography>
                <Typography variant="subHeading">
                  Lorem ipsum dolor sit amet consectetur. Sollicitudin leo etiam
                  ut a maecenas orci consectetur.
                </Typography>
              </Stack>
            </Box>
          </CustomCard>
        </Grid>
        <Grid item md={5}>
          <CustomCard>
            <Box>
              <Stack
                direction={"column"}
                gap={0.5}
                alignItems={"flex-start"}
                sx={{
                  width: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      padding: "12px 16px",
                      borderRadius: "12px",
                      background: (theme) => theme.palette.color.lightShade,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.color.strockborder,
                      mb: 2.5,
                      "svg": {
                        width: 28,
                        height: 28
                      }
                    }}
                  >
                    <DiscordIcon />
                  </Box>
                  <CustomSwitch
                    onChange={(e) => handleSwitchChange(e, "discord")}
                    value={isSelected.vuetra}
                  />
                </Stack>
                <Typography
                  variant="h4"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Discord
                </Typography>
                <Typography variant="subHeading">
                  Lorem ipsum dolor sit amet consectetur. Sollicitudin leo etiam
                  ut a maecenas orci consectetur.
                </Typography>
              </Stack>
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
      <IntegrationModal isOpen={isModalOpen} onCloseModal={() => setIsModalOpen(false)}/>
    </Box>
  );
}
