import dayjs from "dayjs";
import Cookies from "js-cookie";

export function copyContent(val) {
  navigator.clipboard.writeText(val);
  return;
}

export function getProfileFromName(name) {
  const nameSplit = name?.split(" ");
  return nameSplit?.map((name) => name.charAt(0)).join("");
}

export const setToken = (token) => {
  Cookies.set("token", token, { expires: 1 / 24 });
};

export const getToken = () => {
  return Cookies.get("token");
};

export const removeToken = () => {
  Cookies.remove("token");
};

export const getTimeFromDate = (date) => {
  const formattedTime = dayjs(date).format("HH:mm:ss");
  return formattedTime;
};

export const calculatePercentage = (pnl, bln, target) => {
  const val = pnl / (bln * (target || 1));
  return val
}
