import { createTheme } from "@mui/material/styles";

import themeTypography from "./typography";
import componentStyleOverrides from "./compStyleOverride";

const getColor = (mode, darkColor, lightColor) => {
  return mode === "dark" ? darkColor : lightColor;
};

export const theme = (mode) => {
  const color = {
    primary: getColor(mode, "#CAD0DB", "#2E3E61"),
    secondary: "#8A96A6",
    tertiary: getColor(mode, "#D7E4F5", "#0E1011"),
    blue: "#8DADFF",
    blue1: "#8CADFF",
    darkgreen: "#102E23",
    yellow: "#F5CF11",
    orange: "#E69122",
    purple: "#754FFF",
    cyan: "#38C7B6",
    pink: "#F000FF",
    active: "#5F55EE",
    darkgray: getColor(mode, "#12191c", "#ffffff"),
    lightgray: getColor(mode, "#26282D", "#F0F0F5"),
    sidebar: getColor(mode, "#0E1015", "#F7F9FA"),
    gray: getColor(mode, "#14181F", "#1D232B"),
    darkblue: "#2196f3",
    bgblue: getColor(mode, "#121929", "#EDF3FF"),
    inputborder: getColor(mode, "transparent", "#e4e4e4"),
    border2: "#ced4da",
    borderpure: "#101419",
    strockborder: getColor(mode, "#212529", "#e5e5f0"),
    bgGrey: getColor(mode, "#131517", "#ffffff"),
    border: getColor(mode, "#15191FA6", "#EEEEEE"),
    border3: getColor(mode, "#647DB52B", "#EDEFF2"),
    paper: getColor(mode, "#13161A", "#F7F9FA"),
    bg: getColor(mode, "#0d0d0d", "#f5f5f8"),
    bg2: getColor(mode, "#0B0D0F", "#FFFFFFBF"),
    bg3: getColor(mode, "#13161A", "#F7F9FA"),
    bg4: getColor(mode, "#161A1F", "#F7F9FA"),
    bg5: getColor(mode, "#090B0D", "#F7F9FA"),
    buttonbg: getColor(mode, "#8DADFF", "#2E3E61"),
    button: getColor(mode, "#2E3E61", "#FFFFFF"),
    lightText: "#2E3E61",
    darkText: getColor(mode, "#ffffff", "#000000"),
    blackWhiteText: getColor(mode, "#000000", "#ffffff"),
    greenShade: "#2FE0A2",
    bluishLink: "#5f55ee59",
    lightShade: getColor(mode, "#15191C", "#e5e5f0"),
    chatActive: getColor(mode, "#15191C", "#f7f9fa"),
    skyColor: "#4ED5FF",
    skyLightColor: "#0099FF",
    blackShade: getColor(mode, "#0d0d0d", "#e5e5f0"),
    lightSky: "#00a3ff",
    red: "#ff4f6f",
    green: "#21ff90",
    barColor: getColor(mode, "#212529", "#e5e5f0")
  };
  const themeOption = {
    mode: mode,
    colors: color,
    paper: color.bg3,
    divider: color.border,
    success: color.green,
  };

  const themeOptions = {
    palette: {
      mode: themeOption.mode,
      color: themeOption.colors,
      success: {
        main: themeOption.colors.green,
      },
      primary: {
        main: themeOption.colors.blue,
      },
    },

    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);
  return themes;
};

export default theme;
