import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import KeyIcon from "../Icons/KeyIcon";
import CrossIcon from "../Icons/CrossIcon";
import { Controller, useForm } from "react-hook-form";
import {
  updatePassword,
  selectPasswordLoading,
} from "../../slices/settings/updatePassword";
import { useDispatch, useSelector } from "react-redux";
import { selectOtpLoading } from "../../slices/settings/verifyOtp";

export default function PasswordChangeModal({ isOpen, onCloseModal }) {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isOtpForm, setIsOtpForm] = useState(true);
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === 6) {
      console.log("test")
    }

    // Move to next input if current field is filled
    if (value && index < 6 - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  const loading = useSelector(selectPasswordLoading);
  const otpLoading = useSelector(selectOtpLoading);
  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    width: "100%",
    bgcolor: "background.paper",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.strockborder,
    boxShadow: 24,
    borderRadius: "16px",
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    const payload = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
      confirm_password: data.confirmPassword,
    };
    dispatch(updatePassword(payload))
      .then((res) => {
        console.log(res);
        if (res?.error?.message !== "Rejected") {
          setIsOtpForm(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        component="form"
        noValidate
        onSubmit={handleSubmit(!isOtpForm ? onSubmit : () => {})}
      >
        <Box
          sx={{
            padding: "16px",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <KeyIcon />
              <Typography
                variant="h5"
                color={(theme) => theme.palette.color.darkText}
              >
                {isOtpForm ? "Verify Password" : "Change Password"}
              </Typography>
            </Stack>
            <IconButton
              sx={{
                background: (theme) => theme.palette.color.lightgray,
                borderRadius: "32px",
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText,
                },
              }}
              onClick={onCloseModal}
            >
              <CrossIcon />
            </IconButton>
          </Stack>
        </Box>
        {!isOtpForm && (
          <Box
            sx={{
              p: "24px",
            }}
          >
            <Box>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Old Password
              </Typography>
              <Controller
                name="oldPassword"
                control={control}
                rules={{
                  required: "Old password is required",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="old-password"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    sx={{
                      mt: "8px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                  />
                )}
              />
              {errors.oldPassword && (
                <FormHelperText>{errors.oldPassword.message}</FormHelperText>
              )}
            </Box>
            <Box mt={2}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                New Password
              </Typography>
              <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: "New password is required",
                  validate: {
                    minLength: (value) =>
                      value.length >= 12 ||
                      "Password must be at least 12 characters long",
                    hasUpperCase: (value) =>
                      /[A-Z]/.test(value) ||
                      "Password must contain at least one uppercase letter",
                    hasLowerCase: (value) =>
                      /[a-z]/.test(value) ||
                      "Password must contain at least one lowercase letter",
                    hasSpecialChar: (value) =>
                      /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
                      "Password must contain at least one special character",
                    hasNumber: (value) =>
                      /\d/.test(value) ||
                      "Password must contain at least one number",
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    type={showNewPassword ? "text" : "password"}
                    className="custom_input"
                    fullWidth
                    {...field}
                    sx={{
                      mt: "8px",
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      trigger("newPassword");
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {!showNewPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography
                variant="subtitle2"
                color={(theme) => theme.palette.color.red}
              >
                Please add all necessary characters to create a safe password
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) =>
                      errors?.newPassword?.type === "minLength"
                        ? theme.palette.color.red
                        : theme.palette.color.secondary,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color={(theme) =>
                    errors?.newPassword?.type === "minLength"
                      ? theme.palette.color.red
                      : theme.palette.color.secondary
                  }
                >
                  Minimum characters 12
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) =>
                      errors?.newPassword?.type === "hasUpperCase"
                        ? theme.palette.color.red
                        : theme.palette.color.secondary,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color={(theme) =>
                    errors?.newPassword?.type === "hasUpperCase"
                      ? theme.palette.color.red
                      : theme.palette.color.secondary
                  }
                >
                  One uppercase character
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) =>
                      errors?.newPassword?.type === "hasLowerCase"
                        ? theme.palette.color.red
                        : theme.palette.color.secondary,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color={(theme) =>
                    errors?.newPassword?.type === "hasLowerCase"
                      ? theme.palette.color.red
                      : theme.palette.color.secondary
                  }
                >
                  One lowercase character
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) =>
                      errors?.newPassword?.type === "hasSpecialChar"
                        ? theme.palette.color.red
                        : theme.palette.color.secondary,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color={(theme) =>
                    errors?.newPassword?.type === "hasSpecialChar"
                      ? theme.palette.color.red
                      : theme.palette.color.secondary
                  }
                >
                  One special character
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) =>
                      errors?.newPassword?.type === "hasNumber"
                        ? theme.palette.color.red
                        : theme.palette.color.secondary,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color={(theme) =>
                    errors?.newPassword?.type === "hasNumber"
                      ? theme.palette.color.red
                      : theme.palette.color.secondary
                  }
                >
                  One numbers
                </Typography>
              </Stack>
            </Box>
            <Box mt={2}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Confirm New Password
              </Typography>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm password is required",
                }}
                defaultValue={""}
                render={({ field }) => (
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    className="custom_input"
                    fullWidth
                    sx={{
                      mt: "8px",
                    }}
                    {...field}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {!showConfirmPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.confirmPassword && (
                <FormHelperText>
                  {errors.confirmPassword.message}
                </FormHelperText>
              )}
            </Box>
          </Box>
        )}
        {isOtpForm && (
          <Box
            minHeight={450}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box textAlign={"center"}>
              <Stack direction={"column"} gap={0.5}>
                <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Verification
                </Typography>
                <Typography variant="subtitle2">
                  Enter the 6-digit code sent to j*****@****oud.com
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
                sx={{
                  mt: 3,
                  mb: 4,
                }}
              >
                {otp.map((value, index) => {
                  return (
                    <TextField
                      key={index}
                      id={`otp-${index}`}
                      label=""
                      variant="outlined"
                      className="custom_input otp_fields"
                      autoComplete="off"
                      inputRef={(input) => (inputRefs.current[index] = input)}
                      value={value}
                      onChange={(e) => handleChange(index, e)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      sx={{
                        mt: "8px",
                        input: {
                          background: (theme) =>
                            theme.palette.color.blackShade + "! important",
                        },
                      }}
                    />
                  );
                })}
              </Stack>
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  justifyContent={"center"}
                >
                  <Button
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.palette.color.darkText,
                      textTransform: "capitalize",
                      p: 0,
                    }}
                  >
                    Resend Code
                  </Button>
                  <Typography variant="subtitle2">(59s)</Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            p: "16px",
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={3.5}
          >
            <Button
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: (theme) => theme.palette.color.secondary,
                textTransform: "capitalize",
              }}
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            {isOtpForm ? (
              <Button className="custom_color_btn customButton" type="submit">
                {otpLoading ? (
                  <CircularProgress color="inherit" size={21} />
                ) : (
                  "Verify"
                )}
              </Button>
            ) : (
              <Button
                className="custom_color_btn customButton"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={21} />
                ) : (
                  "Change Password"
                )}
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
