import React from "react";

function HomeIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ display: 'block' }}
    >
      <path
        d="M8 3V15M8 2.75L0.75 0.75V13.25L8 15.25L15.25 13.25V0.75L8 2.75Z"
        stroke={"white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HomeIcon2;
