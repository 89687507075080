import React from 'react'
import FileIcon from '../Icons/FileIcon'
import { Stack, Typography } from '@mui/material'

function CardHeader({title="Title"}) {
  return (
    <Stack gap={"8px"} direction={"row"} alignItems={"center"} sx={{
      "& svg path:first-child": {
        fill: (theme) => theme.palette.color.lightgray,
        stroke: "transparent"
      },
      "& svg path": {
        stroke: (theme) => theme.palette.color.darkText
      }
    }}>
      <FileIcon />
      <Typography sx={{ fontSize: "16px" }} variant="h6">{title}</Typography>
    </Stack>
  )
}

export default CardHeader