import React from "react";

export default function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 9.741H19.222M5 9.741V16.694C5 17.579 5 18.021 5.194 18.36C5.364 18.657 5.636 18.899 5.971 19.05C6.35 19.222 6.848 19.222 7.842 19.222H16.38C17.374 19.222 17.871 19.222 18.251 19.05C18.586 18.898 18.858 18.657 19.028 18.36C19.222 18.021 19.222 17.58 19.222 16.697V9.741M5 9.741V9.109C5 8.224 5 7.781 5.194 7.443C5.364 7.145 5.635 6.904 5.971 6.753C6.351 6.58 6.849 6.58 7.844 6.58H8.555M19.222 9.741V9.106C19.222 8.223 19.222 7.781 19.028 7.443C18.858 7.146 18.585 6.904 18.251 6.753C17.871 6.58 17.373 6.58 16.378 6.58H15.667M8.555 6.58H15.667M8.555 6.58V5M15.667 6.58V5"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
