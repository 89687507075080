import React from "react";

export default function VuetraIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.1"
        d="M9.42636 5.46394C9.42636 3.29939 7.62405 1.54468 5.4008 1.54468C3.17755 1.54468 1.37524 3.29939 1.37524 5.46394V20.0808C1.37524 22.2453 3.17755 24 5.4008 24C7.62405 24 9.42636 22.2453 9.42636 20.0808V5.46394Z"
        fill="white"
      />
      <path
        d="M7.51181 1.95967C5.58641 0.877394 3.12441 1.51965 2.01278 3.3942C0.901152 5.26874 1.56085 7.66572 3.48626 8.74799L16.4882 16.0564C18.4136 17.1387 20.8756 16.4964 21.9872 14.6219C23.0988 12.7473 22.4391 10.3504 20.5137 9.26808L7.51181 1.95967Z"
        fill="white"
      />
    </svg>
  );
}
