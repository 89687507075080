import React, { useEffect, useRef, useState } from "react";
import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import PrevIcon from "../../Icons/PrevIcon";
import NextIcon from "../../Icons/NextIcon";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchClosedPositions } from "../../../slices/accounts/trade";

const today = dayjs();

export default function CustomCalendar() {
  const [selectedMonth, setSelectedMonth] = useState(today.startOf("month"));
  const [positionData, setPositionData] = useState([]);
  const calendarRef = useRef(null);
  const { closedPositions } = useSelector((state) => state.trades);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if(params?.id) {
      dispatch(fetchClosedPositions(params.id));
    }
  }, [dispatch, params]);
  
  useEffect(() => {
    const events = closedPositions && closedPositions.reduce((acc, trade) => {
      const date = trade.open_time.split("T")[0];
      const profit = parseFloat(trade.profit) || 0;
    
      // Initialize the date entry if it doesn't exist
      if (!acc[date]) {
        acc[date] = { date, tradesCount: 0, totalProfit: 0 };
      }
    
      acc[date].tradesCount += 1;
      acc[date].totalProfit += profit;
    
      return acc;
    }, {});

    if(events) {
      const eventList = Object.keys(events).length > 0
      ? Object.values(events).map(({ date, tradesCount, totalProfit }, index) => ({
          id: (index + 1).toString(),
          calendarId: '1',
          title: `${totalProfit >= 0 ? '+' : '-'} ${Math.abs(totalProfit).toFixed(2)}`,
          trades: `${tradesCount} trades`,
          category: 'time',
          start: `${date}T00:00:00`,
          end: `${date}T23:59:59`,
          isReadOnly: true,
          raw: {
            trades: `${tradesCount} trades`
          }
        }))
      : [];
      setPositionData(eventList);
    }
  }, [closedPositions])

  const calendarInstance = calendarRef.current?.getInstance();

  const handleNext = () => {
    const month = selectedMonth.add(1, "month").startOf("month");
    setSelectedMonth(month);
  };

  const handlePrev = () => {
    const month = selectedMonth.subtract(1, "month").startOf("month");
    setSelectedMonth(month);
  };

  useEffect(() => {
    if (calendarInstance && Object.keys(calendarInstance).length > 0) {
      calendarInstance.setDate(dayjs(selectedMonth.toDate()));
    }
  }, [selectedMonth, calendarInstance]);

  const template = {
    time(schedule) {
      return `<div class="schedule-list-month"><p style="margin: 0;" class=${stringToNumber(schedule.title) < 0 ? "minus": "plus"}>${schedule.title}</p> <span style="margin: 0;">${schedule.raw.trades}</span></div>`;
    },
  };

  const stringToNumber = (str) => {
    // Remove any commas and spaces, and then convert to number
    const cleanedString = str.replace(/,/g, '').replace(/\s+/g, '');
    return parseFloat(cleanedString);
  };

  const onEventClick = (e) => {
   
  };

  return (
    <Box className="custom_calendar-view">
      <Stack direction={"row"} alignItems={"center"} gap={1} sx={{
        mb: 2
      }}>
        <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>{selectedMonth.format("MMMM YYYY")}</Typography>
        <IconButton
          onClick={handlePrev}
          sx={{
            p: 0,
          }}
        >
          <PrevIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{
            p: 0,
          }}
        >
          <NextIcon />
        </IconButton>
      </Stack>
      <Box className="custom_calendar" sx={{
        ".toastui-calendar-layout .toastui-calendar-daygrid-cell" : {
          border: "1px solid !important",
          borderColor: (theme) => `${theme.palette.color.barColor} !important`,
          borderRadius: "12px",
          margin: "8px",
          background: (theme) => `${theme.palette.color.bgGrey} !important`,
          width: "19% !important",
          height: "90% !important",
        }
      }}>
        <Calendar
          usageStatistics={false}
          month={{ startDayOfWeek: 1, workweek: true, }}
          events={positionData}
          useDetailPopup={false}
          useFormPopup={false}
          view={"month"}
          ref={calendarRef}
          template={template}
          onClickEvent={onEventClick}
          gridSelection={{ enableDblClick: false, enableClick: false }}
        />
      </Box>
    </Box>
  );
}
