import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../ui/CustomCard";
import DownloadIcon from "../Icons/DownloadIcon";

export default function Billing() {
  return (
    <Box>
      <CustomCard customPadding={"0"}>
        <Box p={3} backgroundColor={(theme) => theme.palette.color.lightShade}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="subHeaddingMedium" color={(theme) => theme.palette.color.darkText}>Invoice Details</Typography>
            <Button className="custom_color_btn customButton outline_btn" endIcon={<DownloadIcon />}>
             Download Invoice
            </Button>
          </Stack>
        </Box>
        <Box py={2} px={3}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"column"} gap={0.5}>
              <Typography variant="subHeading">Invoice Date : 2024/01/12</Typography>
              <Typography variant="h5" color={(theme) => theme.palette.color.darkText}>Visa **** 1645</Typography>
            </Stack>
            <Box sx={{
              padding: "12px 16px",
              borderRadius: "12px",
              background: (theme) => theme.palette.color.lightgray
            }}>
              <Typography variant="h1_bigger" color={(theme) => theme.palette.color.darkText}>$14.95</Typography>
            </Box>
          </Stack>
        </Box>
      </CustomCard>
      <Box mt={3}>
        <CustomCard customPadding={"0"}>
          <Box
            p={3}
            backgroundColor={(theme) => theme.palette.color.lightShade}
          >
            <Stack>
              <Typography variant="subHeaddingMedium" color={(theme) => theme.palette.color.darkText}>Billing Details</Typography>
            </Stack>
          </Box>
          <Box p={3}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>Company name</Typography>
                  <Typography variant="subHeading">Barone LLC.</Typography>
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>Email</Typography>
                  <Typography variant="subHeading">info@vuetra.com</Typography>
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>COC number (Chamber of Commerce)</Typography>
                  <Typography variant="subHeading">212-686-7220</Typography>
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>VAT number</Typography>
                  <Typography variant="subHeading">123456789</Typography>
                </Stack>
              </Grid>
              <Grid item md={12}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>Address</Typography>
                  <Typography variant="subHeading">
                    3891 Ranchview Dr. Richardson, New York 62639
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={3}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>Country</Typography>
                  <Typography variant="subHeading">U.S.A</Typography>
                </Stack>
              </Grid>
              <Grid item md={3}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>State</Typography>
                  <Typography variant="subHeading">New York State (NY)</Typography>
                </Stack>
              </Grid>
              <Grid item md={3}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>City</Typography>
                  <Typography variant="subHeading">New York</Typography>
                </Stack>
              </Grid>
              <Grid item md={3}>
                <Stack direction={"column"} gap={0.5}>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>Zipcode</Typography>
                  <Typography variant="subHeading">10001</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CustomCard>
      </Box>
    </Box>
  );
}
