import React from "react";

export default function ToggleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13 5H17.5C18.0304 5 18.5391 5.21071 18.9142 5.58579C19.2893 5.96086 19.5 6.46957 19.5 7V17.5C19.5 18.0304 19.2893 18.5391 18.9142 18.9142C18.5391 19.2893 18.0304 19.5 17.5 19.5H13M5 17.5V7C5 6.46957 5.21071 5.96086 5.58579 5.58579C5.96086 5.21071 6.46957 5 7 5H9.5V19.5H7C6.46957 19.5 5.96086 19.2893 5.58579 18.9142C5.21071 18.5391 5 18.0304 5 17.5Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
