import React from "react";

export default function BarChartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.75 19.25H19.25M6.25 8.75H5.75C5.48478 8.75 5.23043 8.85536 5.04289 9.04289C4.85536 9.23043 4.75 9.48478 4.75 9.75V15.25C4.75 15.5152 4.85536 15.7696 5.04289 15.9571C5.23043 16.1446 5.48478 16.25 5.75 16.25H6.25C6.51522 16.25 6.76957 16.1446 6.95711 15.9571C7.14464 15.7696 7.25 15.5152 7.25 15.25V9.75C7.25 9.48478 7.14464 9.23043 6.95711 9.04289C6.76957 8.85536 6.51522 8.75 6.25 8.75ZM12.25 4.75H11.75C11.4848 4.75 11.2304 4.85536 11.0429 5.04289C10.8554 5.23043 10.75 5.48478 10.75 5.75V15.25C10.75 15.5152 10.8554 15.7696 11.0429 15.9571C11.2304 16.1446 11.4848 16.25 11.75 16.25H12.25C12.5152 16.25 12.7696 16.1446 12.9571 15.9571C13.1446 15.7696 13.25 15.5152 13.25 15.25V5.75C13.25 5.48478 13.1446 5.23043 12.9571 5.04289C12.7696 4.85536 12.5152 4.75 12.25 4.75ZM18.25 8.75H17.75C17.4848 8.75 17.2304 8.85536 17.0429 9.04289C16.8554 9.23043 16.75 9.48478 16.75 9.75V15.25C16.75 15.5152 16.8554 15.7696 17.0429 15.9571C17.2304 16.1446 17.4848 16.25 17.75 16.25H18.25C18.5152 16.25 18.7696 16.1446 18.9571 15.9571C19.1446 15.7696 19.25 15.5152 19.25 15.25V9.75C19.25 9.48478 19.1446 9.23043 18.9571 9.04289C18.7696 8.85536 18.5152 8.75 18.25 8.75Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
