import { Card } from "@mui/material";
import React from "react";

export default function CustomCard({ children, customPadding="20px 24px", className="" }) {
  return (
    <Card
      sx={{
        background: (theme) => theme.palette.color.bgGrey,
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.strockborder,
        borderRadius: "16px",
        padding: customPadding,
        boxShadow: "none"
      }}
      className={className}
    >
      {children}
    </Card>
  );
}
