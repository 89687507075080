import EconomicCalendar from "../components/Layout/Header/EconomicCalendar/EconomicCalendar";
import PartnerDetails from "../components/PartnerProgram/PartnerDetails";
import Abook from "../pages/Abook";
import AccountDetail from "../pages/AccountDetail";
import AllAccounts from "../pages/AllAccounts";
import BillingDetails from "../pages/BillingDetails";
import CheckEmail from "../pages/CheckEmail";
import Community from "../pages/Community";
import Dashboard from "../pages/Dashboard";
import Evaluation from "../pages/Evaluation";
import ForgotPass from "../pages/ForgotPass";
import HelpArticle from "../pages/HelpArticle";
import HelpCenter from "../pages/HelpCenter";
import HelpCenterGraphPage from "../pages/HelpCenterGraphPage";
import Leaderboard from "../pages/LeaderBoard";
import LeaderboardListing from "../pages/LeaderboardListing";
import Login from "../pages/Login";
import Metrics from "../pages/Metrics";
import Partner from "../pages/Partner";
import PaymentDetails from "../pages/paymentDetails";
import PaymentInfo from "../pages/PaymentInfo";
import Register from "../pages/Register";
import ResetSuccess from "../pages/ResetSuccess";
import SetNewPass from "../pages/SetNewPass";
import Settings from "../pages/Settings";

export const navItems = [
  { path: "/", element: <Dashboard />, isPrivate: true, layout: true },
  { path: "/accounts", element: <AllAccounts />, isPrivate: true, layout: true },
  { path: "/tools", element: <AllAccounts />, isPrivate: true, layout: true },
  { path: "/account/:id", element: <AccountDetail />, isPrivate: true, layout: true },
  { path: "/metrics", element: <Metrics />, isPrivate: true, layout: true },
  { path: "/new-evaluation", element: <Evaluation />, isPrivate: true, layout: true },
  { path: "/help-center", element: <HelpCenter />, isPrivate: true, layout: true },
  { path: "/help-article", element: <HelpArticle />, isPrivate: true, layout: true },
  { path: "/help-chart", element: <HelpCenterGraphPage />, isPrivate: true, layout: true },
  { path: "/leaderboard", element: <Leaderboard />, isPrivate: true, layout: true },
  { path: "/leaderboard/highest-withdraws", element: <LeaderboardListing />, isPrivate: true, layout: true },
  { path: "/leaderboard/biggest-profit", element: <LeaderboardListing />, isPrivate: true, layout: true },
  { path: "/partner", element: <Partner />, isPrivate: true, layout: true },
  { path: "/partner/:slug", element: <PartnerDetails />, isPrivate: true, layout: true },
  { path: "/settings", element: <Settings />, isPrivate: true, layout: true },
  { path: "/billing-details", element: <BillingDetails />, isPrivate: true, layout: true },
  { path: "/payment-details", element: <PaymentDetails />, isPrivate: true, layout: true },
  { path: "/payment-info", element: <PaymentInfo />, isPrivate: true, layout: true },
  { path: "/login", element: <Login />, isPrivate: false, layout: false },
  { path: "/register", element: <Register />, isPrivate: false, layout: false },
  { path: "/check-email", element: <CheckEmail />, isPrivate: false, layout: false },
  { path: "/forgot-password", element: <ForgotPass />, isPrivate: false, layout: false },
  { path: "/set-new-password", element: <SetNewPass />, isPrivate: false, layout: false },
  { path: "/reset-success", element: <ResetSuccess />, isPrivate: false, layout: false },
  { path: "/economic-events", element: <EconomicCalendar />, isPrivate: true, layout: true },
  { path: "/a-book", element: <Abook />, isPrivate: true, layout: true },
  { path: "/community", element: <Community />, isPrivate: true, layout: true },
];
