import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { getToken } from "../../utils/helpers";
import { showSnackbar } from "../snackbarSlice";

// Define the initial state
const initialState = {
  profile: null,
  token: getToken() || null,
  loading: false,
  error: null,
};

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async () => {
    const response = await axiosInstance.get("/api/client/get-client-info");
    return response.data.data;
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (credentials, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosInstance.post("/api/client/update-client-info", credentials);
      dispatch(showSnackbar({ message: "Successfully updated", severity: 'success' }));
      return response.data.data;
    } catch (err) {
      dispatch(showSnackbar({ message: err.response.data.message, severity: 'error' }));
      return rejectWithValue(err.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const loginLoading = (state) => state.auth.loading;

export default profileSlice.reducer;
