import React from "react";

export default function CommunityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.7499 14C17.8289 14 18.6799 16.148 19.0239 17.696C19.2089 18.532 18.5339 19.25 17.6769 19.25H16.7499M5.78195 19.25H13.2179C13.7829 19.25 14.2269 18.782 14.1139 18.229C13.8039 16.7 12.7899 14 9.49995 14C6.20995 14 5.19595 16.701 4.88495 18.229C4.77295 18.782 5.21695 19.25 5.78195 19.25Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 10.25C11.0188 10.25 12.25 9.01878 12.25 7.5C12.25 5.98122 11.0188 4.75 9.5 4.75C7.98122 4.75 6.75 5.98122 6.75 7.5C6.75 9.01878 7.98122 10.25 9.5 10.25Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 10.25C16.269 10.25 17.25 9.019 17.25 7.5C17.25 5.981 16.269 4.75 14.75 4.75"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
