import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";

export default function OrderSummary({ isOrderPage, onStepChange }) {
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.color.bgGrey,
        borderRadius: "12px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.color.strockborder,
        overflow: "hidden",
        marginLeft: "40px",
      }}
    >
      <Box
        px={"24px"}
        py={"21px"}
        backgroundColor={(theme) => theme.palette.color.lightShade}
        sx={{
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
      >
        <Typography variant="subHeaddingMedium">Order Summery</Typography>
      </Box>
      <Box p={"24px"}>
        {!isOrderPage && (
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: (theme) => theme.palette.color.strockborder,
            }}
            pb={"16px"}
            mb={"16px"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <TextField
                id="outlined-basic"
                label=""
                placeholder="Discount Code or Gift card"
                variant="outlined"
                className="custom_input"
                fullWidth
              />
              <Button className="customButton discount_apply_btn">Apply</Button>
            </Stack>
          </Box>
        )}
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
          pb={"16px"}
          mb={"16px"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={"5px"}
          >
            <Typography variant="h4">Plan Details</Typography>
            <Typography variant="h4">$276.00</Typography>
          </Stack>
          <Typography variant="subHeading">For Jens’s premium plan</Typography>
        </Box>
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
          pb={"16px"}
          mb={"16px"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              variant="h4"
              color={(theme) => theme.palette.color.lightSky}
            >
              Total Due Today
            </Typography>
            <Typography
              variant="h4"
              color={(theme) => theme.palette.color.lightSky}
            >
              $276.00
            </Typography>
          </Stack>
        </Box>
        <Box mb={"16px"}>
          <Stack direction={"row"} alignItems={"flex-start"} gap={1.5}>
            <Box
              sx={{
                width: "8px",
                height: "5px",
                borderRadius: "50%",
                background: (theme) => theme.palette.color.secondary,
                marginTop: "8px",
              }}
            />
            <Typography variant="subHeading">
              Today we'll charge you $276.00 for the cost of your subscription
              applicable taxes minus existing credits on your account.
            </Typography>
          </Stack>
        </Box>
        <Button
          className="custom_color_btn customButton"
          fullWidth
          sx={{
            padding: "14px 0 !important",
          }}
        >
          pay $276.00 USD now
        </Button>
      </Box>
    </Box>
  );
}
