import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

const tabStyle = { fontSize: "13px !important", fontWeight: "500 !important" };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs({ value, onChange, tabs }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{}}>
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="we fund tabs"
          className="custom_tabs_container"
        >
          {tabs.map((tab, index) => (
            <Tab
              sx={tabStyle}
              label={tab.label}
              {...a11yProps(index)}
              key={`${tab.label}-${index}`}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
}
