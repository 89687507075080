import React from "react";

export default function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.25 13.25V15.25"
        stroke="#8A96A6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 9.25C12.75 9.52614 12.5261 9.75 12.25 9.75C11.9739 9.75 11.75 9.52614 11.75 9.25C11.75 8.97386 11.9739 8.75 12.25 8.75C12.5261 8.75 12.75 8.97386 12.75 9.25Z"
        stroke="#8A96A6"
      />
      <path
        d="M12.25 19.5C16.2541 19.5 19.5 16.2541 19.5 12.25C19.5 8.24594 16.2541 5 12.25 5C8.24594 5 5 8.24594 5 12.25C5 16.2541 8.24594 19.5 12.25 19.5Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
