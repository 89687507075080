import {
  Box,
  Grid,
  Stack,
  Typography,
  Checkbox,
  Button,
  Input,
  FormHelperText,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import AuthLayout from "../components/Login/AuthLayout";
import FormControlLabel from "@mui/material/FormControlLabel";
import GoogleIcon from "../components/Icons/GoogleIcon";
import AppleIcon from "../components/Icons/AppleIcon";
import EmailIcon from "../components/Icons/EmailIcon";
import PassHide from "../components/ui/PassHide";
import PassShow from "../components/ui/PassShow";
import { Link } from "react-router-dom";
import { registerUser } from "../slices/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

export default function Register() {
  const [showPassword, setShowPassword] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.status);
  const themeMode = useSelector((state) => state.theme.mode);
  const handlePassVisiblety = () => {
    setShowPassword((prev) => (prev === "password" ? "text" : "password"));
  };
  const handleConfirmPassVisiblety = () => {
    setShowConfirmPassword((prev) =>
      prev === "password" ? "text" : "password"
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (event) => {
    const formData = {
      full_name: `${event.first_name} ${event.last_name}`,
      email: event.email,
      password: event.password,
      password1: event.password,
      password2: event.confirm_password,
    };
    dispatch(registerUser(formData));
  };

  const password = watch("password");

  const privacyPolicy = (
    <Typography
      variant="caption"
      fontSize={"10px"}
      fontWeight={"400"}
      sx={{
        span: {
          color: (theme) => theme.palette.color.darkText,
        },
      }}
    >
      I agree to the processing of personal data according to{" "}
      <span>privacy policy.</span>
    </Typography>
  );

  const serives = (
    <Typography
      variant="caption"
      fontSize={"10px"}
      fontWeight={"400"}
      sx={{
        span: {
          color: (theme) => theme.palette.color.darkText,
        },
      }}
    >
      I acknowledge that I am not from/in a{" "}
      <span>country where Wefund doesn't provide services</span> and my
      credentials are correct and correspond to the government-issued
      identification.
    </Typography>
  );

  return (
    <Stack sx={{ height: "100vh" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: (theme) => theme.palette.color.bg,
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Sign-up for Wefund
          </Typography>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ paddingTop: "24px", justifyContent: "center" }}
          >
            <Button
              className="loginButton"
              sx={{
                backgroundColor: (theme) => theme.palette.color.skyColor,
                color: (theme) => theme.palette.color.darkText,
                px: "35px",
                py: "14px",
                textTransform: "none",
                borderRadius: "8px",
              }}
            >
              <GoogleIcon /> Sign up with Google
            </Button>
            <Button
              className="custom_color_btn customButton"
              sx={{
                backgroundColor: (theme) => theme.palette.color.skyColor,
                color: (theme) => theme.palette.color.darkText,
                px: "35px",
                py: "14px",
                textTransform: "none",
                borderRadius: "8px",
              }}
            >
              <AppleIcon themeColor={themeMode} /> Sign up with apple
            </Button>
          </Stack>
          <div className="no-span section-divider">OR continue with</div>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  First Name
                </Typography>
                <Box position={"relative"}>
                  <Controller
                    name="first_name"
                    control={control}
                    rules={{
                      required: "First Name is required",
                    }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="custom_input"
                        placeholder="Enter name"
                        autoComplete="off"
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          background: (theme) =>
                            theme.palette.color.lightShade + "!important",
                        }}
                      />
                    )}
                  />
                  {errors.first_name && (
                    <FormHelperText>{errors.first_name.message}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Last Name
                </Typography>
                <Box position={"relative"}>
                  <Controller
                    name="last_name"
                    control={control}
                    rules={{
                      required: "Last Name is required",
                    }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className="custom_input"
                        placeholder="Enter name"
                        autoComplete="off"
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          background: (theme) =>
                            theme.palette.color.lightShade + "!important",
                        }}
                      />
                    )}
                  />
                  {errors.last_name && (
                    <FormHelperText>{errors.last_name.message}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Email
                </Typography>
                <Box position={"relative"}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        startAdornment={
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        }
                        className="custom_input"
                        autoComplete="off"
                        placeholder="Email"
                        sx={{
                          marginTop: "5px",
                          width: "100%",
                          background: (theme) =>
                            theme.palette.color.lightShade + "!important",
                        }}
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText>{errors.email.message}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Password
                </Typography>
                <Box>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required" }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type={showPassword}
                        sx={{
                          marginTop: "5px",
                          width: "100%",
                          background: (theme) =>
                            theme.palette.color.lightShade + "!important",
                        }}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton onClick={handlePassVisiblety}>
                              {showPassword === "password" ? (
                                <PassHide />
                              ) : (
                                <PassShow />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        className="custom_input"
                        placeholder="Enter password"
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText>{errors.password.message}</FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Confirm Password
                </Typography>
                <Box>
                  <Controller
                    name="confirm_password"
                    control={control}
                    rules={{
                      required: "Confirm password is required",
                      validate: (value) =>
                        value === password || "Passwords do not match",
                    }}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type={showConfirmPassword}
                        sx={{
                          marginTop: "5px",
                          width: "100%",
                          background: (theme) =>
                            theme.palette.color.lightShade + "!important",
                        }}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton onClick={handleConfirmPassVisiblety}>
                              {showConfirmPassword === "password" ? (
                                <PassHide />
                              ) : (
                                <PassShow />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        className="custom_input"
                        placeholder="Enter confirm password"
                      />
                    )}
                  />
                  {errors.confirm_password && (
                    <FormHelperText>
                      {errors.confirm_password.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Stack
              sx={{
                my: 2,
              }}
            >
              <Controller
                name="privacy"
                control={control}
                rules={{ required: "You must agree to the privacy policy" }}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        color="primary"
                      />
                    }
                    label={privacyPolicy}
                    sx={{
                      marginLeft: 0,
                    }}
                  />
                )}
              />
              <Controller
                name="services"
                control={control}
                rules={{ required: "You must agree to the services" }}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="allowExtraEmails"
                        color="primary"
                        {...field}
                      />
                    }
                    label={serives}
                    sx={{
                      marginLeft: 0.6,
                    }}
                  />
                )}
              />
              {(errors.services || errors.privacy) && (
                <FormHelperText>
                  You have to accept the privacy policy
                </FormHelperText>
              )}
            </Stack>
            <Button
              className="custom_color_btn customButton"
              fullWidth
              sx={{
                padding: "14px !important",
                fontSize: "16px !important",
              }}
              disabled={loading === "loading"}
              type="submit"
            >
              {loading === "loading" ? (
                <CircularProgress color="inherit" size={21} />
              ) : (
                "Sign Up"
              )}
            </Button>
            <Grid container justifyContent="center" sx={{ pt: "40px" }}>
              <Grid item>
                <Typography
                  variant="subtitle2_500"
                  sx={{
                    a: {
                      color: (theme) => theme.palette.color.skyColor,
                      textDecoration: "none",
                    },
                  }}
                >
                  Already have an account?{" "}
                  <Link to="/login" variant="body2">
                    Sign in
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
