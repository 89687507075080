import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import LeftAngleArrow from "../../assets/LeftAngleArrow.svg";
import HomeIcon2 from "../Icons/HomeIcon2";
import VideoIcon from "../Icons/VideoIcon";
import FolderIcon from "../Icons/FolderIcon";
import AiIcon from "../Icons/AiIcon";
import { helpCenterNavItems } from "../../../src/static/navItems";
import InfoIcon2 from "../Icons/InfoIcon2";
import PlusIcon from "../Icons/PlusIcon";

const icons = {
  home: <HomeIcon2 />,
  video: <VideoIcon />,
  ai: <AiIcon />,
  folder: <FolderIcon />,
};

const HelpCenterSidebar = () => {
  const renderMenu = (menu) => {
    return Object.keys(menu).map((key) => {
      const isBorderItem =
        key === "Ai Assistant" || key === "CRM" || key === "Policies";

      if (typeof menu[key] === "object" && menu[key].link) {
        return (
          <Box
            key={key}
            sx={{
              paddingTop: "14px",
              paddingBottom: "14px",
              paddingLeft: "14px",
              marginBottom: isBorderItem ? "10px" : "8px",
              borderBottom: isBorderItem ? "1px solid" : "none",
              borderColor: isBorderItem ? (theme) => theme.palette.color.strockborder : ""
            }}
          >
            <NavLink to={menu[key].link}>
              {icons[menu[key].icon]}{" "}
              <Typography
                variant="paragraph"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "14px",
                  lineHeight: "20px",
                  padding: "0",
                  fontWeight: 400,
                }}
              >
                {key}
              </Typography>
            </NavLink>
          </Box>
        );
      } else if (typeof menu[key] === "object") {
        return (
          <Box
            key={key}
            sx={{
              borderBottom:
                key === "CRM" || key === "Policies"
                  ? "1px solid"
                  : "none",
              borderColor: (theme) => theme.palette.color.strockborder,
              paddingBottom: key === "CRM" || key === "Policies" ? "10px" : "0",
              marginBottom: key === "CRM" || key === "Policies" ? "10px" : "0",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                color: (theme) => theme.palette.color.secondary,
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 400,
              }}
            >
              {key}
            </Typography>
            <ul>{renderMenu(menu[key])}</ul>
          </Box>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Box sx={{ width: "300px", height: "100%", minWidth: "275px" }}>
      <Box
        sx={{
          color: "#8A96A6",
          backgroundColor: (theme) => theme.palette.color.bg2,
          borderRadius: "16px",
          paddingBottom: "8px",
          height: "100%",
          mx: 2,
          border: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
          padding: 1.5,
          position: "relative",
        }}
      >
        <Box>
          <Typography
            sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: 500 }}
            variant="h2"
          >
            We-Fund <span style={{ color: "#754FFF" }}>DOCS</span>
          </Typography>
          <Stack direction={"row"} alignItems={"center"} gap={1} pt={"8px"}>
            <img src={LeftAngleArrow} alt="" />
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "18px",
                color: (theme) => theme.palette.color.secondary,
              }}
            >
              Back to Dashboard
            </Typography>
          </Stack>
        </Box>
        <div className="sidebar">
          <ul>{renderMenu(helpCenterNavItems.menu)}</ul>
        </div>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            left: 0,
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Box
            sx={{
              padding: "14px",
              paddingLeft: "28px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <InfoIcon2 />
            <Typography
              variant="paragraph"
              sx={{ fontSize: "14px", lineHeight: "20px" }}
            >
              Couldn’t find your questions?
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "14px",
              paddingLeft: "28px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <PlusIcon />
            <Typography
              variant="paragraph"
              sx={{ fontSize: "14px", lineHeight: "20px" }}
            >
              Create Article{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpCenterSidebar;
