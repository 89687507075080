import React from "react";

function InfoIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.25 9.25V11.25"
        stroke="#8A96A6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 5.25C8.75 5.52614 8.52614 5.75 8.25 5.75C7.97386 5.75 7.75 5.52614 7.75 5.25C7.75 4.97386 7.97386 4.75 8.25 4.75C8.52614 4.75 8.75 4.97386 8.75 5.25Z"
        stroke="#8A96A6"
      />
      <path
        d="M8.25 15.5C12.2541 15.5 15.5 12.2541 15.5 8.25C15.5 4.24594 12.2541 1 8.25 1C4.24594 1 1 4.24594 1 8.25C1 12.2541 4.24594 15.5 8.25 15.5Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InfoIcon2;
