import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import UpDownIcon from "../Icons/UpDownIcon";

const data = [
  { label: "", values: ["Step 1", "Step 2", "Step 3"] },
  { label: "Trading Period", values: ["Unlimited", "Unlimited", "Unlimited"] },
  {
    label: "Minimum Trading Days",
    values: ["Unlimited", "Unlimited", "Unlimited"],
  },
  {
    label: "Maximum Daily Loss",
    values: [
      { value: "$500", percentage: "(5%)" },
      { value: "$500", percentage: "(5%)" },
      { value: "$500", percentage: "(5%)" },
    ],
  },
  {
    label: "Maximum Total Loss",
    values: [
      { value: "$500", percentage: "(5%)" },
      { value: "$500", percentage: "(5%)" },
      { value: "$500", percentage: "(5%)" },
    ],
  },
  { label: "Profit Target", values: ["$1500", "$1500", "N/A"] },
];

const TradingInfo = () => {
  return (
    <Box mx={3}>
      {data.map((item, index) => (
        <Grid container spacing={2} key={index}>
          <Grid
            item
            md={3}
            sx={{
              padding: "30px 0!important",
            }}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {item.label && <UpDownIcon />}
              <Typography variant="heading_500">{item.label}</Typography>
            </Stack>
          </Grid>
          {item.values.map((value, subIndex) => (
            <Grid
              item
              md={3}
              key={subIndex}
              sx={{
                padding: "20px 0!important",
                backgroundColor:
                  item.label !== "" && (subIndex === 0 || subIndex === 2)
                    ? (theme) => theme.palette.color.bgGrey
                    : "inherit",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.label === "Maximum Daily Loss" ||
              item.label === "Maximum Total Loss" ? (
                <Stack>
                  <Typography component="subtitle2_500">
                    {value.value}
                  </Typography>
                  <Typography
                    component="subtitle2_500"
                    color={(theme) => theme.palette.color.skyColor}
                    mt={1}
                  >
                    {value.percentage}
                  </Typography>
                </Stack>
              ) : (
                <Typography
                  variant={item.label ? "subtitle2_500" : "heading"}
                  color={(theme) => theme.palette.color.darkText}
                >
                  {value}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );
};

export default TradingInfo;
