import React from 'react';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

const ChartComponent = ({ balanceHistoryData = [] }) => {
  const series = [
    {
      name: 'Balance',
      data: balanceHistoryData.map(entry => parseFloat(entry.balance)),
    },
  ];

  const options = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.1,
        stops: [0, 90, 100],
      },
      dropShadow: {
        enabled: false, // Disable shadow
      },
    },
    markers: {
      size: 0,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: balanceHistoryData.map(entry => dayjs(entry.timestamp).format('HH:mm:ss')), // Formats timestamps to time
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show: true,
      },
    },
    yaxis: {},
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      x: {
        format: 'yyyy-MM-dd HH:mm:ss',
      },
    },
    colors: ['#00E396'],
  };

  return (
    <div>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default ChartComponent;
