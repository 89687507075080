import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { hideSnackbar, selectSnackbar } from "../../slices/snackbarSlice";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";

const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(selectSnackbar);

  useEffect(() => {
    if (snackbar.open) {
      const timer = setTimeout(() => {
        dispatch(hideSnackbar());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [snackbar.open, dispatch]);

  return (
    <>
      {snackbar.open && (
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity={snackbar.severity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            <Typography
              variant="h5"
              color={(theme) => theme.palette.color.darkText}
            >
              {snackbar.message}
            </Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CustomSnackbar;
