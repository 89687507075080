import React from "react";

function GoogleIcon() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.90675 10.7211L3.33241 12.8652L1.2332 12.9096C0.605846 11.746 0.25 10.4147 0.25 8.99998C0.25 7.63193 0.582707 6.34184 1.17245 5.20589H1.1729L3.04179 5.54852L3.86047 7.40619C3.68912 7.90573 3.59573 8.44198 3.59573 8.99998C3.59579 9.60559 3.70549 10.1858 3.90675 10.7211Z"
        fill="#FBBB00"
      />
      <path
        d="M16.606 7.45877C16.7007 7.95783 16.7501 8.47323 16.7501 8.99997C16.7501 9.59062 16.688 10.1668 16.5697 10.7225C16.1681 12.6137 15.1187 14.265 13.665 15.4336L13.6646 15.4332L11.3106 15.3131L10.9775 13.2333C11.9421 12.6676 12.6959 11.7823 13.093 10.7225H8.68152V7.45877H16.606Z"
        fill="#518EF8"
      />
      <path
        d="M13.6645 15.4332L13.665 15.4337C12.2512 16.5701 10.4552 17.25 8.50014 17.25C5.35834 17.25 2.62678 15.494 1.23334 12.9097L3.90689 10.7212C4.60359 12.5806 6.39729 13.9043 8.50014 13.9043C9.404 13.9043 10.2508 13.6599 10.9774 13.2334L13.6645 15.4332Z"
        fill="#28B446"
      />
      <path
        d="M13.766 2.6493L11.0933 4.83736C10.3413 4.3673 9.45239 4.09576 8.50003 4.09576C6.34959 4.09576 4.52234 5.48012 3.86053 7.4062L1.17294 5.2059H1.17249C2.54553 2.55865 5.31154 0.75 8.50003 0.75C10.5018 0.75 12.3372 1.46304 13.766 2.6493Z"
        fill="#F14336"
      />
    </svg>
  );
}

export default GoogleIcon;
