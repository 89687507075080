import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';

const date = new Date();
const today = date.getDay();
const currentHour = date.getHours();

const styles = {
  containerBox: (
    today,
    eventDay,
    eventHour,
    currentHour,
    severityColors,
    eventImpact
  ) => ({
    position: 'relative',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '116px',
    width: '160px',
    gap: '0.5rem',
    border:
      today === eventDay && eventHour === currentHour ? '0.5px solid' : 'none',
    borderColor:
      today === eventDay ? severityColors[eventImpact] : 'transparent',
    cursor: 'pointer',
    background: (theme) => today === eventDay && eventHour === currentHour
    ? 'transparent'
    : theme.palette.color.bgGrey,
  }),
  circleIcon: (index, severity, severityColors, eventImpact) => ({
    fontSize: '10px',
    color:
      index < severity[eventImpact] ? severityColors[eventImpact] : 'default',
  }),
};

export default function CalendarItem({ event, eventDay, eventHour }) {

  // Define a mapping for severity levels to colors
  const severityColors = {
    Holiday: 'accent.blue',
    Low: 'accent.default',
    Medium: 'orange',
    High: 'red',
  };

  const severity = {
    Holiday: 1,
    Low: 1,
    Medium: 2,
    High: 3,
  };

  const impactColors = {
    Holiday: 'accent.blue',
    Low: 'accent.default',
    Medium: 'orange',
    High: 'red',
  };

  return (
    <Box
      sx={styles.containerBox(
        today,
        eventDay,
        eventHour,
        currentHour,
        severityColors,
        event.impact
      )}
      onClick={() => {
        // dispatch(selectEvent(event));
        // dispatch(openItemSidebar());
      }}
    >
      <img
        style={{
          position: 'absolute',
          left: -5,
          top: -5,
          width: '20px',
          height: '20px',
          borderRadius: '50%',
        }}
        src={require(`../../../../Icons/economic_calendar/countries/${event?.country?.toLowerCase()}/${event?.country?.toLowerCase()}.svg`)}
        alt={event?.country}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '0.3rem',
        }}
      >
        {Array.from({ length: 3 }).map((_, index) => (
          <CircleIcon
            key={index}
            sx={styles.circleIcon(
              index,
              severity,
              severityColors,
              event.impact
            )}
          />
        ))}
      </Box>
      <Typography
        variant='body2'
        color={'text.primary'}
        textAlign={'center'}
        padding={'0rem 0.2rem'}
      >
        {event.event}
      </Typography>
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <Typography variant='caption' color={'text.secondary'}>
          A: -
        </Typography>
        <Typography
          variant='caption'
          sx={{ color: impactColors[event.impact] }}
        >
          D: -
        </Typography>
      </Box>
    </Box>
  );
}

CalendarItem.propTypes = {
  index: PropTypes.number.isRequired,
  event: PropTypes.object.isRequired,
  eventDay: PropTypes.number.isRequired,
  eventHour: PropTypes.number.isRequired,
};