import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CustomCard from "../ui/CustomCard";
import ColorCopyIcon from "../Icons/ColorCopyIcon";
import LinkIcon from "../Icons/LinkIcon";
import XIcon from "../Icons/XIcon";
import YoutubeIcon from "../Icons/YoutubeIcon";
import LinkedInIcon from "../Icons/LinkedInIcon";
import InstaIcon from "../Icons/InstaIcon";

export default function AffilatePage() {
  return (
    <Box mx={2} mt={5} mb={3} minHeight={"calc(100vh - 200px)"}>
      <Box maxWidth={"1200px"} margin={"0 auto"}>
        <Stack direction={"column"} gap={2}>
          <CustomCard>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction={"column"} gap={1}>
                <Typography
                  variant="heading"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Referral URL
                </Typography>
                <Typography variant="subHeading">
                  Share your referral URL with audience to earn comission
                </Typography>
              </Stack>
              <Box position={"relative"}>
                <Input
                  sx={{ height: "48px", width: "500px" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <ColorCopyIcon />
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        "svg path": {
                          stroke: (theme) => theme.palette.color.darkText,
                        },
                      }}
                    >
                      <LinkIcon />
                    </InputAdornment>
                  }
                  className="custom_input"
                  placeholder=""
                />
              </Box>
            </Stack>
          </CustomCard>
          <CustomCard>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Stack direction={"column"} gap={1}>
                <Typography
                  variant="heading"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Referral URL generator
                </Typography>
                <Typography variant="subHeading">
                  Use this from to generate a refral link
                </Typography>
              </Stack>
              <Stack direction={"column"} gap={3}>
                <Stack direction={"column"} gap={1}>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    Page URL
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    sx={{
                      width: "500px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                    value={"https://firm.vuetra.com/join/partnerprogram"}
                  />
                </Stack>
                <Stack direction={"column"} gap={1}>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    Campaign name
                  </Typography>

                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    className="custom_input"
                    fullWidth
                    sx={{
                      width: "500px",
                      input: {
                        background: (theme) =>
                          theme.palette.color.blackShade + "! important",
                      },
                    }}
                    value={""}
                  />
                  <Typography variant="caption">
                    Enter an optional campaign to help track perfomance
                  </Typography>
                </Stack>
                <Stack direction={"column"} gap={1}>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    Generated referral URL
                  </Typography>
                  <Input
                    sx={{ height: "48px", width: "100%" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <ColorCopyIcon />
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment
                        position="start"
                        sx={{
                          "svg path": {
                            stroke: (theme) => theme.palette.color.darkText,
                          },
                        }}
                      >
                        <LinkIcon />
                      </InputAdornment>
                    }
                    className="custom_input"
                    placeholder=""
                    helperText="Share this URL with your audience"
                  />
                  <Typography variant="caption">
                    Share this URL with your audience
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={3}>
                  <IconButton
                    onClick={() => {}}
                    sx={{
                      "& svg path": {
                        fill: (theme) => theme.palette.color.darkText,
                      },
                    }}
                  >
                    <XIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {}}
                    sx={{
                      "& svg path:first-child": {
                        fill: (theme) => theme.palette.color.darkText,
                      },
                      "& svg path": {
                        fill: (theme) => theme.palette.color.blackShade,
                      },
                    }}
                  >
                    <YoutubeIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {}}
                    sx={{
                      "& svg path": {
                        fill: (theme) => theme.palette.color.darkText,
                      },
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {}}
                    sx={{
                      "& svg path": {
                        fill: (theme) => theme.palette.color.darkText,
                      },
                    }}
                  >
                    <InstaIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </CustomCard>
        </Stack>
      </Box>
    </Box>
  );
}
