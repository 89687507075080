import React from "react";

function LeftArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M5.09271 10.5834L1.0833 6.00003L5.09271 1.41671"
        stroke="#8A96A6"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.39739 6L11.9167 6"
        stroke="#8A96A6"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default LeftArrow;
