import React from "react";

export default function DollarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
    >
      <path
        d="M4.12145 13.2727V0.545454H4.93679V13.2727H4.12145ZM6.67188 4.4929C6.61884 4.02225 6.40009 3.65767 6.01562 3.39915C5.63116 3.13731 5.14725 3.00639 4.56392 3.00639C4.14631 3.00639 3.78504 3.07268 3.48011 3.20526C3.17519 3.33452 2.93821 3.51349 2.76918 3.74219C2.60346 3.96757 2.5206 4.22443 2.5206 4.51278C2.5206 4.75473 2.57694 4.96354 2.68963 5.1392C2.80563 5.31487 2.95644 5.46236 3.14205 5.58168C3.33097 5.69768 3.53314 5.79545 3.74858 5.875C3.96402 5.95123 4.17116 6.0142 4.37003 6.06392L5.36435 6.32244C5.68916 6.40199 6.02225 6.50971 6.36364 6.6456C6.70502 6.78149 7.02154 6.96046 7.31321 7.18253C7.60488 7.40459 7.8402 7.67969 8.01918 8.00781C8.20147 8.33594 8.29261 8.72869 8.29261 9.18608C8.29261 9.76278 8.14347 10.2749 7.84517 10.7223C7.55019 11.1697 7.12098 11.5227 6.55753 11.7812C5.9974 12.0398 5.3196 12.169 4.52415 12.169C3.76184 12.169 3.10227 12.0481 2.54545 11.8061C1.98864 11.5642 1.55279 11.2211 1.23793 10.777C0.923059 10.3295 0.749053 9.79924 0.715909 9.18608H2.2571C2.28693 9.55398 2.40625 9.86056 2.61506 10.1058C2.82718 10.3478 3.0973 10.5284 3.42543 10.6477C3.75687 10.7637 4.11979 10.8217 4.5142 10.8217C4.94839 10.8217 5.33452 10.7538 5.67259 10.6179C6.01397 10.4787 6.28243 10.2865 6.47798 10.0412C6.67353 9.79261 6.77131 9.5026 6.77131 9.17116C6.77131 8.86955 6.68513 8.62263 6.51278 8.4304C6.34375 8.23816 6.1134 8.07907 5.82173 7.95312C5.53338 7.82718 5.20691 7.71615 4.84233 7.62003L3.6392 7.2919C2.82386 7.06984 2.17756 6.74337 1.70028 6.3125C1.22633 5.88163 0.989347 5.31155 0.989347 4.60227C0.989347 4.01562 1.14844 3.50355 1.46662 3.06605C1.7848 2.62855 2.21567 2.28883 2.75923 2.04688C3.30279 1.80161 3.91596 1.67898 4.59872 1.67898C5.28812 1.67898 5.89631 1.79995 6.4233 2.0419C6.9536 2.28385 7.37121 2.61695 7.67614 3.04119C7.98106 3.46212 8.14015 3.94602 8.15341 4.4929H6.67188Z"
        fill="#4ED5FF"
      />
    </svg>
  );
}
