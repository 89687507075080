import React from "react";

export default function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M3 1H15C15.825 1 16.5 1.675 16.5 2.5V11.5C16.5 12.325 15.825 13 15 13H3C2.175 13 1.5 12.325 1.5 11.5V2.5C1.5 1.675 2.175 1 3 1Z"
        stroke="#8A96A6"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 2.50001L9 7.75001L1.5 2.50001"
        stroke="#8A96A6"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
