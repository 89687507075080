import React from "react";

export default function StripeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="101"
      height="42"
      viewBox="0 0 101 42"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100.75 21.6945C100.75 14.5833 97.3056 8.97224 90.7222 8.97224C84.1111 8.97224 80.1111 14.5833 80.1111 21.6389C80.1111 30 84.8333 34.2222 91.6111 34.2222C94.9167 34.2222 97.4167 33.4722 99.3056 32.4167V26.8611C97.4167 27.8056 95.25 28.3889 92.5 28.3889C89.8056 28.3889 87.4167 27.4445 87.1111 24.1667H100.694C100.694 23.8056 100.75 22.3611 100.75 21.6945ZM87.0278 19.0556C87.0278 15.9167 88.9444 14.6111 90.6944 14.6111C92.3889 14.6111 94.1945 15.9167 94.1945 19.0556H87.0278ZM69.3889 8.97224C66.6667 8.97224 64.9167 10.25 63.9444 11.1389L63.5833 9.41668H57.4722V41.8056L64.4167 40.3333L64.4444 32.4722C65.4444 33.1945 66.9167 34.2222 69.3611 34.2222C74.3333 34.2222 78.8611 30.2222 78.8611 21.4167C78.8333 13.3611 74.25 8.97224 69.3889 8.97224ZM67.7222 28.1111C66.0833 28.1111 65.1111 27.5278 64.4444 26.8056L64.4167 16.5C65.1389 15.6945 66.1389 15.1389 67.7222 15.1389C70.25 15.1389 72 17.9722 72 21.6111C72 25.3333 70.2778 28.1111 67.7222 28.1111ZM47.9167 7.33335L54.8889 5.83335V0.194458L47.9167 1.66668V7.33335ZM47.9167 9.44446H54.8889V33.75H47.9167V9.44446ZM40.4444 11.5L40 9.44446H34V33.75H40.9444V17.2778C42.5833 15.1389 45.3611 15.5278 46.2222 15.8333V9.44446C45.3333 9.11113 42.0833 8.50001 40.4444 11.5ZM26.5556 3.41668L19.7778 4.86112L19.75 27.1111C19.75 31.2222 22.8333 34.25 26.9444 34.25C29.2222 34.25 30.8889 33.8334 31.8056 33.3334V27.6945C30.9167 28.0556 26.5278 29.3333 26.5278 25.2222V15.3611H31.8056V9.44446H26.5278L26.5556 3.41668ZM7.77778 16.5C7.77778 15.4167 8.66667 15 10.1389 15C12.25 15 14.9167 15.6389 17.0278 16.7778V10.25C14.7222 9.33335 12.4444 8.97224 10.1389 8.97224C4.5 8.97224 0.75 11.9167 0.75 16.8333C0.75 24.5 11.3056 23.2778 11.3056 26.5833C11.3056 27.8611 10.1944 28.2778 8.63889 28.2778C6.33333 28.2778 3.38889 27.3333 1.05556 26.0556V32.6667C3.63889 33.7778 6.25 34.25 8.63889 34.25C14.4167 34.25 18.3889 31.3889 18.3889 26.4167C18.3611 18.1389 7.77778 19.6111 7.77778 16.5Z"
        fill="#635BFF"
      />
    </svg>
  );
}
