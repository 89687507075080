import React, { useState } from "react";
import CustomCard from "../ui/CustomCard";
import { Box, Button, Stack, Typography } from "@mui/material";
import PostIcon from "../Icons/PostIcon";
import ThumbIcon from "../Icons/ThumbIcon";
import LaptopIcon from "../Icons/LaptopIcon";
import MobileIcon from "../Icons/MobileIcon";
import CustomSwitch from "../ui/Switch";
import PasswordChangeModal from "./PasswordChangeModal";
import SuccessModal from "./SuccessModal";

export default function Security() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handlePassModalOpen = () => {
    setIsOpen(true);
  }

  const handlePassModalClose = () => {
    setIsOpen(false);
    setIsSuccessModalOpen(false);
  }

  return (
    <Box>
      <CustomCard>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant="h5"
            color={(theme) => theme.palette.color.darkText}
          >
            Change Password
          </Typography>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Typography variant="subtitle2">Last updated: 1h ago</Typography>
            <Button className="custom_color_btn customButton" onClick={handlePassModalOpen}>
              Change Password
            </Button>
          </Stack>
        </Stack>
      </CustomCard>
      <Box mt={5}>
        <Stack direction={"column"} gap={1}>
          <Typography
            variant="h3_semibold"
            color={(theme) => theme.palette.color.darkText}
          >
            2-factor authentication
          </Typography>
          <Typography variant="subHeading">
            Protect your trading data, use security layers against hackers. They
            do exist.
          </Typography>
        </Stack>
        <Box mt={3}>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <PostIcon />
              <Stack direction={"column"} gap={1}>
                <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Email authentication
                </Typography>
                <Typography variant="subHeading">
                  Retrieve codes from an authentication app on your device, like
                  Google Authenticator, Duo Mobile or Authy.
                </Typography>
              </Stack>
            </Stack>
            <CustomSwitch />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            sx={{
              mt: 3,
            }}
          >
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <ThumbIcon />
              <Stack direction={"column"} gap={1}>
                <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Authentication app
                </Typography>
                <Typography variant="subHeading">
                  Retrieve codes from an authentication app on your device, like
                  Google Authenticator, Duo Mobile or Authy.
                </Typography>
              </Stack>
            </Stack>
            <CustomSwitch />
          </Stack>
        </Box>
      </Box>
      <Box mt={4}>
        <Stack>
          <Typography
            variant="h3_semibold"
            color={(theme) => theme.palette.color.darkText}
          >
            Session History
          </Typography>
        </Stack>
        <Box mt={3}>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            sx={{
              mb: 3,
            }}
          >
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <LaptopIcon />

              <Stack direction={"column"} gap={1}>
                <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Mac, Mac OS X 10.15.7
                </Typography>
                <Typography variant="subHeading">
                  Arnhem, The Netherlands · 82.172.181.198 · TV Desktop 2.7.7
                </Typography>
              </Stack>
            </Stack>
            <Button className="customButton success_varaint">Active now</Button>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
              <MobileIcon />
              <Stack direction={"column"} gap={1}>
                <Typography
                  variant="h5"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Iphone, IOS 13.01.5
                </Typography>
                <Typography variant="subHeading">
                  Arnhem, The Netherlands · 82.172.181.198 · TV Desktop 2.7.7
                </Typography>
              </Stack>
            </Stack>
            <Button className="customButton see_all_btn">Logged out</Button>
          </Stack>
        </Box>
        <Box mt={4}>
          <Button className="customButton danger_varaint">Terminate all sessions</Button>
        </Box>
      </Box>
      {isOpen && <PasswordChangeModal isOpen={isOpen} onCloseModal={handlePassModalClose}/>}
      <SuccessModal isOpen={isSuccessModalOpen} onCloseModal={() => {}}></SuccessModal>
    </Box>
  );
}
