import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCard from "../../ui/CustomCard";
import InfoIcon from "../../Icons/InfoIcon";
import UpAngleArrowIcon from "../../Icons/UpAngleArrowIcon";
import CalendarIcon from "../../Icons/CalendarIcon";
import DollarCircleIcon from "../../Icons/DollarCircleIcon";
import SettingIcon from "../../Icons/SettingIcon";
import CylinderIcon from "../../Icons/CylinderIcon";
// import QuestionIcon from "../../Icons/questionIcon";
// import DonutChart from "./DonutChart";
import CustomProgressBar from "./ProgressBar";
import ReviewIcon from "../../Icons/ReviewIcons";
import { Link, useParams } from "react-router-dom";
import CrossSquareIcon from "../../Icons/CrossSquareIcon";
// import CandleStickChart from "./CandleStickChart";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccountAccess,
  fetchAccountDetails,
  fetchAccountStatistics,
  fetchBalanceHistory,
} from "../../../slices/accounts/myaccounts";
// import { fetchClosedPositions } from "../../../slices/accounts/trade";
import dayjs from "dayjs";
import { getTimeFromDate } from "../../../utils/helpers";
import ChartComponent from "../../Home/ChartComponent";
// import CandleStickChart from "./CandleStickChart";

export default function Overview() {
  const [timeLeft, setTimeLeft] = useState("");
  const {
    singleAccountDetails,
    accountStatistics,
    balanceHistoryData,
    accountDetails,
  } = useSelector((state) => state.accounts);

  console.log("accountDetails", accountDetails);

  useEffect(() => {
    const updateTimer = (expiry) => {
      const now = dayjs();
      const diff = expiry?.diff(now, "second"); // difference in seconds

      if (diff <= 0) {
        setTimeLeft("Expired");
        return;
      }

      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);

      setTimeLeft(`${hours}H ${minutes}M`);
    };
    if (singleAccountDetails) {
      const expiry = dayjs(singleAccountDetails.daily_drawdown_expiry);
      updateTimer(expiry); // initial call
    }
    const timerId = setInterval(updateTimer, 1000 * 60); // update every minute

    return () => clearInterval(timerId); // cleanup on unmount
  }, [singleAccountDetails]);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchAccountDetails(params.id));
      dispatch(fetchAccountStatistics(params.id));
      dispatch(fetchBalanceHistory(params?.id));
      dispatch(fetchAccountAccess(params?.id));
    }
  }, [dispatch, params]);

  const initialBalance = parseFloat(singleAccountDetails?.initial_balance); // From initial_balance field
  const globalDrawdownThreshold = parseFloat(
    singleAccountDetails?.phase.maximum_drawdown
  ); // From global_drawdown_threshold field
  const dailyDrawdownLimit = parseFloat(
    singleAccountDetails?.phase.daily_drawdown
  ); // From daily_drawdown field

  // Calculate Max Permitted Loss (Global Drawdown)
  const maxPermittedLoss = initialBalance * globalDrawdownThreshold;

  // Calculate Max Permitted Daily Loss (Daily Drawdown)
  const maxPermittedDailyLoss = initialBalance * dailyDrawdownLimit;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={9}>
          <Box mb={4}>
            {singleAccountDetails?.status !== "ACTIVE" && (
              <CustomCard customPadding="24px">
                <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
                  <CrossSquareIcon />
                  <Stack direction={"column"} gap={0.5}>
                    <Typography
                      variant="heading_500"
                      color={(theme) => theme.palette.color.darkText}
                    >
                      Account inactive
                    </Typography>
                    <Typography variant="subtitle2_500">
                      This account is inactive since of violating our trading
                      rules.
                    </Typography>
                    <Box
                      mt={1.5}
                      sx={{
                        a: {
                          color: (theme) => theme.palette.color.darkText,
                        },
                      }}
                    >
                      <Link>
                        <Typography
                          variant="subtitle2_500"
                          color={(theme) => theme.palette.color.darkText}
                        >
                          See here your summary.
                        </Typography>
                      </Link>
                    </Box>
                  </Stack>
                </Stack>
              </CustomCard>
            )}
          </Box>
          <Typography
            variant="heading"
            color={(theme) => theme.palette.color.darkText}
          >
            Trade Growth
          </Typography>
          {balanceHistoryData && (
            <ChartComponent balanceHistoryData={balanceHistoryData} />
          )}
          {/* <CandleStickChart /> */}
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomCard customPadding="16px">
            <Box
              sx={{
                background: (theme) => theme.palette.color.lightShade,
                borderRadius: "8px 8px 0 0",
                p: "13px 8px",
              }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  variant="caption2"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Status
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Box
                    sx={{
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      background: (theme) => theme.palette.color.skyColor,
                    }}
                  />
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.skyColor}
                  >
                    {singleAccountDetails?.status}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Box mt={2} flexDirection={"column"} display={"flex"} gap={2}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <CalendarIcon />
                  <Typography variant="subHeading">Start date</Typography>
                </Stack>
                <Stack direction={"column"} gap={0.6}>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    {dayjs(singleAccountDetails?.start_date).format(
                      "MMMM D, YYYY"
                    )}
                  </Typography>
                  <Typography variant="subtitle2_500" align="end">
                    {getTimeFromDate(singleAccountDetails?.start_date)}
                  </Typography>
                </Stack>
              </Stack>
              {singleAccountDetails?.end_date && (
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <CalendarIcon />
                    <Typography variant="subHeading">End date</Typography>
                  </Stack>
                  <Stack direction={"column"} gap={0.6}>
                    <Typography
                      variant="heading_500"
                      color={(theme) => theme.palette.color.darkText}
                    >
                      April 12,2024
                    </Typography>
                    <Typography variant="subtitle2_500" align="end">
                      11:05:37
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Box>
            <Divider
              sx={{
                my: 3,
                background: (theme) => theme.palette.color.strockborder,
                borderImage:
                  "linear-gradient(to right, rgba(33, 37, 41, 0), rgba(33, 37, 41, 1), rgba(33, 37, 41, 0)) 1",
              }}
            />
            <Box display={"flex"} flexDirection={"column"} gap={2.5}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <DollarCircleIcon />
                  <Typography variant="subHeading">Init. balance</Typography>
                </Stack>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  ${singleAccountDetails?.initial_balance}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <SettingIcon />
                  <Typography variant="subHeading">Platform</Typography>
                </Stack>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  {accountDetails?.platform}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <CylinderIcon />
                  <Typography variant="subHeading">Server</Typography>
                </Stack>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  {accountDetails?.server.split("//")[1].split(".")[0]}
                </Typography>
              </Stack>
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Typography
          variant="heading"
          color={(theme) => theme.palette.color.darkText}
        >
          Trading objectives
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "4px",
          }}
        >
          <Grid item md={9}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Typography variant="heading_500">Overall Loss</Typography>
                    <InfoIcon />
                  </Stack>
                  {/* <Typography
                    variant="heading_500"
                    sx={{
                      span: {
                        color: "#C04857",
                      },
                    }}
                  >
                    <span>3.5</span>/5%
                  </Typography> */}
                </Stack>
                <CustomProgressBar color={"#C04857"} />
                <Typography variant="heading_500">
                  -${maxPermittedLoss}
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={9}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Typography variant="heading_500">Daily Loss</Typography>
                    <InfoIcon />
                  </Stack>
                  {/* <Typography
                    variant="heading_500"
                    sx={{
                      span: {
                        color: "#C04857",
                      },
                    }}
                  >
                    <span>3.5</span>/5%
                  </Typography> */}
                </Stack>
                <CustomProgressBar color="#C04857" />
                <Typography variant="heading_500">
                  - ${maxPermittedDailyLoss}
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={9}>
            <CustomCard customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Typography variant="heading_500">Profit target</Typography>
                    <InfoIcon />
                  </Stack>
                  {/* <Typography
                    variant="heading_500"
                    sx={{
                      span: {
                        color: "#00A3FF",
                      },
                    }}
                  >
                    <span>6</span>/5%
                  </Typography> */}
                </Stack>
                <CustomProgressBar color={"#00A3FF"} />
                <Typography variant="heading_500">
                  ${singleAccountDetails?.phase.target_profit}
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={0.5}
              >
                <Typography variant="heading_500">1.4</Typography>
                <UpAngleArrowIcon />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Box>
                  <Stack
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Typography variant="heading_500">Balance</Typography>
                  </Stack>
                  <Typography variant="heading2">
                    +${singleAccountDetails?.live_account_balance}
                  </Typography>
                </Box>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={0.5}
              >
                <Typography variant="heading_500">1.4</Typography>
                <UpAngleArrowIcon />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
              >
                <Box>
                  <Stack
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Typography variant="heading_500">Equity</Typography>
                  </Stack>
                  <Typography variant="heading2">
                    +${singleAccountDetails?.live_account_equity}
                  </Typography>
                </Box>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard customPadding="16px">
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={0.5}
              >
                <ReviewIcon />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                sx={{
                  mt: "-24px",
                }}
              >
                <Box>
                  <Stack
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Typography variant="heading_500">
                      Daily Loss Revive
                    </Typography>
                  </Stack>
                  <Typography variant="heading2">{timeLeft}</Typography>
                </Box>
              </Stack>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={2.25}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Win Rate</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  {parseFloat(accountStatistics?.win_rate).toFixed(2)}%
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          {/* <Grid item md={2.25}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Efficiency</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  60%
                </Typography>
              </Stack>
            </CustomCard>
          </Grid> */}
          {/* <Grid item md={2.25}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Max Con. Losses</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  6
                </Typography>
              </Stack>
            </CustomCard>
          </Grid> */}
          {/* <Grid item md={2.25}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">
                    Max Con. Winners
                  </Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  4
                </Typography>
              </Stack>
            </CustomCard>
          </Grid> */}
        </Grid>
      </Box>
      {/* <Box mt={2}>
        <Typography
          variant="heading"
          color={(theme) => theme.palette.color.darkText}
        >
          Performance insights
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "4px",
          }}
        >
          <Grid item md={5}>
            <CustomCard
              className="transparent_bg"
              customPadding="16px 16px 40px"
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={6}
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                    sx={{
                      "& svg path": {
                        stroke: (theme) => theme.palette.color.darkText,
                      },
                    }}
                  >
                    <Typography
                      variant="heading_500"
                      color={(theme) => theme.palette.color.darkText}
                    >
                      Order Type Allocation
                    </Typography>
                    <QuestionIcon />
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          background: "#754FFF",
                        }}
                      />
                      <Typography>Market</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          background: "#15191C",
                        }}
                      />
                      <Typography>Limit</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          background: "#4ED5FF",
                        }}
                      />
                      <Typography>Stop</Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <DonutChart />
              </Box>
            </CustomCard>
          </Grid>
          <Grid
            item
            md={7}
            sx={{
              ".symbol-card": {
                position: "relative",
                height: "100%",
              },
            }}
          >
            <CustomCard customPadding="16px" className="symbol-card">
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  sx={{
                    "& svg path": {
                      stroke: (theme) => theme.palette.color.darkText,
                    },
                  }}
                >
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    Symbol allocation
                  </Typography>
                  <QuestionIcon />
                </Stack>
                <Box>
                  <Box
                    sx={{
                      background: "rgb(78 213 255 / 20%)",
                      padding: "26px",
                      borderRadius: "50%",
                      width: "130px",
                      height: "130px",
                      position: "absolute",
                      zIndex: "9",
                      left: "30%",
                      top: "90px",
                      border: "2px solid",
                      borderColor: (theme) => theme.palette.color.skyColor,
                    }}
                  >
                    <Box
                      sx={{
                        background: (theme) => theme.palette.color.bg,
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="subHeaddingMedium">10%</Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          left: "-70%",
                          top: "10%",
                        }}
                      >
                        <Typography variant="heading_500">EURUSD</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "rgba(117 79 255/20%)",
                      padding: "26px",
                      borderRadius: "50%",
                      width: "140px",
                      height: "140px",
                      position: "absolute",
                      left: "35%",
                      bottom: "19%",
                      zIndex: "5",
                      border: "2px solid",
                      borderColor: (theme) => theme.palette.color.purple,
                    }}
                  >
                    <Box
                      sx={{
                        background: (theme) => theme.palette.color.bg,
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="subHeaddingMedium">40%</Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          left: "105%",
                          bottom: "15px",
                        }}
                      >
                        <Typography variant="heading_500">EURUSD</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "rgba(38 40 45/20%)",
                      padding: "26px",
                      borderRadius: "50%",
                      width: "200px",
                      height: "200px",
                      position: "absolute",
                      left: "40%",
                      top: "18%",
                      border: "2px solid",
                      borderColor: (theme) => theme.palette.color.lightgray,
                    }}
                  >
                    <Box
                      sx={{
                        background: (theme) => theme.palette.color.bg,
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="subHeaddingMedium">50%</Typography>
                      <Box
                        sx={{
                          position: "absolute",
                          right: "-35%",
                          top: "10px",
                        }}
                      >
                        <Typography variant="heading_500">EURUSD</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CustomCard>
          </Grid>
        </Grid> 
      </Box> */}
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Worst trade</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  {accountStatistics?.avg_losing_trade}
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Balance</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  ${singleAccountDetails?.initial_balance}
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">No. of trades</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  {accountStatistics?.total_trades}
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Lots</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  {parseFloat(accountStatistics?.total_lots).toFixed(2)}
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
      {/* <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Worst trade</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  -$10.10
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Balance</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  $60.10
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">No. of trades</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  140
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard className="transparent_bg" customPadding="16px">
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Lots</Typography>
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  32
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
        </Grid>
      </Box> */}
      {/* <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <CustomCard>
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Best session</Typography>
                  <InfoIcon />
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  EURUSD
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard>
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Worst session</Typography>
                  <InfoIcon />
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  USDJPY
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard>
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Best day</Typography>
                  <InfoIcon />
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Wednesday
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={3}>
            <CustomCard>
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Worst day</Typography>
                  <InfoIcon />
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Monday
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
        </Grid>
      </Box> */}
      {/* <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <CustomCard>
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Best session</Typography>
                  <InfoIcon />
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  London
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
          <Grid item md={6}>
            <CustomCard>
              <Stack direction={"column"} gap={2}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography variant="heading_500">Worst session</Typography>
                  <InfoIcon />
                </Stack>
                <Typography
                  variant="h2_600"
                  color={(theme) => theme.palette.color.darkText}
                >
                  New york
                </Typography>
              </Stack>
            </CustomCard>
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
}
