import {
  alpha,
  Box,
  Button,
  IconButton,
  // MenuItem,
  // MenuList,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomTabs from "../components/ui/CustomTabs";
import Certificates from "../components/Accounts/AccountDetails.jsx/Certificates";
import CustomCalendar from "../components/Accounts/AccountDetails.jsx/CustomCalendar";
import KeyIcon from "../components/Icons/KeyIcon";
import Position from "../components/Accounts/AccountDetails.jsx/Position";
import Overview from "../components/Accounts/AccountDetails.jsx/Overview";
import ShareIcon from "../components/Icons/ShareIcon";
import CustomSwitch from "../components/ui/Switch";
import ColorCopyIcon from "../components/Icons/ColorCopyIcon";
import AccountCredentialModal from "../components/Accounts/AccountDetails.jsx/AccountCredentialModal";
import { copyContent } from "../utils/helpers";
// import { FaChevronDown } from "react-icons/fa";
// import CheckIcon from "../components/Icons/Check";
import { useParams } from "react-router-dom";

const tabsList = [
  {
    icon: "",
    label: "Overview",
  },
  {
    icon: "",
    label: "Positions",
  },
  {
    icon: "",
    label: "Calendar view",
  },
  {
    icon: "",
    label: "Certificates",
  },
];

export default function AccountDetail() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [anchorTimeEl, setAnchorTimeEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedTime, setSelectedTime] = useState("All time");
  
  const params = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedTime("All time");
  };

  // const handleTimeClick = (event) => {
  //   setAnchorTimeEl(event.currentTarget);
  // };

  // const handleTimeClose = () => {
  //   setAnchorTimeEl(null);
  // };

  const open = Boolean(anchorEl);
  // const openTimeList = Boolean(anchorTimeEl);

  const id = open ? "simple-popover" : undefined;

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  // const timeList = [
  //   {
  //     label: "All time",
  //     onClick: () => {},
  //   },
  //   {
  //     label: "This Week",
  //     onClick: () => {},
  //   },
  //   {
  //     label: "This month",
  //     onClick: () => {},
  //   },
  //   {
  //     label: "This year",
  //     onClick: () => {},
  //   },
  //   {
  //     label: "Custom date",
  //     onClick: () => {},
  //   },
  // ];

  return (
    <Box mx={2} mt={4} mb={3} minHeight={"calc(100vh - 200px)"}>
      <Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CustomTabs
            onChange={handleTabChange}
            value={selectedTab}
            tabs={tabsList}
          />
          <Box width={"32%"}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {/* <Button
                endIcon={
                  <FaChevronDown
                    style={{
                      fontSize: "10px",
                    }}
                  />
                }
                sx={{
                  background: "transparent",
                  color: (theme) => theme.palette.color.secondary,
                  borderRadius: "50px",
                  bgcolor: "transparent",
                  border: (theme) =>
                    `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                  ":hover": {
                    bgcolor: (theme) => theme.palette.color.bg5,
                    border: (theme) =>
                      `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                  },
                  fontSize: "11.5px",
                  padding: "6px 12px",
                  textTransform: "capitalize",
                  svg: {
                    width: 18,
                  },
                  "svg path": {
                    stroke: (theme) => theme.palette.color.secondary,
                  },
                }}
                onClick={(e) => handleTimeClick(e)}
              >
                All time
              </Button>
              <Popover
                open={openTimeList}
                anchorEl={anchorTimeEl}
                onClose={handleTimeClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                className="custom-profile-popover"
              >
                <MenuList>
                  {timeList.map((time, index) => {
                    return (
                      <MenuItem
                        sx={{
                          background: (theme) =>
                            selectedTime === time.label
                              ? theme.palette.color.strockborder
                              : "transparent",
                          borderRadius: "12px",
                        }}
                        key={index}
                        onClick={() => time.onClick()}
                      >
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          {selectedTime === time.label && <CheckIcon />}
                          <Typography
                            variant="subHeading"
                            color={(theme) => theme.palette.color.secondary}
                          >
                            {time.label}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Popover> */}
              <Button
                startIcon={<KeyIcon />}
                sx={{
                  background: "transparent",
                  color: (theme) => theme.palette.color.secondary,
                  borderRadius: "50px",
                  bgcolor: "transparent",
                  border: (theme) =>
                    `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                  ":hover": {
                    bgcolor: (theme) => theme.palette.color.bg5,
                    border: (theme) =>
                      `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                  },
                  fontSize: "11.5px",
                  padding: "6px 12px",
                  textTransform: "capitalize",
                  svg: {
                    width: 18,
                  },
                  "svg path": {
                    stroke: (theme) => theme.palette.color.secondary,
                  },
                }}
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                Credentials
              </Button>
              <Button
                startIcon={<ShareIcon />}
                sx={{
                  background: "transparent",
                  color: (theme) => theme.palette.color.secondary,
                  borderRadius: "50px",
                  bgcolor: "transparent",
                  border: (theme) =>
                    `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                  ":hover": {
                    bgcolor: (theme) => theme.palette.color.bg5,
                    border: (theme) =>
                      `1px solid ${alpha(theme.palette.color.secondary, 0.15)}`,
                  },
                  fontSize: "11.5px",
                  padding: "6px 12px",
                  textTransform: "capitalize",
                  svg: {
                    width: 18,
                  },
                  "svg path": {
                    stroke: (theme) => theme.palette.color.secondary,
                  },
                }}
                onClick={handleClick}
              >
                Share
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                className="custom-profile-popover"
              >
                <Box width={400}>
                  <Box
                    sx={{
                      padding: "10px 8px",
                      borderBottom: "1px solid",
                      borderColor: (theme) => theme.palette.color.strockborder,
                    }}
                  >
                    <Typography
                      variant="subHeading"
                      color={(theme) => theme.palette.color.darkText}
                    >
                      Share
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: "10px 8px",
                    }}
                  >
                    <Stack direction={"column"} gap={2}>
                      <Box position={"relative"}>
                        <TextField
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          className="custom_input"
                          fullWidth
                          value={"https://firm.vuetra.com/join/partnerprogram"}
                        />
                        <Box position={"absolute"} right={"8px"} top={"15%"}>
                          <IconButton
                            onClick={() =>
                              copyContent(
                                "https://firm.vuetra.com/join/partnerprogram"
                              )
                            }
                          >
                            <ColorCopyIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                        <Typography variant="subHeading">
                          Hide balance
                        </Typography>
                        <CustomSwitch />
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Popover>
            </Stack>
          </Box>
        </Stack>
        <Box mt={4}>
          {selectedTab === 0 && <Overview />}
          {selectedTab === 1 && <Position />}
          {selectedTab === 2 && <CustomCalendar />}
          {selectedTab === 3 && <Certificates />}
        </Box>
      </Box>
      {isModalOpen && (
        <AccountCredentialModal
          isOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          accountId={params?.id}
        />
      )}
    </Box>
  );
}
