import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";

export default function SuccessModal({ isOpen, onCloseModal }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    width: "100%",
    bgcolor: "background.paper",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.strockborder,
    boxShadow: 24,
    borderRadius: "16px",
    height: 500,
  };
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Stack direction={"column"} gap={1} alignItems={"center"}>
            <Typography variant="h2" color={(theme) => theme.palette.color.darkText}>Congratulations 🎉</Typography>
            <Typography variant="subHeaddingRegular">Your Password successfully changed</Typography>
            <Button
              className="custom_color_btn customButton"
              onClick={onCloseModal}
              sx={{
                mt: 2
              }}
            >
              Done
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
