import * as React from "react";
import {
  Avatar,
  Box,
  Divider,
  Popover,
  Stack,
  Typography,
  MenuList,
  MenuItem,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Avatar1 from "../../../assets/avatar2.png";
import { useDispatch, useSelector } from "react-redux";
import ThemeIcon from "../../Icons/ThemeIcon";
import UserIcon from "../../Icons/UserIcon";
import PartnerIcon from "../../Icons/PartnerIcon";
import LogoutIcon from "../../Icons/LogoutIcon";
import EvaluatIcon from "../../Icons/EvaluatIcon";
import CheckIcon from "../../Icons/Check";
import { toggleTheme } from "../../../slices/themeSlice";
import { logoutUser } from "../../../slices/auth/authSlice";

export default function ProfileSection() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const isExpanded = useSelector((state) => state.sidebar.mode === "expanded");
  const themeMode = useSelector((state) => state.theme.mode);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSubMenuAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const items = [
    {
      label: "Theme",
      link: "",
      icon: <ThemeIcon />,
      arrow: true,
      onClick: (e) => handleSubMenuClick(e),
      children: [
        {
          label: "Light",
          onClick: () => dispatch(toggleTheme()),
        },
        {
          label: "Dark",
          onClick: () => dispatch(toggleTheme()),
        },
        {
          label: "Based on system",
          onClick: () => dispatch(toggleTheme()),
        },
      ],
    },
    {
      label: "Account Settings",
      onClick: () => {
        navigate("/settings");
        setAnchorEl(null);
      },
      icon: <UserIcon />,
    },
    {
      label: "Partner program",
      onClick: () => {
        navigate("/partner");
        setAnchorEl(null);
      },
      icon: <PartnerIcon />,
    },
    {
      divider: true,
    },
    {
      label: "New evaluation",
      icon: <EvaluatIcon />,
      onClick: () => {
        navigate("/new-evaluation");
        setAnchorEl(null);
      },
    },
    {
      label: "Logout",
      icon: <LogoutIcon />,
      onClick: async () => {
        await dispatch(logoutUser())
        navigate("/login");
      },
    },
  ];

  return (
    <>
      <Stack
        onClick={handleClick}
        sx={{
          width: "100%",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              color: (theme) => theme.palette.color.darkText,
              m: "8px",
              width: "100%",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Avatar alt="Trevor Henderson" variant="rounded" src={Avatar1} />
            {isExpanded && (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  width: "65%",
                }}
              >
                <Box>
                  <Typography>Name</Typography>
                  <Typography>@username</Typography>
                </Box>
                <Box
                  sx={{
                    width: "9px",
                    height: "9px",
                    borderRadius: "50%",
                    background: "#21FF90",
                  }}
                />
              </Stack>
            )}
          </Box>
        </Box>
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="custom-profile-popover"
      >
        <Box sx={{ width: "260px" }}>
          <Box
            sx={{
              display: "flex",
              color: (theme) => theme.palette.color.darkText,
              width: "100%",
              alignItems: "center",
              gap: "5px",
              background: (theme) => theme.palette.color.lightgray,
              padding: "8px",
              borderRadius: "8px",
              mb: 0.5,
            }}
          >
            <Avatar alt="Trevor Henderson" src={Avatar1} />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                width: "100%",
              }}
            >
              <Box>
                <Typography>Name</Typography>
                <Typography>@username</Typography>
              </Box>
              <Box
                sx={{
                  width: "9px",
                  height: "9px",
                  borderRadius: "50%",
                  background: "#21FF90",
                }}
              />
            </Stack>
          </Box>
          <Divider />
          <Stack sx={{ mt: "12px" }} spacing={1}>
            {items.map((item, index) =>
              item.divider ? (
                <Box sx={{ p: "8px", width: "100%" }} key={index}>
                  <Divider />
                </Box>
              ) : (
                <Box
                  sx={{
                    px: "7px",
                    py: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    transition: "all 0.5s ease-in-out",
                    color: (theme) => theme.palette.color.secondary,
                    cursor: "pointer",
                    position: "relative",
                    ":hover": {
                      pr: "0px",
                      color: (theme) => theme.palette.color.tertiary,
                    },
                    "& svg path": {
                      fill: (theme) => item.label !== "New evaluation" ? theme.palette.color.darkText : "transparent",
                      stroke: (theme) => item.label !== "New evaluation" ? theme.palette.color.darkText : theme.palette.color.skyColor,
                    }
                  }}
                  key={index}
                  onClick={(e) => {
                    if (item.onClick) {
                      item.onClick(e);
                    }
                  }}
                >
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    {item.icon}
                    <Typography
                      variant="subHeading"
                      color={(theme) =>
                        item.label === "New evaluation"
                          ? theme.palette.color.skyColor
                          : theme.palette.color.secondary
                      }
                    >
                      {item.label}
                    </Typography>
                  </Stack>
                  {item.arrow && (
                    <ChevronRight sx={{ fontSize: "16px", color: "inherit" }} />
                  )}
                  {item.children?.length > 0 && (
                    <Popover
                      open={Boolean(subMenuAnchorEl)}
                      anchorEl={subMenuAnchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      className="custom-profile-popover"
                    >
                      <MenuList>
                        {item.children.map((child, index) => {
                          return (
                            <MenuItem
                              sx={{
                                background: (theme) =>
                                  themeMode === child.label.toLowerCase()
                                    ? theme.palette.color.lightgray
                                    : "transparent",
                                borderRadius: "12px",
                                mt: 1
                              }}
                              key={index}
                              onClick={() => child.onClick()}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={1}
                              >
                                {themeMode === child.label.toLowerCase() && (
                                  <CheckIcon />
                                )}
                                <Typography
                                  variant="subHeading"
                                  color={(theme) =>
                                    theme.palette.color.secondary
                                  }
                                >
                                  {child.label}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Popover>
                  )}
                </Box>
              )
            )}
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
