import React from "react";

export default function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M17.6457 17.6459L14.2082 14.2084M4.354 10.0834C4.354 6.91922 6.91904 4.35419 10.0832 4.35419C13.2473 4.35419 15.8123 6.91922 15.8123 10.0834C15.8123 13.2475 13.2473 15.8125 10.0832 15.8125C6.91904 15.8125 4.354 13.2475 4.354 10.0834Z"
        stroke="#8A96A6"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
