import { Box } from "@mui/material";
import React from "react";
import CustomTabs from "../components/ui/CustomTabs";
import Profile from "../components/Settings/Profile";
import Security from "../components/Settings/Security";
import Verification from "../components/Settings/Verification";
import Integration from "../components/Settings/Integration";
import Billing from "../components/Settings/Billing";

const tabsList = [
  {
    label: "My profile",
  },
  {
    label: "Security",
  },
  {
    label: "Verification",
  },
  {
    label: "Integration",
  },
  {
    label: "Billing",
  },
];

export default function Settings() {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      mx={2}
      mt={5}
      mb={3}
      minHeight={"calc(100vh - 200px)"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <CustomTabs
          onChange={handleTabChange}
          value={selectedTab}
          tabs={tabsList}
        />
        <Box mt={6} maxWidth={"1200px"} marginX={"auto"}>
          {
            selectedTab === 0 && <Profile />
          }
          {
            selectedTab === 1 && <Security />
          }
          {
            selectedTab === 2 && <Verification />
          }
          {
            selectedTab === 3 && <Integration />
          }
          {
            selectedTab === 4 && <Billing />
          }
        </Box>
      </Box>
    </Box>
  );
}
