import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ to }) => {
  const isTokenAvailable = useSelector((state) => state.auth.token);
  return !isTokenAvailable ? <Outlet /> : <Navigate to={to} />;
};

export default PublicRoute;
