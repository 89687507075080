import React from "react";
import ReactApexChart from "react-apexcharts";

export default function Linechart() {
    const options = {
        chart: {
          type: 'line',
          height: 350,
          toolbar: {
            show: false, // Disable the entire toolbar
          },
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        },
      };

  const series = [
    {
      name: "Sample Data",
      data: [10, 41, 35, 51, 49],
    },
  ];
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={100}
    />
  );
}
