import React, { useState } from "react";
import CustomCard from "../ui/CustomCard";
import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ChartIcon from "../Icons/ChartIcon";
import { Link, useNavigate } from "react-router-dom";
import KeyIcon from "../Icons/KeyIcon";
import dayjs from "dayjs";
import AccountCredentialModal from "../Accounts/AccountDetails.jsx/AccountCredentialModal";
import { calculatePercentage } from "../../utils/helpers";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 16,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#754FFF",
  },
}));

export default function ActiveAccountDetails({ account }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const finalPercentage = calculatePercentage(
    account?.profit_loss,
    account?.initial_balance,
    account?.phase?.target_profit
  );

  return (
    <CustomCard customPadding={"16px"}>
      <Box
        flexDirection={"column"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        sx={{
          ".image_div": {
            position: "absolute",
            right: "0",
            bottom: "0",
          },
        }}
      >
        <Box
          width={"100%"}
          sx={{
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
            pb: 2,
            mb: 3,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              flexDirection={"row"}
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#21FF90",
                  borderRadius: "50%",
                  height: "6px",
                  width: "6px",
                }}
              />
              <Typography
                variant="h5"
                color={(theme) => theme.palette.color.darkText}
                sx={{
                  a: {
                    textDecoration: "none",
                    color: (theme) => theme.palette.color.darkText,
                  },
                }}
              >
                <Link to={`/account/${account.id}`}>#{account.id}</Link>
              </Typography>
            </Stack>
            <ChartIcon />
          </Stack>
          <Typography variant="subtitle2">Challenge name</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stack
              flexDirection={"row"}
              sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
            >
              <Typography variant="subHeading">Start Date</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                {dayjs(account?.startDate).format("MMMM D, YYYY")}
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
            >
              <Typography variant="subHeading">Phase</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                {account?.phase.title}
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
            >
              <Typography variant="subHeading">Balance</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                ${account?.initial_balance}
              </Typography>
            </Stack>
          </Box>
          {!account?.live && (
            <Box mt={6} mb={2.5}>
              <BorderLinearProgress
                variant="determinate"
                value={finalPercentage}
              />
              <Stack
                flexDirection={"row"}
                sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
              >
                <Typography variant="subHeading">
                  ${account?.initial_balance}
                </Typography>
                <Typography variant="body1">{finalPercentage.toFixed(2)}%</Typography>
              </Stack>
            </Box>
          )}
        </Box>
        <Stack
          direction={"row"}
          gap={1}
          alignItems={"center"}
          sx={{
            width: "100%",
          }}
        >
          <Button
            className="customButton see_all_btn metrix_btn"
            fullWidth
            onClick={() => navigate(`/account/${account?.id}`)}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <ChartIcon />
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.skyColor}
              >
                Metrics
              </Typography>
            </Stack>
          </Button>
          <Button
            startIcon={<KeyIcon />}
            className="customButton outline-btn"
            onClick={() => setIsModalOpen(true)}
          >
            Credentials
          </Button>
        </Stack>
      </Box>
      {isModalOpen && (
        <AccountCredentialModal
          isOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          accountId={account.id}
        />
      )}
    </CustomCard>
  );
}
