import React from "react";

export default function DarkModeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.45454 6.37447C9.45454 10.89 13.1129 14.5498 17.6265 14.5498C18.4528 14.5498 19.2491 14.4281 20 14.2001C18.9531 17.6446 15.7778 20 12.1785 20C8.12614 20.0027 4.68393 17.0351 4.08919 13.0246C3.49445 9.0151 5.92698 5.17452 9.80502 4C9.57167 4.7703 9.45363 5.56966 9.45454 6.37447Z"
        stroke="#8A96A6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
