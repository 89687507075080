import { Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import TradingInfo from "../components/Evaluation/TradingInfo";
import DollarIcon from "../components/Icons/DollarIcon";
import EuroIcon from "../components/Icons/EuroIcon";
import PoundIcon from "../components/Icons/PoundIcon";
import { accountBalance } from "../static/EvaluationData";
import { useNavigate } from "react-router-dom";

export default function Evaluation() {
  const [selectedBal, setSelectedBal] = useState("10.000");
  const [selectedCurrency, setSelectedCurrency] = useState("dollar");
  const [selectedType, setSelectedType] = useState("step1");

  const navigate = useNavigate();

  const handleBalanceChange = (val) => {
    setSelectedBal(val);
  };
  return (
    <Box
      sx={{
        margin: "30px 16px",
      }}
    >
      <Box>
        <Typography
          variant="heading"
          color={(theme) => theme.palette.color.darkText}
        >
          Account Balance
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            marginTop: "12px",
          }}
        >
          <ButtonGroup
            variant="contained"
            aria-label="Basic button group"
            className="custom_tab_buttons"
          >
            {accountBalance.map((balance, index) => (
              <Button
                className={selectedBal === balance ? "selected" : ""}
                key={index}
                onClick={() => handleBalanceChange(balance)}
              >
                {balance}
              </Button>
            ))}
          </ButtonGroup>
          <ButtonGroup
            variant="contained"
            aria-label="Basic button group"
            className="custom_tab_buttons"
          >
            <Button
              className={selectedCurrency === "dollar" ? "selected" : ""}
              onClick={() => setSelectedCurrency("dollar")}
            >
              <DollarIcon />
            </Button>
            <Button
              className={selectedCurrency === "euro" ? "selected" : ""}
              onClick={() => setSelectedCurrency("euro")}
            >
              <EuroIcon />
            </Button>
            <Button
              className={selectedCurrency === "pound" ? "selected" : ""}
              onClick={() => setSelectedCurrency("pound")}
            >
              <PoundIcon />
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
      <Box my={3}>
        <Typography
          variant="heading"
          color={(theme) => theme.palette.color.darkText}
        >
          Account Type
        </Typography>
        <Stack
          sx={{
            marginTop: "12px",
          }}
        >
          <ButtonGroup
            variant="contained"
            aria-label="Basic button group"
            className="custom_tab_buttons"
          >
            <Button
              className={selectedType === "step1" ? "selected" : ""}
              onClick={() => setSelectedType("step1")}
              sx={{
                "span": {
                  fontSize: "10px",
                  fontWeight: "500",
                  color: (theme) => theme.palette.color.darkText,
                  marginLeft: "8px"
                }
              }}
            >
              1 Step <span>HTF</span>
            </Button>
            <Button
              className={selectedType === "step2" ? "selected" : ""}
              onClick={() => setSelectedType("step2")}
            >
              2 Step
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
      <TradingInfo />
      <Box mt={4}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack>
            <Typography variant="subtitle2_500">Challenge Fee</Typography>
            <Typography
              variant="h3_heading"
              color={(theme) => theme.palette.color.darkText}
            >
              $97.00
            </Typography>
          </Stack>
          <Button className="custom_color_btn customButton" onClick={() => navigate("/payment-info")}>
            Start your career
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
