import React from "react";

export default function YoutubeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M18.792 4.64151C19.655 4.86751 20.329 5.51851 20.563 6.35151C20.987 7.86451 21 10.9995 21 10.9995C21 10.9995 21 14.1475 20.577 15.6475C20.342 16.4815 19.668 17.1325 18.805 17.3585C17.252 17.7675 11 17.7675 11 17.7675C11 17.7675 4.748 17.7675 3.195 17.3585C2.332 17.1325 1.658 16.4815 1.423 15.6475C1 14.1355 1 10.9995 1 10.9995C1 10.9995 1 7.86451 1.411 6.36451C1.645 5.53051 2.319 4.87951 3.182 4.65351C4.735 4.24551 10.987 4.23251 10.987 4.23251C10.987 4.23251 17.238 4.23251 18.792 4.64151Z"
        fill="white"
      />
      <path
        d="M14.681 11.0056L9.495 13.9056V8.10556L14.681 11.0056Z"
        fill="#0D0D0D"
      />
    </svg>
  );
}
