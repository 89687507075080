import React from "react";

export default function NotificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M13.7003 15.5V16.4C13.7003 17.8912 12.4915 19.1 11.0003 19.1C9.50913 19.1 8.30029 17.8912 8.30029 16.4V15.5M13.7003 15.5H8.30029M13.7003 15.5H16.9317C17.276 15.5 17.449 15.5 17.5884 15.453C17.8547 15.3632 18.063 15.1541 18.1529 14.8878C18.2 14.7479 18.2 14.5744 18.2 14.2273C18.2 14.0755 18.1998 13.9996 18.188 13.9272C18.1656 13.7904 18.1125 13.6607 18.0316 13.5481C17.9889 13.4886 17.9346 13.4343 17.8275 13.3272L17.477 12.9767C17.3638 12.8636 17.3003 12.7101 17.3003 12.5502V9.20002C17.3003 5.72062 14.4797 2.90002 11.0003 2.90002C7.5209 2.90003 4.70029 5.72063 4.70029 9.20002V12.5502C4.70029 12.7102 4.63661 12.8636 4.52351 12.9767L4.17295 13.3272C4.06558 13.4346 4.01183 13.4885 3.96904 13.5481C3.88819 13.6608 3.83463 13.7904 3.81217 13.9272C3.80029 13.9996 3.80029 14.0755 3.80029 14.2274C3.80029 14.5744 3.80029 14.7478 3.8475 14.8878C3.93732 15.1541 4.14661 15.3632 4.41289 15.453C4.5523 15.5 4.7246 15.5 5.0689 15.5H8.30029"
        stroke="#8A96A6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
