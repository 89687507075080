import React from "react";

export default function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.95825 12.2917V13.5417C3.95825 14.2047 4.22164 14.8406 4.69049 15.3094C5.15933 15.7783 5.79521 16.0417 6.45825 16.0417H13.5416C14.2046 16.0417 14.8405 15.7783 15.3094 15.3094C15.7782 14.8406 16.0416 14.2047 16.0416 13.5417V12.2917M9.99992 11.875V3.95834M9.99992 11.875L7.29159 8.95834M9.99992 11.875L12.7083 8.95834"
        stroke="#4ED5FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
