import React from "react";
import Chart from 'react-apexcharts';


function CandleStickChart() {
  const options = {
    chart: {
      type: 'candlestick',
      height: 350
    },
    title: {
      text: 'Candlestick Chart',
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    }
  };

  const series= [{
    data: [
      {
        x: new Date('2024-07-15'),
        y: [6629.81, 6650.5, 6623.04, 6633.33]
      },
      {
        x: new Date('2024-07-14'),
        y: [6632.01, 6643.59, 6620, 6630.11]
      },
      {
        x: new Date('2024-07-15'),
        y: [6630.71, 6648.95, 6623.34, 6635.65]
      },
      {
        x: new Date('2024-07-16'),
        y: [6635.65, 6651, 6629.67, 6638.24]
      },
      {
        x: new Date('2024-07-17'),
        y: [6638.24, 6640, 6620, 6624.47]
      },
      {
        x: new Date('2024-07-18'),
        y: [6624.53, 6636.03, 6621.68, 6624.31]
      },
      {
        x: new Date('2024-07-19'),
        y: [6629.81, 6650.5, 6623.04, 6633.33]
      },
      {
        x: new Date('2024-07-20'),
        y: [6632.01, 6643.59, 6620, 6630.11]
      },
      {
        x: new Date('2024-07-21'),
        y: [6630.71, 6648.95, 6623.34, 6635.65]
      },
      {
        x: new Date('2024-07-22'),
        y: [6635.65, 6651, 6629.67, 6638.24]
      },
      {
        x: new Date('2024-07-23'),
        y: [6638.24, 6640, 6620, 6624.47]
      },
      {
        x: new Date('2024-07-24'),
        y: [6624.53, 6636.03, 6621.68, 6624.31]
      }
    ]
  }]
  return (
    <div>
      <Chart options={options} series={series} type="candlestick" height={350} />
    </div>
  );
}

export default CandleStickChart;
