import { Box, Chip } from "@mui/material";
import React from "react";
import CustomTable from "../Accounts/AccountDetails.jsx/CustomTable";

const headcells = [
  {
    id: "reference",
    label: "Reference",
    getCell: (row) => row.reference,
  },
  {
    id: "amount",
    label: "Amount",
    getCell: (row) => row.amount,
  },
  {
    id: "description",
    label: "Description",
    getCell: (row) => row.description,
  },
  {
    id: "status",
    label: "Status",
    getCell: (row) => (
      <Box sx={{
        ".MuiChip-root": {
            color: "#000",
            background: row.status === "Paid" ? "#21FF90" : row.status === "Unpaid" ?  "#FFC452" : "#FF4F6F",
            "span": {
                lineHeight: '1',
                fontSize: "12px",
                fontWeight: "500"
            }
        }
      }}>
        <Chip label={row.status}  />
      </Box>
    ),
  },
  {
    id: "date",
    label: "Date",
    getCell: (row) => row.date,
  },
];

const data = [
  {
    reference: "EUR.USD",
    amount: "up",
    description: "0.05",
    status: "Paid",
    date: "April 16,2024 5:11 pm",
  },
  {
    reference: "EUR.USD",
    amount: "up",
    description: "0.05",
    status: "Unpaid",
    date: "April 16,2024 5:11 pm",
  },
  {
    reference: "EUR.USD",
    amount: "up",
    description: "0.05",
    status: "Rejected",
    date: "April 16,2024 5:11 pm",
  },
];

export default function ReferralTable() {
  return (
    <Box>
      <CustomTable headcells={headcells} rows={data} />
    </Box>
  );
}
