import React from "react";

export default function BankIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <mask
        id="mask0_4323_35839"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="28"
        height="28"
      >
        <path
          d="M2.91666 2.66665H29.5833V29.3333H2.91666V2.66665Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4323_35839)">
        <path
          d="M28.8021 9.75H3.69791L16.25 3.44792L28.8021 9.75Z"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.26041 12.875C5.26041 12.875 3.69791 11.3125 3.69791 9.75"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.26041 12.875H27.2396C27.2396 12.875 28.8021 11.3125 28.8021 9.75"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.2396 25.4271H5.26041C5.26041 25.4271 3.69791 26.9896 3.69791 28.5521H28.8021C28.8021 26.9896 27.2396 25.4271 27.2396 25.4271Z"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.82291 25.4271V12.875"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0208 25.4271V12.875"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.6771 12.875V25.4271"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2188 25.4271V12.875"
          stroke="#8A96A6"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
