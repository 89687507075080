import DashboardIcon from "../components/Icons/Dashboard";
// import ChartIcon from "../components/Icons/ChartIcon";
import CopyIcon from "../components/Icons/CopyIcon";
import BarChartIcon from "../components/Icons/BarChartIcon";
import InfoIcon from "../components/Icons/InfoIcon";
import AbookIcon from "../components/Icons/AbookIcon";
import CommunityIcon from "../components/Icons/CommunityIcon";
import ToolsIcon from "../components/Icons/ToolsIcon";

export const navItems = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    path: "/",
    tooltip: "Dashboard",
  },
  // {
  //   title: "Metrics",
  //   icon: ChartIcon,
  //   path: "/metrics",
  //   tooltip: "Metrics",
  //   to: "/metrics",
  // },
  {
    title: "Account Overview",
    icon: CopyIcon,
    path: "/accounts",
    tooltip: "Account Overview",
    to: "/accounts",
  },
  {
    title: "A-book",
    icon: AbookIcon,
    path: "/a-book",
    tooltip: "A Book",
    to: "/a-book",
  },
  {
    title: "Leader Board",
    icon: BarChartIcon,
    path: "/leaderboard",
    tooltip: "Leader Board",
    to: "/leaderboard",
  },
  {
    title: "Community",
    icon: CommunityIcon,
    path: "/community",
    tooltip: "Community",
    to: "/community",
  },
  {
    title: "Tools",
    icon: ToolsIcon,
    path: "/tools",
    tooltip: "Tools",
    to: "/tools",
  },
];

export const navItems2 = [
  {
    title: "Billing Details",
    icon: InfoIcon,
    path: "/billing-details",
    tooltip: "Billing Details",
    to: "/billing-details",
  },
  {
    title: "Economic Events",
    icon: InfoIcon,
    path: "/economic-events",
    tooltip: "Economic Events",
    to: "/economic-events",
  },
  {
    title: "Payment Details",
    icon: InfoIcon,
    path: "/payment-details",
    tooltip: "Payment Details",
    to: "/payment-details",
  },
  {
    title: "Start new evaluation",
    icon: InfoIcon,
    path: "/new-evaluation",
    tooltip: "Start new evaluation",
    to: "/new-evaluation",
  },
  {
    title: "Your Account",
    icon: InfoIcon,
    path: "/settings",
    tooltip: "Your Account",
    to: "/settings",
  },
  {
    title: "Order Details",
    icon: InfoIcon,
    path: "/order-details",
    tooltip: "Order Details",
    to: "/order-details",
  },
  {
    title: "Partner program",
    icon: InfoIcon,
    path: "/partner",
    tooltip: "Partner program",
    to: "/partner",
  },
];

export const helpCenterNavItems = {
  menu: {
    Home: {
      icon: "home",
      link: "/home"
    },
    "Video's": {
      icon: "video",
      link: "/videos"
    },
    "Ai Assistant": {
      icon: "ai",
      link: "/ai-assistant"
    },
    CRM: {
      Challenges: {
        icon: "folder",
        link: "/crm/challenges"
      },
      Orders: {
        icon: "folder",
        link: "/crm/orders"
      },
      Customers: {
        icon: "folder",
        link: "/crm/customers"
      },
      Statistics: {
        icon: "folder",
        link: "/crm/statistics"
      },
      Affiliate: {
        icon: "folder",
        link: "/crm/affiliate"
      }
    },
    Policies: {
      Refunds: {
        icon: "folder",
        link: "/policies/refunds"
      },
      Breaches: {
        icon: "folder",
        link: "/policies/breaches"
      },
      Affiliate: {
        icon: "folder",
        link: "/policies/affiliate"
      }
    },
    Integrations: {
      Refunds: {
        icon: "folder",
        link: "/integrations/refunds"
      },
      Breaches: {
        icon: "folder",
        link: "/integrations/breaches"
      },
      Affiliate: {
        icon: "folder",
        link: "/integrations/affiliate"
      }
    }
  }
};