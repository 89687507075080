import React from "react";
import BillingDetails from "./BillingDetails";
import PaymentDetails from "./paymentDetails";
import { Box, Grid, Stack, Typography } from "@mui/material";
import OrderSummary from "../components/Evaluation/OrderSummary";

export default function PaymentInfo() {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "calc(100vh - 32px)",
        backgroundColor: (theme) => theme.palette.color.bg,
        padding: "12px",
      }}
    >
      <Box
        sx={{
          padding: "7px 12px",
          color: (theme) => theme.palette.color.primary,
        }}
      >
        <Stack
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            alignItems: { xs: "flex-start", lg: "center" },
            gap: { xs: 0.5, lg: 2 },
          }}
        >
          <Typography variant="heading2">Payment</Typography>
        </Stack>
        <Grid container spacing={2} sx={{
          mt: 4,
          ml: 2
        }}>
          <Grid item md={6} mdOffset={2}>
            <BillingDetails />
            <PaymentDetails />
          </Grid>
          <Grid item md={5}>
            <OrderSummary />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
